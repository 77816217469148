<template>
  <v-row>
    <v-col cols="12" class="pt-0 pt-lg-3">
      <v-card class="mb-0">
        <v-card-title class="borderBottom-1 border-accentxlight"
          >Weather</v-card-title
        >
        <v-card-text>
          <v-row>
            <v-slide-group
              v-model="model"
              class="px-0 py-1 weatherslider"
              show-arrows
            >
              <v-slide-item
                class="weathercard"
                v-for="item in listOfWeathers"
                :key="item.id"
              >
                <v-col>
                  <v-row no-gutters class="align-center">
                    <v-col class="xlgFont fw-700 black--text"
                      >{{ item.city.temprature
                      }}<sup class="fw-400 mdFont">&deg;C</sup>
                    </v-col>
                    <v-col cols="auto">
                      <v-tooltip bottom color="accentdark">
                        <template v-slot:activator="{ on, attrs }">
                          <img
                            v-bind="attrs"
                            v-on="on"
                            v-if="item.city.weatherConditionIcon"
                            :src="
                              require(`../../assets/OpenWeather/${item.city.weatherConditionIcon}.png`)
                            "
                          />
                        </template>
                        <span>
                          {{ item.city.weatherConditionDescription }}</span
                        >
                      </v-tooltip></v-col
                    >
                  </v-row>
                  <v-row no-gutters class="mt-2 align-center">
                    <v-col class="fw-400 min-wd-0 text-truncate">
                      {{ item.city.name }}
                    </v-col>
                    <v-col cols="auto"><div class="chip ml-1">PK</div></v-col>
                  </v-row>
                </v-col>
              </v-slide-item>
            </v-slide-group>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import apimethods from "../../mixins/apimethods";

export default {
  name: "Weather",
  mixins: [apimethods],
  data() {
    return {
      listOfWeathers: [],
    };
  },
  mounted() {
    this.GetAllActiveWeathers();
  },
  methods: {
    GetAllActiveWeathers() {
      this.$store.commit("showLoadingModal");
      var self = this;
      this.GetRequestWithAuthorization("api/Weather/GetActive", undefined)
        .then((res) => {
          this.$store.commit("hideLoadingModal");
          if (
            res != undefined &&
            res.data != undefined &&
            res.data.data != undefined
          ) {
            self.listOfWeathers = res.data.data;
          }
        })
        .catch((ex) => {
          this.$store.commit("hideLoadingModal");
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
