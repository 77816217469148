<template>
  <v-row class="d-flex fill-height ma-0">
    <v-col cols="12" md="6" class="px-0 pl-md-0 pr-lg-3 pt-0 pt-lg-3">
      <v-row class="ma-0 fill-height p-relative box-shadow-1">
        <v-col class="white rounded d-flex fill-height flex-column p-absolute">
          <v-row class="align-center">
            <v-col class="lgFont accentdark--text fw-700 pt-4 pb-2">
              Bond Grout
            </v-col>
          </v-row>
          <v-row class="fill-height p-relative mb-0">
            <v-col cols="min-wd-0">
              <v-row class="fill-height p-absolute wd-100 employeedirectory">
                <vue-scroll :ops="ops">
                  <v-col cols="12">
                    <v-card
                      outlined
                      class="px-3 py-4 mb-2"
                      v-for="(item, index) in bondGroutList"
                      :key="item.Id"
                    >
                      <v-row class="accentlight--text xsFont align-center">
                        <v-col sm="auto" cols="3" class="order-0">
                          S.No
                          <div class="accentdark--text text-truncate fw-500">
                            {{ index + 1 }}
                          </div>
                        </v-col>
                        <v-col cols="6" class="col-sm min-wd-0 order-1">
                          Name
                          <div class="accentdark--text text-truncate fw-500">
                            {{ item.itemName }}
                          </div>
                        </v-col>
                        <v-col
                          cols="auto"
                          class="order-sm-2 pt-0 pt-sm-3 order-3 col-sm"
                        >
                          SQM / Bag
                          <div class="accentdark--text text-truncate fw-500">
                            {{ item.sqmperBag }}
                          </div>
                        </v-col>
                        <v-col class="order-sm-3 pt-0 pt-sm-3 order-4">
                          KG / Package
                          <div class="accentdark--text text-truncate fw-500">
                            {{ item.kgperPackage }}
                          </div>
                        </v-col>
                        <v-col
                          cols="3"
                          class="
                            order-sm-4
                            col-sm-auto
                            text-right
                            order-2
                            px-0
                            py-0 py-sm-3
                          "
                        >
                          <v-btn
                            text
                            small
                            class="editbtn"
                            color="accentlight mr-2"
                            @click="editBondGrout(item)"
                          >
                            <span class="material-icons-outlined lgFont mr-1"
                              >edit</span
                            >
                            Edit</v-btn
                          >
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                </vue-scroll>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" md="6" class="px-0 pl-md-0 pr-lg-3 pt-0 pt-lg-3">
      <v-row class="ma-0 fill-height p-relative box-shadow-1">
        <v-col class="white rounded d-flex fill-height flex-column p-absolute">
          <v-row class="align-center">
            <v-col class="lgFont accentdark--text fw-700 pb-1">
              Tile Sizes
            </v-col>
            <v-col cols="auto" class="pl-1 pb-1 pl-sm-3">
              <v-btn
                class="accent white--text px-2 px-sm-3 empbtn"
                @click.stop="openTileSizePopUp({ id: 0 })"
                ><span class="material-icons-outlined mr-1 lgFont"
                  >add_circle_outline</span
                ><span class="d-none d-md-inline-block">Add&nbsp;</span>
                Tile Size
              </v-btn>
            </v-col>
          </v-row>

          <v-row class="fill-height p-relative mb-0">
            <v-col cols="min-wd-0">
              <v-row class="fill-height p-absolute wd-100 employeedirectory">
                <vue-scroll :ops="ops">
                  <v-col cols="12">
                    <v-card
                      outlined
                      class="px-3 py-4 mb-2"
                      v-for="(item, index) in tileSizeList"
                      :key="item.Id"
                    >
                      <v-row class="accentlight--text xsFont align-start">
                        <v-col class="col-wd-0">
                          <v-row class="align-center">
                            <v-col cols="1" class="pr-0">
                              <!-- S.No -->
                              <div
                                class="accentdark--text text-truncate fw-500"
                              >
                                {{ index + 1 }}
                              </div>
                            </v-col>
                            <v-col class="px-1 px-sm-3 min-wd-0">
                              <!-- Name -->
                              <div
                                class="accentdark--text text-truncate fw-500"
                              >
                                {{ item.displayName }}
                              </div>
                            </v-col>
                            <v-col
                              cols="auto"
                              class="py-0 py-sm-0 px-0 text-right"
                            >
                              <v-btn
                                text
                                small
                                color="accentlight"
                                class="mr-0 removebtn px-0"
                                @click="ConfirmRemove(item)"
                              >
                                <span class="material-icons lgFont mr-1"
                                  >delete</span
                                >Remove</v-btn
                              >
                              <v-btn
                                text
                                small
                                class="editbtn"
                                color="accentlight mr-2"
                                @click="openTileSizePopUp(item)"
                              >
                                <span
                                  class="material-icons-outlined lgFont mr-1"
                                  >edit</span
                                >
                                Edit</v-btn
                              >
                            </v-col>
                          </v-row>

                          <v-row class="mt-1">
                            <v-col cols="1">
                              <!-- S.No -->
                            </v-col>
                            <v-col class="px-1 px-sm-3">
                              Length
                              <div
                                class="accentdark--text text-truncate fw-500"
                              >
                                {{ item.length }}
                              </div>
                            </v-col>
                            <v-col class="px-1 px-sm-3">
                              Width
                              <div
                                class="accentdark--text text-truncate fw-500"
                              >
                                {{ item.width }}
                              </div>
                            </v-col>
                            <v-col class="px-1 px-sm-3">
                              SQM / Box
                              <div
                                class="accentdark--text text-truncate fw-500"
                              >
                                {{ item.sqmperBox }}
                              </div>
                            </v-col>
                            <v-col class="px-1 px-sm-3">
                              Tiles / Box
                              <div
                                class="accentdark--text text-truncate fw-500"
                              >
                                {{ item.tilesPerBox }}
                              </div>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                </vue-scroll>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
    <v-dialog
      v-model="editBondGroutDialog"
      content-class="dashboardmodal"
      max-width="440"
    >
      <v-card class="mb-0">
        <v-form ref="bondGroutForm" v-model="bondGroutFormValid">
          <v-card-title
            class="accentdark--text borderBottom-1 border-accentxlight"
          >
            <v-row class="align-center">
              <v-col>Edit {{ currentBondGrout.itemName }}</v-col>
              <v-col cols="auto" class="d-none d-sm-block">
                <v-btn
                  outlined
                  class="accentlight--text mr-2"
                  small
                  @click="editBondGroutDialog = false"
                  >Cancel</v-btn
                >

                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  :disabled="!allowSaveBondGrout"
                  :loading="isSaving"
                  outlined
                  class="accent--text"
                  small
                  @click="saveBondGrout"
                  >Save</v-btn
                >
              </v-col>
            </v-row>
          </v-card-title>

          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Item Name"
                  outlined
                  dense
                  maxlength="50"
                  disabled
                  hide-details
                  v-model="currentBondGrout.itemName"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="SQM Per Bag"
                  outlined
                  dense
                  hide-details
                  placeholder="eg: 123.45"
                  type="tel"
                  @keydown="allowNumberOnly"
                  :rules="SQMRule"
                  maxlength="6"
                  v-model="currentBondGrout.sqmperBag"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="KG Per Package"
                  outlined
                  dense
                  type="tel"
                  hide-details
                  placeholder="eg: 123"
                  :rules="numberRule"
                  v-model="currentBondGrout.kgperPackage"
                  v-mask="'###'"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions class="d-sm-none borderTop-1 border-accentxlight">
            <v-row class="align-center mx-0 mb-n1 text-right justify-end">
              <v-col cols="auto" class="text-right pt-5 justify-end">
                <v-btn
                  outlined
                  class="accentlight--text mr-2"
                  small
                  @click="editBondGroutDialog = false"
                  >Cancel</v-btn
                >

                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  :disabled="!allowSaveBondGrout"
                  :loading="isSaving"
                  outlined
                  class="accent--text"
                  small
                  @click="saveBondGrout"
                  >Save</v-btn
                >
              </v-col>
            </v-row>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="editTileSizeDialog"
      content-class="dashboardmodal"
      max-width="440"
    >
      <v-card class="mb-0">
        <v-form ref="TileSizeForm" v-model="TileSizeFormValid">
          <v-card-title
            class="accentdark--text borderBottom-1 border-accentxlight"
          >
            <v-row class="align-center">
              <v-col
                >{{ currentTileSize.id != 0 ? "Edit" : "Add" }} Tile Size</v-col
              >
              <v-col cols="auto" class="d-none d-sm-block">
                <v-btn
                  outlined
                  class="accentlight--text mr-2"
                  small
                  @click="editTileSizeDialog = false"
                  >Cancel</v-btn
                >

                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  :disabled="!allowSaveTileSize"
                  :loading="isSaving"
                  outlined
                  class="accent--text"
                  small
                  @click="saveTileSize"
                  >Save</v-btn
                >
              </v-col>
            </v-row>
          </v-card-title>

          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Name"
                  outlined
                  dense
                  maxlength="30"
                  hide-details
                  placeholder="eg: 12 x 14"
                  :rules="[(v) => !!v.trim() || 'Name is required']"
                  v-model="currentTileSize.displayName"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Length"
                  outlined
                  dense
                  hide-details
                  placeholder="eg: 12"
                  :rules="numberRule"
                  v-model="currentTileSize.length"
                  v-mask="'##'"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Width"
                  outlined
                  dense
                  hide-details
                  placeholder="eg: 12"
                  :rules="numberRule"
                  v-model="currentTileSize.width"
                  v-mask="'##'"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="SQM Per Box"
                  outlined
                  dense
                  hide-details
                  placeholder="eg: 123.45"
                  type="tel"
                  @keydown="allowNumberOnly"
                  :rules="SQMRule"
                  maxlength="6"
                  v-model="currentTileSize.sqmperBox"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Tiles Per Box"
                  outlined
                  dense
                  type="tel"
                  hide-details
                  placeholder="eg: 123"
                  :rules="numberRule"
                  v-model="currentTileSize.tilesPerBox"
                  v-mask="'###'"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions class="d-sm-none borderTop-1 border-accentxlight">
            <v-row class="align-center mx-0 mb-n1 text-right justify-end">
              <v-col cols="auto" class="text-right pt-5 justify-end">
                <v-btn
                  outlined
                  class="accentlight--text mr-2"
                  small
                  @click="editTileSizeDialog = false"
                  >Cancel</v-btn
                >

                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  :disabled="!allowSaveTileSize"
                  :loading="isSaving"
                  outlined
                  class="accent--text"
                  small
                  @click="saveTileSize"
                  >Save</v-btn
                >
              </v-col>
            </v-row>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showRemoveConfirmDialog" max-width="350">
      <v-card class="mb-0">
        <v-card-text>
          <v-row>
            <v-col class="fw-500 mdFont accentdark--text">
              Are you sure you want to remove this Tile Size
              {{
                currentRemoveItem.length +
                " X " +
                currentRemoveItem.width +
                " (" +
                currentRemoveItem.displayName +
                ")"
              }}?
            </v-col>
          </v-row>
          <v-row>
            <v-col class="fw-500 smFont error--text">
              Note: This action cannot be undone.
            </v-col>
          </v-row>
          <v-row class="justify-end mt-1">
            <v-col cols="12" class="text-right">
              <v-btn
                outlined
                class="accentlight--text mr-2"
                small
                @click="showRemoveConfirmDialog = false"
                >Cancel</v-btn
              >
              <v-btn
                outlined
                class="accent--text"
                small
                @click="RemoveTileSize(currentRemoveItem.id)"
                >Yes</v-btn
              >
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import apimethods from "../../mixins/apimethods";
export default {
  name: "TileCalculatorPage",
  mixins: [apimethods],
  data() {
    return {
      bondGroutList: {},
      tileSizeList: {},
      currentBondGrout: {},
      currentTileSize: {},
      editBondGroutDialog: false,
      bondGroutFormValid: false,
      editTileSizeDialog: false,
      TileSizeFormValid: false,
      isSaving: false,
      showRemoveConfirmDialog: false,
      currentRemoveItem: {},
      numberRule: [
        (v) => !!v.trim() || "Value is required",
        ((v) => /^[1-9][0-9]*$/.test(v)) || "Value Should Be Greater than 0",
      ],
      SQMRule: [
        (v) => !!v.trim() || "SQM is required",
        ((v) => /^(?!0+$)[0-9][0-9]{0,2}(?:\.[0-9]{0,2})?$/.test(v)) ||
          "Value Should Be Greater than 1",
      ],
    };
  },
  computed: {
    allowSaveBondGrout() {
      if (
        this.bondGroutFormValid == false ||
        this.currentBondGrout.sqmperBag == "" ||
        this.currentBondGrout.sqmperBag == 0 ||
        this.currentBondGrout.sqmperBag == "0" ||
        this.currentBondGrout.kgperPackage == "" ||
        this.currentBondGrout.kgperPackage == 0 ||
        this.currentBondGrout.kgperPackage == "0"
      ) {
        return false;
      }
      return true;
    },
    allowSaveTileSize() {
      if (
        this.TileSizeFormValid == false ||
        this.currentTileSize.length == "" ||
        this.currentTileSize.length == 0 ||
        this.currentTileSize.length == "0" ||
        this.currentTileSize.length == undefined ||
        this.currentTileSize.width == "" ||
        this.currentTileSize.width == 0 ||
        this.currentTileSize.width == "0" ||
        this.currentTileSize.width == undefined ||
        this.currentTileSize.sqmperBox == "" ||
        this.currentTileSize.sqmperBox == 0 ||
        this.currentTileSize.sqmperBox == "0" ||
        this.currentTileSize.sqmperBox == undefined ||
        this.currentTileSize.tilesPerBox == "" ||
        this.currentTileSize.tilesPerBox == 0 ||
        this.currentTileSize.tilesPerBox == "0" ||
        this.currentTileSize.tilesPerBox == undefined
      ) {
        return false;
      }
      return true;
    },
  },
  props: {},
  mounted() {
    this.GetBondGrountandTileSize();
  },
  methods: {
    allowNumberOnly(e) {
      var key = e.key;
      var lstAllowedKeys = [
        "Backspace",
        "Tab",
        ".",
        "ArrowLeft",
        "ArrowRight",
        "Delete",
        "End",
        "Home",
      ];
      if (lstAllowedKeys.includes(key)) return;
      if (!/^\d+/g.test(key)) e.preventDefault();
    },
    GetBondGrountandTileSize() {
      this.$store.commit("showLoadingModal");

      var self = this;
      var getBondGrout = this.GetRequestWithAuthorization(
        "api/TileCalculator/GetBondGrout",
        undefined
      )
        .then((res) => {
          if (res != undefined && res.data != undefined) {
            self.bondGroutList = res.data;
          }
        })
        .catch((ex) => {});
      var getTileSize = this.GetRequestWithAuthorization(
        "api/TileCalculator/GetTileSize",
        undefined
      )
        .then((res) => {
          if (res != undefined && res.data != undefined) {
            self.tileSizeList = res.data;
          }
        })
        .catch((ex) => {});

      Promise.all([getBondGrout, getTileSize]).then((values) => {
        this.$store.commit("hideLoadingModal");
      });
    },
    editBondGrout(bondGrout) {
      this.currentBondGrout = JSON.parse(JSON.stringify(bondGrout));
      this.editBondGroutDialog = true;
      this.$refs.bondGroutForm.resetValidation();
    },
    openTileSizePopUp(TileSize) {
      this.currentTileSize = JSON.parse(JSON.stringify(TileSize));
      this.editTileSizeDialog = true;
      this.$refs.TileSizeForm.resetValidation();
    },
    saveBondGrout() {
      this.isSaving = true;
      this.$store.commit("showLoadingModal");
      var self = this;
      var url = "api/TileCalculator/EditBondGrout";
      this.currentBondGrout.sqmperBag = parseFloat(
        this.currentBondGrout.sqmperBag
      );
      this.currentBondGrout.kgperPackage = parseFloat(
        this.currentBondGrout.kgperPackage
      );
      this.PostRequestWithAuthorization(
        url,
        undefined,
        JSON.stringify(this.currentBondGrout)
      )
        .then((res) => {
          self.isSaving = false;
          this.$store.commit("hideLoadingModal");
          if (
            res != undefined &&
            res.data != undefined &&
            res.data.data != undefined
          ) {
            if (res.data.data == true) {
              self.$notify({
                group: "notification",
                title: "Success",
                text: this.currentBondGrout.itemName + " updated successfully.",
                type: "success",
              });
              self.editBondGroutDialog = false;
              self.GetBondGrountandTileSize();
            } else {
              self.$notify({
                group: "notification",
                title: "Error",
                text: res.data.errorMessage,
                type: "error",
              });
            }
          }
        })
        .catch((ex) => {
          self.isSaving = false;
          this.$store.commit("hideLoadingModal");
        });
    },
    saveTileSize() {
      var isEdit =
        this.currentTileSize.id != null &&
        this.currentTileSize.id != undefined &&
        this.currentTileSize.id != 0;
      this.isSaving = true;
      this.$store.commit("showLoadingModal");
      var self = this;
      var url =
        "api/TileCalculator/".concat(isEdit ? "Edit" : "Add") + "TileSize";
      this.currentTileSize.sqmperBox = parseFloat(
        this.currentTileSize.sqmperBox
      );
      this.currentTileSize.length = parseFloat(this.currentTileSize.length);
      this.currentTileSize.width = parseFloat(this.currentTileSize.width);
      this.currentTileSize.tilesPerBox = parseInt(
        this.currentTileSize.tilesPerBox
      );
      this.PostRequestWithAuthorization(
        url,
        undefined,
        JSON.stringify(this.currentTileSize)
      )
        .then((res) => {
          self.isSaving = false;
          this.$store.commit("hideLoadingModal");
          if (
            res != undefined &&
            res.data != undefined &&
            res.data.data != undefined
          ) {
            if (res.data.data == true) {
              self.$notify({
                group: "notification",
                title: "Success",
                text: "Tile"
                  .concat(isEdit ? " Updated" : " Added")
                  .concat(" successfully."),
                type: "success",
              });
              self.editTileSizeDialog = false;
              self.GetBondGrountandTileSize();
            } else {
              self.$notify({
                group: "notification",
                title: "Error",
                text: res.data.errorMessage,
                type: "error",
              });
            }
          }
        })
        .catch((ex) => {
          self.isSaving = false;
          this.$store.commit("hideLoadingModal");
        });
    },
    ConfirmRemove(item) {
      this.currentRemoveItem = item;
      this.showRemoveConfirmDialog = true;
    },
    RemoveTileSize(TileSizeId) {
      this.$store.commit("showLoadingModal");
      this.showRemoveConfirmDialog = false;
      var self = this;
      this.PostRequestWithAuthorization(
        "api/TileCalculator/RemoveTileSize",
        undefined,
        JSON.stringify(this.currentRemoveItem)
      )
        .then((res) => {
          this.$store.commit("hideLoadingModal");

          if (res != undefined && res.data != undefined) {
            if (res.data.status == true) {
              self.$notify({
                group: "notification",
                title: "Message",
                text: "Tile Size Removed Successfully",
                type: "success",
              });
              self.skip = 0;
              self.tileSizeList = {};
              self.GetBondGrountandTileSize();
            } else {
              self.$notify({
                group: "notification",
                title: "Error",
                text: res.data.errorMessage,
                type: "error",
              });
            }
          }
        })
        .catch((ex) => {
          this.$store.commit("hideLoadingModal");
          console.log(ex);
        });
    },
  },
};
</script>
