<template>
  <v-row class="d-flex fill-height ma-0">
    <v-col class="px-0 pl-md-0 pr-lg-3 pt-0 pt-lg-3">
      <v-row class="ma-0 fill-height p-relative box-shadow-1">
        <v-col class="white rounded d-flex fill-height flex-column p-absolute">
          <v-row class="align-center">
            <v-col class="lgFont accentdark--text fw-700">
              Department in focus
            </v-col>
          </v-row>

          <v-row
            class="borderBottom-1 border-secondary mb-2 mx-0 xsFont"
            v-if="departmentinfocus"
          >
            <v-col cols="auto" class="d-none d-sm-block">
              <img
                :src="departmentinfocus.images[0].url"
                class="starclubmini rounded"
              />
            </v-col>

            <v-col class="pl-0 min-wd-0">
              <v-row class="align-center">
                <v-col cols="12"  class="col-sm accentdark--text mdFont fw-500">
                  {{ departmentinfocus.title }}
                </v-col>
                <v-col cols="12" class="accentdark--text pt-0 pb-4 xxsFont  d-sm-none">
                  {{ departmentinfocus.department.title }}
                  <span class="px-4">|</span>Dated:
                  {{ departmentinfocus.publishedOn }}
                </v-col>
                <v-col cols="6" class="py-0 d-sm-none">
              <img
                :src="departmentinfocus.images[0].url"
                class="starclubmini rounded"
              />
            </v-col>
            <v-col cols="6" class="py-0 d-sm-none">
              <v-row no-gutters class="justify-end">
                <v-col cols="auto" class="text-right py-0 pr-0">
                  <v-btn
                    text
                    color="accentlight"
                    @click.stop="
                      showdepartmentpostpopup(
                        'Edit',
                        JSON.parse(JSON.stringify(departmentinfocus))
                      )
                    "
                    class="editbtn px-0 ml-4 d-block mobaligned"
                  >
                    <span class="material-icons-outlined lgFont mr-1">edit</span
                    >Edit</v-btn
                  >
                  <v-btn
                    text
                    class="removebtn px-0 my-3 d-block mobaligned"
                    color="accentlight"
                    @click="confirmRemove(departmentinfocus.id)"
                  >
                    <span class="material-icons lgFont mr-1">delete</span>
                    Remove</v-btn
                  >
                </v-col>
                <v-col cols="auto" class="mobaligned mb-3">
                  <v-radio-group
                    class="mt-0 pt-0"
                    hide-details
                    v-model="departmentinfocus.isInFocus"
                  >
                    <v-radio
                      small
                      hide-details
                      class="mt-0 pt-0 customcheckbox customcheckbox2"
                      label="IN FOCUS"
                      :value="true"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
            </v-col>
            
                <v-col cols="auto" class="text-right d-none d-sm-block py-0 pr-0">
                  <v-btn
                    text
                    color="accentlight"
                    @click.stop="
                      showdepartmentpostpopup(
                        'Edit',
                        JSON.parse(JSON.stringify(departmentinfocus))
                      )
                    "
                    class="editbtn"
                  >
                    <span class="material-icons-outlined lgFont mr-1">edit</span
                    >Edit</v-btn
                  >
                  <v-btn
                    text
                    class="removebtn"
                    color="accentlight"
                    @click="confirmRemove(departmentinfocus.id)"
                  >
                    <span class="material-icons lgFont mr-1">delete</span>
                    Remove</v-btn
                  >
                </v-col>
                <v-col cols="auto" class=" d-none d-sm-block">
                  <v-radio-group
                    class="mt-0 pt-0"
                    hide-details
                    v-model="departmentinfocus.isInFocus"
                  >
                    <v-radio
                      small
                      hide-details
                      class="mt-0 pt-0 customcheckbox customcheckbox2"
                      label="IN FOCUS"
                      :value="true"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>

              <v-row class="mt-1 align-center d-none d-sm-block">
                <v-col class="accentdark--text pt-0 xxsFont">
                  {{ departmentinfocus.department.title }}
                  <span class="px-4">|</span>Dated:
                  {{ departmentinfocus.publishedOn }}
                </v-col>
              </v-row>

              <v-row class="mt-sm-2 mt-4 align-center">
                <v-col class="accentdark--text pt-0 xxsFont">
                  <div class="p-relative">
                    <div class="departtext p-relative seemorediv">
                      <div v-html="departmentinfocus.description"></div>
                      <a
                        class="readmoredot d-none"
                        @click="previewDepartment(departmentinfocus)"
                      >
                        Read More...</a
                      >
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="align-center mt-0 mb-0">
            <v-col class="lgFont accentdark--text fw-700">
              All Departments
            </v-col>
            <v-col cols="auto">
              <v-btn
                class="accent white--text"
                @click="showdepartmentpostpopup('Add')"
                ><span class="material-icons-outlined mr-1 lgFont"
                  >add_circle_outline</span
                >Add POST</v-btn
              >
            </v-col>
          </v-row>

          <v-row class="align-center my-0">
            <v-col class="px-3 py-1">
              <v-chip-group
                class="departtabs"
                mandatory
                show-arrows
                v-model="filteredDepartment"
              >
                <v-chip color="accentdark" label outlined> All </v-chip>
                <v-chip
                  v-for="dept in departments"
                  :key="dept.id"
                  color="accentdark"
                  label
                  outlined
                >
                  {{ dept.title }}
                </v-chip>
              </v-chip-group>
            </v-col>
          </v-row>

          <v-row class="fill-height p-relative mt-0 mb-1">
            <v-col>
              <v-row class="fill-height p-absolute wd-100">
                <vue-scroll  :ops="ops">
                  <v-col cols="12">
                    <v-row class="px-2">
                      <v-col
                        cols="12"
                        md="6"
                        class="pt-0 px-1"
                        v-for="deptpost in departmentpostsunfocused"
                        :key="deptpost.id"
                      >
                        <v-row
                          class="
                            borderBottom-1
                            borderTop-1
                            border-secondary
                            my-2
                            mx-0
                            xsFont
                          "
                        >
                          <v-col cols="auto" class="d-none d-sm-block">
                            <img
                              :src="deptpost.images[0].url"
                              class="starclubmini rounded"
                            />
                          </v-col>

                          <v-col class="pl-0 min-wd-0">
                            <v-row class="align-center">
                              <v-col
                                class="
                                  accentdark--text
                                  mdFont
                                  text-truncate
                                  fw-500
                                "
                              >
                                {{ deptpost.title }}
                              </v-col>
                            </v-row>

                            <v-row class="mt-1 align-center d-sm-none">
                              <v-col class="accentdark--text pt-0 xxsFont">
                                {{ deptpost.department.title }}

                               

                                <span class="px-4">|</span>Dated:
                                {{ deptpost.publishedOn }}
                              </v-col>
                            </v-row>

                            <v-row class="d-sm-none align-center mt-0">
                               <v-col cols="6">
                            <img
                              :src="deptpost.images[0].url"
                              class="starclubmini rounded"
                            />
                          </v-col>
                             <v-col cols="6" class="py-0 d-sm-none">
              <v-row no-gutters class="justify-end">
                <v-col cols="auto" class="text-right py-0 pr-0">
                  <v-btn
                    text
                    color="accentlight"
                     @click.stop="
                                    showdepartmentpostpopup(
                                      'Edit',
                                      JSON.parse(JSON.stringify(deptpost))
                                    )
                                  "
                    
                    class="editbtn px-0 ml-4 d-block mobaligned"
                  >
                    <span class="material-icons-outlined lgFont mr-1">edit</span
                    >Edit</v-btn
                  >
                  <v-btn
                    text
                    class="removebtn px-0 my-3 d-block mobaligned"
                    color="accentlight"
                      @click="confirmRemove(deptpost.id)"
                  >
                    <span class="material-icons lgFont mr-1">delete</span>
                    Remove</v-btn
                  >

                 
                </v-col>
                <v-col cols="auto" class="mobaligned mb-3">
               
                   <v-radio-group
                                  class="mt-0 pt-0"
                                  hide-details
                                  @change="MarkInFocus(deptpost.id)"
                                  v-model="deptpost.isInFocus"
                                >
                                  <v-radio
                                    small
                                    hide-details
                                    class="
                                      mt-0
                                      pt-0
                                      customcheckbox
                                      customcheckbox2
                                    "
                                    label="IN FOCUS"
                                    :value="true"
                                  ></v-radio>
                                </v-radio-group>
                </v-col>
              </v-row>
            </v-col>
                            </v-row>

                            <v-row class="mt-1 align-center d-none d-sm-flex">
                              <v-col class="accentdark--text pt-0 xxsFont">
                                {{ deptpost.department.title }}

                               

                                <span class="px-4">|</span>Dated:
                                {{ deptpost.publishedOn }}
                              </v-col>
                            </v-row>

                            <v-row class="mt-0 align-center d-none d-sm-flex">
                              <v-col
                                cols="auto"
                                class="text-right pr-0 pb-2 pt-1"
                              >
                                <v-btn
                                  text
                                  color="accentlight"
                                  @click.stop="
                                    showdepartmentpostpopup(
                                      'Edit',
                                      JSON.parse(JSON.stringify(deptpost))
                                    )
                                  "
                                  class="editbtn"
                                >
                                  <span
                                    class="material-icons-outlined lgFont mr-1"
                                    >edit</span
                                  >Edit</v-btn
                                >
                                <v-btn
                                  text
                                  class="removebtn"
                                  color="accentlight"
                                  @click="confirmRemove(deptpost.id)"
                                >
                                  <span class="material-icons lgFont mr-1"
                                    >delete</span
                                  >
                                  Remove</v-btn
                                >
                              </v-col>
                              <v-col cols="auto" class="pt-1 pb-2">
                                <v-radio-group
                                  class="mt-0 pt-0"
                                  hide-details
                                  @change="MarkInFocus(deptpost.id)"
                                  v-model="deptpost.isInFocus"
                                >
                                  <v-radio
                                    small
                                    hide-details
                                    class="
                                      mt-0
                                      pt-0
                                      customcheckbox
                                      customcheckbox2
                                    "
                                    label="IN FOCUS"
                                    :value="true"
                                  ></v-radio>
                                </v-radio-group>
                              </v-col>
                            </v-row>

                            <v-row class="mt-2 align-center">
                              <v-col class="accentdark--text pt-0 xxsFont">
                                <div class="p-relative">
                                  <div class="departtext p-relative seemorediv">
                                    <div v-html="deptpost.description"></div>
                                    <a
                                      class="readmoredot d-none"
                                      @click="previewDepartment(deptpost)"
                                    >
                                      Read More...</a
                                    >
                                  </div>
                                </div>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                </vue-scroll>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
    <v-dialog v-model="departmentpostDialog" scrollable max-width="800px">
      <v-card class="mb-0">
        <v-card-title
          class="accentdark--text borderBottom-1 border-accentxlight"
        >
          <v-row class="align-center">
            <v-col class="accentdark--text">{{ deptpopuptype }} Post</v-col>
            <v-col cols="auto"  class="d-none d-sm-block">
              <v-btn
                outlined
                class="accentlight--text mr-2"
                small
                @click="departmentpostDialog = false"
                >Cancel</v-btn
              >
              <v-btn
                outlined
                class="accent--text"
                small
                :disabled="
                  !this.deptPostModel.description ||
                  this.deptPostModel.description.trim() == '' ||
                  !this.deptpostFormValid ||
                  !this.deptPostModel.images ||
                  this.deptPostModel.images.length == 0
                "
                :loading="savingData"
                @click="savedeptpost"
                >Save</v-btn
              >
            </v-col>
          </v-row>
        </v-card-title>
        <vue-scroll :ops="ops">
          <v-card-text>
            <v-form
              ref="deptpostform"
              class="wd-100 py-3"
              v-model="deptpostFormValid"
            >
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    label="Heading"
                    :rules="[(v) => !!v.trim() || 'Heading is required']"
                    outlined
                    dense
                    v-model="deptPostModel.title"
                    maxlength="100"
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-select
                    label="Department"
                    outlined
                    :rules="[(v) => !!v || 'Department is required']"
                    :items="departments"
                    item-text="title"
                    item-value="id"
                    v-model="deptPostModel.departmentId"
                    dense
                    hide-details
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <div class="document-editor__toolbar"></div>
                  <div class="document-editor__editable-container">
                    <ckeditor
                      style="height: 180px !important; background-color: white"
                      :editor="editor"
                      :config="editorConfig"
                      :rules="[(v) => !!v.trim() || 'Description is required']"
                      v-model="deptPostModel.description"
                      @ready="onReady"
                    ></ckeditor>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pt-0">
                  <v-tabs
                    show-arrows
                    dense
                    class="browsetab"
                    height="40px"
                    v-model="photovideotab"
                  >
                    <v-tab active-class="base notransform accentdark--text"
                      >Upload a Photo</v-tab
                    >
                    <v-tab
                      class="notransform"
                      active-class="base  accentdark--text"
                      >Link a Video</v-tab
                    >

                    <v-tab-item class="pt-3 pl-3 pr-1">
                      <v-row>
                        <v-col cols="auto" class="pl-0">
                          <div class="imguploaderdiv uploadmultiimg" @click="onButtonClick">
                                 <span class="material-icons-outlined accentlight--text uploadicon"> upload_file </span>
                                 <p class="accentdark--text uploadtext mb-0"> Upload a Photo </p>
                                </div>

                          <!-- <v-btn
                            outlined
                            class="accentlight--text text-notransform px-8 py-4"
                            small
                            @click="onButtonClick"
                            >Browse</v-btn
                          > -->

                          <input
                            ref="fileuploadInput"
                            class="d-none"
                            type="file"
                            name="img"
                            multiple
                            accept="image/x-png,image/jpeg"
                            @change="uploadImage($event)"
                          />
                        </v-col>
                     
                        <v-col class="pl-0">
                          <img
                            v-for="(item, index) in deptPostModel.images"
                            :key="index"
                            class="imgpreview"
                          
                            :src="item.url"
                          />
                        </v-col>
                      </v-row> </v-tab-item
                    ><v-tab-item class="pt-3 pl-3 pr-1">
                      <v-text-field
                        label="Video Link"
                        prepend-inner-icon="mdi-link"
                        outlined
                        dense
                        v-model="deptPostModel.videoLink"
                        hide-details
                        :rules="NullableLinkrules"
                      ></v-text-field>
                    </v-tab-item>
                  </v-tabs>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </vue-scroll>
         <v-card-actions class="d-sm-none borderTop-1 border-accentxlight">
          <v-row class="align-center mb-n1 text-right justify-end">
            <v-col cols="auto" class="text-right pt-5 justify-end">
               <v-btn
                outlined
                class="accentlight--text mr-2"
                small
                @click="departmentpostDialog = false"
                >Cancel</v-btn
              >
              <v-btn
                outlined
                class="accent--text"
                small
                :disabled="
                  !this.deptPostModel.description ||
                  this.deptPostModel.description.trim() == '' ||
                  !this.deptpostFormValid ||
                  !this.deptPostModel.images ||
                  this.deptPostModel.images.length == 0
                "
                :loading="savingData"
                @click="savedeptpost"
                >Save</v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showRemoveConfirmDialog" max-width="350">
      <v-card class="mb-0">
        <v-card-text>
          <v-row>
            <v-col class="fw-500 mdFont accentdark--text">
              Are you sure you want to remove this?
            </v-col>
          </v-row>
          <v-row class="justify-end mt-1">
            <v-col cols="12" class="text-right">
              <v-btn
                outlined
                flat
                class="accentlight--text mr-2"
                small
                @click="showRemoveConfirmDialog = false"
                >Cancel</v-btn
              >
              <v-btn
                outlined
                flat
                class="accent--text"
                small
                @click="RemoveDepartmentPost(currentRemoveId)"
                >Yes</v-btn
              >
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="previewDeptDialog"
      content-class="previewDeptDialog"
      max-width="750"
      scrollable
    >
      <v-card class="mb-0">
        <vue-scroll  :ops="ops">
          <v-card-text
            class="pa-0 accentdark--text"
            v-if="deptPostModel.images.length > 0"
          >
            <v-row class="mx-0">
              <v-col class="px-0">
                <img
                  class="headerimg"
                  :src="deptPostModel.images[0].url"
                  style="width: 100%"
                />
              </v-col>
            </v-row>

            <v-row class="ma-0 pa-3">
              <v-col cols="12" class="pt-0">
                <v-row no-gutters v-if="deptPostModel.isInFocus">
                  <v-col cols="12">
                    <v-chip outlined color="accent" small
                      >Department in Focus</v-chip
                    >
                  </v-col>
                </v-row>

                <v-row no-gutters class="mt-3">
                  <v-col cols="12">
                    <h2>{{ deptPostModel.title }}</h2>
                  </v-col>
                </v-row>
                <v-row no-gutters v-if="deptPostModel.department">
                  <v-col cols="12" class="mt-3">
                    <p>
                      {{ deptPostModel.department.title }} | Dated:
                      {{ deptPostModel.publishedOn }}
                    </p>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col
                    cols="12"
                    class="xsFont"
                    v-html="deptPostModel.description"
                  >
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="12" md="10" class="mx-auto">
                    <v-slide-group
                      v-model="model"
                      class="px-0 py-1 newsslider newsmodalitems"
                      show-arrows
                    >
                      <v-slide-item
                        class="px-2"
                        v-for="(item, i) in deptPostModel.images"
                        :key="i"
                      >
                        <div class="ceomodalitems">
                          <iframe
                            v-if="item.assetType == 'drive'"
                            :src="item.url"
                            width="200px"
                            height="180px"
                          ></iframe>
                          <iframe
                            v-if="item.assetType == 'youtube'"
                            width="200px"
                            height="180px"
                            :src="item.url"
                            title="YouTube video player"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen
                          ></iframe>

                          <video-player
                            v-if="item.assetType == 'video'"
                            class="video-player-box"
                            ref="videoPlayerPopup"
                            :options="item.playerPopupOptions"
                          />
                          <img
                            v-if="item.assetType == 'Image'"
                            :src="item.url"
                          />
                        </div>
                      </v-slide-item>
                    </v-slide-group>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </vue-scroll>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import apimethods from "../../mixins/apimethods";
import helpermethods from "../../mixins/helpermethods";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import DocumentEditor from "@ckeditor/ckeditor5-build-decoupled-document";
export default {
  mixins: [apimethods, helpermethods],
  components: { ckeditor: CKEditor.component },
  data() {
    return {
      ops: {
        scrollPanel: {
          scrollingX: false,
        },
      }, // Your options
      filteredDepartment: 0,
      departments: [],
      departmentposts: [],
      currentRemoveId: 0,
      departmentpostDialog: false,
      previewDeptDialog: false,
      deptPostModel: { images: [], VideoLink: "" },
      deptpostFormValid: false,
      deptpopuptype: "Add",
      photovideotab: 0,
      savingData: false,
      editor: DocumentEditor,
      showRemoveConfirmDialog: false,
      editorConfig: {
        toolbar: {
          items: [
            "bold",
            "italic",
            "underline",
            "numberedList",
            "indent",
            "outdent",
          ],
          shouldNotGroupWhenFull: true,
        },
      },
    };
  },
  name: "DepartmentPage",
  computed: {
    departmentinfocus() {
      if (!this.departmentposts || this.departmentposts.length == 0)
        return null;
      return this.departmentposts.find((x) => x.isInFocus == true);
    },

    departmentpostsunfocused() {
      if (!this.departmentposts || this.departmentposts.length == 0) return [];

      if (this.filteredDepartment != 0) {
        return this.departmentposts.filter(
          (x) =>
            x.isInFocus == false &&
            x.departmentId == this.departments[this.filteredDepartment - 1].id
        );
      }

      return this.departmentposts.filter((x) => x.isInFocus == false);
    },
  },
  mounted() {
    this.GetDepartmentData();
  },
  methods: {
    previewDepartment(dept) {
      dept.images.forEach((element) => {
        if (element.assetType == "video") {
          element.playerOptions = {
            // videojs options
            muted: false,
            language: "en",
            width: "335px",
            height: "200px",
            sources: [
              {
                type: "video/mp4",
                src: element.url,
              },
            ],
          };
          element.playerPopupOptions = {
            // videojs options
            muted: false,
            language: "en",
            width: "250px",
            height: "180px",
            sources: [
              {
                type: "video/mp4",
                src: element.url,
              },
            ],
          };
        }
      });
      this.deptPostModel = dept;
      this.previewDeptDialog = true;
    },
    confirmRemove(deptpostid) {
      this.$data.showRemoveConfirmDialog = true;
      this.$data.currentRemoveId = deptpostid;
    },
    onReady(editor) {
      setTimeout(() => {
        const toolbarContainer = document.querySelector(
          ".document-editor__toolbar"
        );
        toolbarContainer.appendChild(editor.ui.view.toolbar.element);
        var pictureUploadbtn = toolbarContainer.getElementsByClassName(
          "ck-file-dialog-button"
        );
        if (pictureUploadbtn != undefined && pictureUploadbtn.length > 0)
          pictureUploadbtn[0].classList.add("d-none");
      }, 200);
    },
    onButtonClick() {
      this.$refs.fileuploadInput.click();
    },
    uploadImage(event) {
      if (event.target.files != null && event.target.files.length > 0) {
        var arrayFile = Array.from(event.target.files);

        if (arrayFile.length > 5) {
          this.$notify({
            group: "notification",
            title: "Error",
            text: "You cannot select more than 5 images",
            type: "error",
          });
          return;
        }

        this.deptPostModel.images = [];

        arrayFile = arrayFile.filter((x) => this.isFileImage(x));
        arrayFile.forEach((element) => {
          var imageUrlLocal = URL.createObjectURL(element);
          this.deptPostModel.images.push({
            url: imageUrlLocal,
            File: element,
          });
        });
      }
    },
    showdepartmentpostpopup(
      popuptype,
      editModel = { images: [], VideoLink: "" }
    ) {
      this.deptpopuptype = popuptype;
      this.deptPostModel = editModel;
      if (popuptype == "Edit") {
        this.deptPostModel.images = this.deptPostModel.images.filter(
          (x) => x.assetType == "Image"
        );
      }

      this.departmentpostDialog = true;
      this.photovideotab = 0;
      this.$refs.deptpostform.resetValidation();
    },
    GetDepartmentData() {
      this.filteredDepartment = 0;
      var self = this;
      this.$store.commit("showLoadingModal");
      var getdepartments = this.GetRequestWithAuthorization(
        "api/Department/Get",
        undefined
      )
        .then((res) => {
          if (
            res != undefined &&
            res.data != undefined &&
            res.data.data != undefined
          ) {
            self.departments = res.data.data;
          }
        })
        .catch((ex) => {});

      var getdepartmentposts = this.GetRequestWithAuthorization(
        "api/Department/GetPosts",
        undefined
      )
        .then((res) => {
          if (
            res != undefined &&
            res.data != undefined &&
            res.data.data != undefined
          ) {
            self.departmentposts = res.data.data;
            self.departmentposts.forEach((element) => {
              element.images.forEach((image) => {
                if (image.assetType == "Image") {
                  image.url = image.url + "?" + performance.now();
                }
              });
            });
            setTimeout(() => {
              self.allocateseemorediv(null);
            }, 10);
          }
        })
        .catch((ex) => {});

      Promise.all([getdepartments, getdepartmentposts]).then((values) => {
        this.$store.commit("hideLoadingModal");
      });
    },
    savedeptpost() {
      if (
        this.deptPostModel.images == undefined ||
        this.deptPostModel.images.length == 0
      ) {
        this.$notify({
          group: "notification",
          title: "Error",
          text: "Please upload image(s)",
          type: "error",
        });
        this.savingData = false;
        return;
      }
      if (
        this.deptPostModel.description == undefined ||
        this.deptPostModel.description == ""
      ) {
        this.$notify({
          group: "notification",
          title: "Error",
          text: "Please enter some description",
          type: "error",
        });
        this.savingData = false;
        return;
      }

      this.savingData = true;
      var self = this;
      var bodyFormData = new FormData();
      if (this.deptpopuptype == "Edit") {
        bodyFormData.append("id", this.deptPostModel.id);
      }
      bodyFormData.append("title", this.deptPostModel.title);
      if (
        this.deptPostModel.videoLink == null ||
        this.deptPostModel.videoLink == undefined ||
        !this.deptPostModel.videoLink
      ) {
        this.deptPostModel.videoLink = "";
      }
      bodyFormData.append("videoLink", this.deptPostModel.videoLink);
      bodyFormData.append("description", this.deptPostModel.description);
      if (this.deptPostModel.isInFocus)
        bodyFormData.append("isInFocus", this.deptPostModel.isInFocus);
      bodyFormData.append("departmentId", this.deptPostModel.departmentId);
      this.deptPostModel.images.forEach((element) => {
        if (element.File != null && element.File != undefined && element.File) {
          bodyFormData.append("file", element.File);
        }
      });

      this.$store.commit("showLoadingModal");

      var url = "api/Department/" + self.deptpopuptype + "Post";
      this.PostFormRequestWithAuthorization(url, undefined, bodyFormData)
        .then((res) => {
          this.$store.commit("hideLoadingModal");
          this.savingData = false;
          if (
            res != undefined &&
            res.data != undefined &&
            res.data.status == true
          ) {
            if (self.deptpopuptype == "Edit") {
              self.$notify({
                group: "notification",
                title: "Message",
                text: "Department Post Updated Successfully",
                type: "success",
              });
            } else {
              self.$notify({
                group: "notification",
                title: "Message",
                text: "Department Post Added Successfully",
                type: "success",
              });
            }

            self.departmentpostDialog = false;
            self.GetDepartmentData();
          } else {
            self.$notify({
              group: "notification",
              title: "Error",
              text: res.data.errorMessage,
              type: "error",
            });
          }
        })
        .catch((ex) => {
          this.$store.commit("hideLoadingModal");
          this.savingData = false;
          console.log(ex);
        });
    },
    RemoveDepartmentPost(deptpostid) {
      this.$data.showRemoveConfirmDialog = false;
      var self = this;
      this.$store.commit("showLoadingModal");
      this.GetRequestWithAuthorization("api/Department/RemovePost", [
        {
          Key: "departmentPostId",
          Value: deptpostid,
        },
      ])
        .then((res) => {
          this.$store.commit("hideLoadingModal");
          if (
            res != undefined &&
            res.data != undefined &&
            res.data.status == true
          ) {
            self.$notify({
              group: "notification",
              title: "Message",
              text: "Department Post Removed Successfully",
              type: "success",
            });
            self.GetDepartmentData();
          } else {
            self.$notify({
              group: "notification",
              title: "Error",
              text: res.data.errorMessage,
              type: "error",
            });
          }
        })
        .catch((ex) => {
          this.$store.commit("hideLoadingModal");
          console.log(ex);
        });
    },
    MarkInFocus(deptpostid) {
      var self = this;
      this.$store.commit("showLoadingModal");
      this.GetRequestWithAuthorization("api/Department/MarkInFocus", [
        {
          Key: "departmentPostId",
          Value: deptpostid,
        },
      ])
        .then((res) => {
          this.$store.commit("hideLoadingModal");
          if (
            res != undefined &&
            res.data != undefined &&
            res.data.status == true
          ) {
            self.$notify({
              group: "notification",
              title: "Message",
              text: "Department Post marked Focused.",
              type: "success",
            });
            self.GetDepartmentData();
          } else {
            self.$notify({
              group: "notification",
              title: "Error",
              text: res.data.errorMessage,
              type: "error",
            });
          }
        })
        .catch((ex) => {
          this.$store.commit("hideLoadingModal");
          console.log(ex);
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
