import Vuex from 'vuex'
import Vue from 'vue'
Vue.use(Vuex)

export default new Vuex.Store({
    state:{
        showLoading:false
    },
    mutations: {
        showLoadingModal (state) {
          state.showLoading = true;
        },
        hideLoadingModal (state) {
            state.showLoading = false;
          }
      }
});