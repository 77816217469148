<template>
  <v-row class="d-flex fill-height ma-0">
    <v-col class="px-0 pl-md-0 pr-lg-3 pt-0 pt-lg-3">
      <v-row class="ma-0 fill-height p-relative box-shadow-1">
        <v-col class="white rounded d-flex fill-height flex-column p-absolute">
          <v-row class="align-center">
            <v-col class="lgFont accentdark--text fw-700">
              Noticeboard List
            </v-col>
            <v-col cols="auto">
              <v-btn
                class="accent white--text"
                @click.stop="openDialog('create')"
                ><span class="material-icons-outlined mr-1 lgFont"
                  >add_circle_outline</span
                >Add Notice</v-btn
              >
            </v-col>
          </v-row>

          <v-row class="fill-height p-relative generalscroller mb-0">
            <v-col>
              <v-row class="fill-height p-absolute wd-100 pr-1">
                <vue-scroll>
                  <v-col cols="12">
                    <v-tabs
                      show-arrows
                      dense
                      class="browsetab generaltab"
                      height="45px"
                    >
                      <v-tab active-class="base accentdark--text">All</v-tab>
                      <v-tab active-class="base accentdark--text">Active</v-tab>
                      <v-tab active-class="base accentdark--text"
                        >Inactive</v-tab
                      >

                      <v-tab-item class="pt-3 pl-3 pr-1">
                        <v-row
                          v-for="item in listofInAllNoticeboard"
                          :key="item.id"
                          class="border-1 border-secondary rounded my-2 mr-0 xxsFont"
                        >
                          <v-col md="auto">
                            <img
                              :src="item.imageUrl"
                              class="noticeimg rounded"
                            />
                          </v-col>
                          <v-col
                            cols="auto"
                            sm="5"
                            class="text-right pr-2 pt-2 pb-0 d-md-none d-flex d-sm-block flex-column"
                          >
                            <v-btn
                              text
                              color="accentlight"
                              medium
                              class="mr-0 mr-sm-4 px-0 editbtn mb-2 mb-sm-0 d-block d-sm-inline-block mobaligned"
                              @click="openDialog('edit', item)"
                            >
                              <span class="material-icons-outlined lgFont mr-1"
                                >edit</span
                              >Edit</v-btn
                            >
                            <v-btn
                              text
                              color="accentlight"
                              medium
                              class="mr-sm-4 removebtn pa-0 mb-2 mb-sm-0 px-md-3 d-block d-sm-inline-block mobaligned"
                              @click="confirmRemove(item.id)"
                            >
                              <span class="material-icons lgFont mr-1"
                                >delete</span
                              >Remove</v-btn
                            >
                            <div class="d-block mobaligned d-sm-inline-block">
                              <v-switch
                                class="mt-0 pt-0 activeswitch"
                                hide-details
                                dense
                                v-model="item.isActive"
                                color="accent"
                                label="Active"
                                @change="
                                  ChangeActiveStatus(item.id, item.isActive)
                                "
                              ></v-switch>
                            </div>
                          </v-col>
                          <v-col
                            cols="12"
                            class="col-md pl-6 pt-0 pt-md-3 pl-md-3"
                          >
                            <v-row class="align-center">
                              <v-col
                                cols="3"
                                sm="2"
                                md="1"
                                class="accentlight--text px-0"
                                >Title:</v-col
                              >
                              <v-col class="accentdark--text">{{
                                item.title
                              }}</v-col>
                              <v-col
                                cols="auto"
                                class="text-right d-none d-md-block"
                              >
                                <v-btn
                                  text
                                  color="accentlight"
                                  medium
                                  class="mr-0 px-0 editbtn"
                                  @click="openDialog('edit', item)"
                                >
                                  <span
                                    class="material-icons-outlined lgFont mr-1"
                                    >edit</span
                                  >Edit</v-btn
                                >
                                <v-btn
                                  text
                                  color="accentlight"
                                  medium
                                  class="mr-1 removebtn"
                                  @click="confirmRemove(item.id)"
                                >
                                  <span class="material-icons lgFont mr-1"
                                    >delete</span
                                  >Remove</v-btn
                                >
                                <div class="d-inline-block">
                                  <v-switch
                                    class="mt-0 pt-0 activeswitch"
                                    hide-details
                                    dense
                                    v-model="item.isActive"
                                    color="accent"
                                    label="Active"
                                    @change="
                                      ChangeActiveStatus(item.id, item.isActive)
                                    "
                                  ></v-switch>
                                </div>
                              </v-col>
                            </v-row>
                            <v-row class="mt-2 align-center">
                              <v-col
                                cols="3"
                                sm="2"
                                md="1"
                                class="accentlight--text pt-0 px-0"
                                >Subtitle:</v-col
                              >
                              <v-col class="accentdark--text pt-0">{{
                                item.subtitle
                              }}</v-col>
                            </v-row>
                            <v-row class="mt-2 align-center">
                              <v-col
                                cols="3"
                                sm="2"
                                md="1"
                                class="accentlight--text pt-0 px-0"
                                >Button Text:</v-col
                              >
                              <v-col
                                cols="9"
                                sm="10"
                                md="2"
                                class="accentdark--text pt-0"
                                >{{ item.buttonText }}</v-col
                              >
                              <v-col
                                cols="3"
                                md="1"
                                sm="2"
                                class="accentlight--text pt-0 px-0"
                                >Button Style:</v-col
                              >
                              <v-col
                                cols="9"
                                sm="10"
                                md="2"
                                class="accentdark--text pt-0"
                              >
                                <v-btn
                                  outlined
                                  class="accent--text py-3"
                                  x-small
                                  v-if="item.buttonStyle == 1"
                                  >Button</v-btn
                                >
                                <v-btn
                                  filled
                                  color="accent"
                                  class="white--text py-3"
                                  x-small
                                  v-if="item.buttonStyle == 2"
                                  >Button</v-btn
                                >
                                <v-btn
                                  filled
                                  color="accentdark"
                                  class="white--text py-3"
                                  x-small
                                  v-if="item.buttonStyle == 3"
                                  >Button</v-btn
                                >
                              </v-col>
                              <v-col
                                cols="3"
                                sm="2"
                                md="1"
                                class="accentlight--text pt-0 px-0"
                                >Button Link:</v-col
                              >
                              <v-col
                                cols="9"
                                sm="10"
                                class="col-md accentdark--text pt-0"
                              >
                                <v-text-field
                                  label="Button Links"
                                  prepend-inner-icon="mdi-link"
                                  outlined
                                  solo
                                  flat
                                  class="buttonlink"
                                  dense
                                  hide-details
                                  disabled
                                  v-model="item.url"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-tab-item>
                      <v-tab-item class="pt-3 pl-3 pr-1">
                        <v-row
                          v-for="item in listofActiveNoticeboard"
                          :key="item.id"
                          class="border-1 border-secondary rounded my-2 mr-0 xxsFont"
                        >
                          <v-col md="auto">
                            <img
                              :src="item.imageUrl"
                              class="noticeimg rounded"
                            />
                          </v-col>
                          <v-col
                            cols="auto"
                            sm="5"
                            class="text-right pr-2 pt-2 pb-0 d-md-none d-flex d-sm-block flex-column"
                          >
                            <v-btn
                              text
                              color="accentlight"
                              medium
                              class="mr-0 mr-sm-4 px-0 editbtn mb-2 mb-sm-0 d-block d-sm-inline-block mobaligned"
                              @click="openDialog('edit', item)"
                            >
                              <span class="material-icons-outlined lgFont mr-1"
                                >edit</span
                              >Edit</v-btn
                            >
                            <v-btn
                              text
                              color="accentlight"
                              medium
                              class="mr-sm-4 removebtn pa-0 mb-2 mb-sm-0 px-md-3 d-block d-sm-inline-block mobaligned"
                              @click="confirmRemove(item.id)"
                            >
                              <span class="material-icons lgFont mr-1"
                                >delete</span
                              >Remove</v-btn
                            >
                            <div class="d-block mobaligned d-sm-inline-block">
                              <v-switch
                                class="mt-0 pt-0 activeswitch"
                                hide-details
                                dense
                                v-model="item.isActive"
                                color="accent"
                                label="Active"
                                @change="
                                  ChangeActiveStatus(item.id, item.isActive)
                                "
                              ></v-switch>
                            </div>
                          </v-col>
                          <v-col
                            cols="12"
                            class="col-md pl-6 pt-0 pt-md-3 pl-md-3"
                          >
                            <v-row class="align-center">
                              <v-col
                                cols="3"
                                sm="2"
                                md="1"
                                class="accentlight--text px-0"
                                >Title:</v-col
                              >
                              <v-col class="accentdark--text">{{
                                item.title
                              }}</v-col>
                              <v-col
                                cols="auto"
                                class="text-right d-none d-md-block"
                              >
                                <v-btn
                                  text
                                  color="accentlight"
                                  medium
                                  class="mr-0 px-0 editbtn"
                                  @click="openDialog('edit', item)"
                                >
                                  <span
                                    class="material-icons-outlined lgFont mr-1"
                                    >edit</span
                                  >Edit</v-btn
                                >
                                <v-btn
                                  text
                                  color="accentlight"
                                  medium
                                  class="mr-1 removebtn"
                                  @click="confirmRemove(item.id)"
                                >
                                  <span class="material-icons lgFont mr-1"
                                    >delete</span
                                  >Remove</v-btn
                                >
                                <div class="d-inline-block">
                                  <v-switch
                                    class="mt-0 pt-0 activeswitch"
                                    hide-details
                                    dense
                                    v-model="item.isActive"
                                    color="accent"
                                    label="Active"
                                    @change="
                                      ChangeActiveStatus(item.id, item.isActive)
                                    "
                                  ></v-switch>
                                </div>
                              </v-col>
                            </v-row>
                            <v-row class="mt-2 align-center">
                              <v-col
                                cols="3"
                                sm="2"
                                md="1"
                                class="accentlight--text pt-0 px-0"
                                >Subtitle:</v-col
                              >
                              <v-col class="accentdark--text pt-0">{{
                                item.subtitle
                              }}</v-col>
                            </v-row>
                            <v-row class="mt-2 align-center">
                              <v-col
                                cols="3"
                                sm="2"
                                md="1"
                                class="accentlight--text pt-0 px-0"
                                >Button Text:</v-col
                              >
                              <v-col
                                cols="9"
                                sm="10"
                                md="2"
                                class="accentdark--text pt-0"
                                >{{ item.buttonText }}</v-col
                              >
                              <v-col
                                cols="3"
                                md="1"
                                sm="2"
                                class="accentlight--text pt-0 px-0"
                                >Button Style:</v-col
                              >
                              <v-col
                                cols="9"
                                sm="10"
                                md="2"
                                class="accentdark--text pt-0"
                              >
                                <v-btn
                                  outlined
                                  class="accent--text py-3"
                                  x-small
                                  v-if="item.buttonStyle == 1"
                                  >Button</v-btn
                                >
                                <v-btn
                                  filled
                                  color="accent"
                                  class="white--text py-3"
                                  x-small
                                  v-if="item.buttonStyle == 2"
                                  >Button</v-btn
                                >
                                <v-btn
                                  filled
                                  color="accentdark"
                                  class="white--text py-3"
                                  x-small
                                  v-if="item.buttonStyle == 3"
                                  >Button</v-btn
                                >
                              </v-col>
                              <v-col
                                cols="3"
                                sm="2"
                                md="1"
                                class="accentlight--text pt-0 px-0"
                                >Button Link:</v-col
                              >
                              <v-col
                                cols="9"
                                sm="10"
                                class="col-md accentdark--text pt-0"
                              >
                                <v-text-field
                                  label="Button Links"
                                  prepend-inner-icon="mdi-link"
                                  outlined
                                  solo
                                  flat
                                  class="buttonlink"
                                  dense
                                  hide-details
                                  disabled
                                  v-model="item.url"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-tab-item>
                      <v-tab-item class="pt-3 pl-3 pr-1">
                        <v-row
                          v-for="item in listofInActiveNoticeboard"
                          :key="item.id"
                          class="border-1 border-secondary rounded my-2 mr-0 xxsFont"
                        >
                          <v-col md="auto">
                            <img
                              :src="item.imageUrl"
                              class="noticeimg rounded"
                            />
                          </v-col>
                          <v-col
                            cols="auto"
                            sm="5"
                            class="text-right pr-2 pt-2 pb-0 d-md-none d-flex d-sm-block flex-column"
                          >
                            <v-btn
                              text
                              color="accentlight"
                              medium
                              class="mr-0 mr-sm-4 px-0 editbtn mb-2 mb-sm-0 d-block d-sm-inline-block mobaligned"
                              @click="openDialog('edit', item)"
                            >
                              <span class="material-icons-outlined lgFont mr-1"
                                >edit</span
                              >Edit</v-btn
                            >
                            <v-btn
                              text
                              color="accentlight"
                              medium
                              class="mr-sm-4 removebtn pa-0 mb-2 mb-sm-0 px-md-3 d-block d-sm-inline-block mobaligned"
                              @click="confirmRemove(item.id)"
                            >
                              <span class="material-icons lgFont mr-1"
                                >delete</span
                              >Remove</v-btn
                            >
                            <div class="d-block mobaligned d-sm-inline-block">
                              <v-switch
                                class="mt-0 pt-0 activeswitch"
                                hide-details
                                dense
                                v-model="item.isActive"
                                color="accent"
                                label="Active"
                                @change="
                                  ChangeActiveStatus(item.id, item.isActive)
                                "
                              ></v-switch>
                            </div>
                          </v-col>
                          <v-col
                            cols="12"
                            class="col-md pl-6 pt-0 pt-md-3 pl-md-3"
                          >
                            <v-row class="align-center">
                              <v-col
                                cols="3"
                                sm="2"
                                md="1"
                                class="accentlight--text px-0"
                                >Title:</v-col
                              >
                              <v-col class="accentdark--text">{{
                                item.title
                              }}</v-col>
                              <v-col
                                cols="auto"
                                class="text-right d-none d-md-block"
                              >
                                <v-btn
                                  text
                                  color="accentlight"
                                  medium
                                  class="mr-0 px-0 editbtn"
                                  @click="openDialog('edit', item)"
                                >
                                  <span
                                    class="material-icons-outlined lgFont mr-1"
                                    >edit</span
                                  >Edit</v-btn
                                >
                                <v-btn
                                  text
                                  color="accentlight"
                                  medium
                                  class="mr-1 removebtn"
                                  @click="confirmRemove(item.id)"
                                >
                                  <span class="material-icons lgFont mr-1"
                                    >delete</span
                                  >Remove</v-btn
                                >
                                <div class="d-inline-block">
                                  <v-switch
                                    class="mt-0 pt-0 activeswitch"
                                    hide-details
                                    dense
                                    v-model="item.isActive"
                                    color="accent"
                                    label="Active"
                                    @change="
                                      ChangeActiveStatus(item.id, item.isActive)
                                    "
                                  ></v-switch>
                                </div>
                              </v-col>
                            </v-row>
                            <v-row class="mt-2 align-center">
                              <v-col
                                cols="3"
                                sm="2"
                                md="1"
                                class="accentlight--text pt-0 px-0"
                                >Subtitle:</v-col
                              >
                              <v-col class="accentdark--text pt-0">{{
                                item.subtitle
                              }}</v-col>
                            </v-row>
                            <v-row class="mt-2 align-center">
                              <v-col
                                cols="3"
                                sm="2"
                                md="1"
                                class="accentlight--text pt-0 px-0"
                                >Button Text:</v-col
                              >
                              <v-col
                                cols="9"
                                sm="10"
                                md="2"
                                class="accentdark--text pt-0"
                                >{{ item.buttonText }}</v-col
                              >
                              <v-col
                                cols="3"
                                md="1"
                                sm="2"
                                class="accentlight--text pt-0 px-0"
                                >Button Style:</v-col
                              >
                              <v-col
                                cols="9"
                                sm="10"
                                md="2"
                                class="accentdark--text pt-0"
                              >
                                <v-btn
                                  outlined
                                  class="accent--text py-3"
                                  x-small
                                  v-if="item.buttonStyle == 1"
                                  >Button</v-btn
                                >
                                <v-btn
                                  filled
                                  color="accent"
                                  class="white--text py-3"
                                  x-small
                                  v-if="item.buttonStyle == 2"
                                  >Button</v-btn
                                >
                                <v-btn
                                  filled
                                  color="accentdark"
                                  class="white--text py-3"
                                  x-small
                                  v-if="item.buttonStyle == 3"
                                  >Button</v-btn
                                >
                              </v-col>
                              <v-col
                                cols="3"
                                sm="2"
                                md="1"
                                class="accentlight--text pt-0 px-0"
                                >Button Link:</v-col
                              >
                              <v-col
                                cols="9"
                                sm="10"
                                class="col-md accentdark--text pt-0"
                              >
                                <v-text-field
                                  label="Button Links"
                                  prepend-inner-icon="mdi-link"
                                  outlined
                                  solo
                                  flat
                                  class="buttonlink"
                                  dense
                                  hide-details
                                  disabled
                                  v-model="item.url"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-tab-item>
                    </v-tabs>
                  </v-col> </vue-scroll></v-row
            ></v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
    <v-dialog v-model="popupDialog" scrollable max-width="700px">
      <v-card class="mb-0">
        <v-card-title
          class="accentdark--text borderBottom-1 border-accentxlight"
        >
          <v-row class="align-center">
            <v-col class="accentdark--text">{{ PopupHeading }}</v-col>
            <v-col cols="auto" class="d-none d-sm-block">
              <v-btn
                outlined
                class="mr-2"
                style="color: #df492f"
                v-if="ShowDeleteButtonOnPopup"
                small
                @click="RemoveNoticeboardFromPopup()"
                >Delete</v-btn
              >
              <v-btn
                outlined
                class="accentlight--text mr-2"
                small
                @click="popupDialog = false"
                >Cancel</v-btn
              >
              <v-btn
                outlined
                class="accent--text"
                small
                :disabled="!isValid || !pickerImageUrl"
                @click="SubmitForm"
                :loading="PopupData.savingData"
                >Save</v-btn
              >
            </v-col>
          </v-row>
        </v-card-title>
        <vue-scroll>
          <v-form v-model="isValid">
            <v-card-text>
              <v-row>
                <v-col cols="12" class="noticeboardimgdiv">
                  <div
                    class="imguploaderdiv"
                    v-if="!pickerImageUrl"
                    @click="Cropperdialog = true"
                  >
                    <span
                      class="material-icons-outlined accentlight--text uploadicon"
                    >
                      upload_file
                    </span>
                    <p class="accentdark--text xlgFont py-2 py-sm-5 mb-0">
                      Upload a Image
                    </p>
                  </div>

                  <img
                    v-if="pickerImageUrl"
                    :src="pickerImageUrl"
                    class="noticeimgupload"
                    @click="Cropperdialog = true"
                  />

                  <div
                    class="imguploaderdiv noticeboardhover"
                    @click="Cropperdialog = true"
                    v-if="pickerImageUrl"
                  >
                    <span
                      class="material-icons-outlined accentlight--text uploadicon"
                    >
                      upload_file
                    </span>
                    <p class="accentdark--text xlgFont py-2 py-sm-5 mb-0">
                      <b>Choose a file</b> or drag it here
                    </p>
                  </div>

                  <div class=""></div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field
                    label="Title"
                    outlined
                    dense
                    hide-details
                    maxlength="200"
                    v-model="PopupData.Title"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    label="Subtitle"
                    outlined
                    dense
                    hide-details
                    maxlength="200"
                    v-model="PopupData.SubTitle"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="fw-700 accentdark--text">Button Properties</v-col>
              </v-row>
              <v-row class="mt-0">
                <v-col cols="12" sm="6">
                  <v-text-field
                    label="Button Text"
                    outlined
                    dense
                    maxlength="50"
                    hide-details
                    v-model="PopupData.ButtonText"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" class="">
                  <v-row
                    no-gutters
                    class="align-center border-1 border-secondary rounded px-3 buttonstyles py-1 height-40"
                  >
                    <v-col class="accentdark--text">Button Style</v-col>
                    <v-col cols="auto" class="pr-1 pr-md-3">
                      <v-btn
                        outlined
                        x-small
                        class="py-3 accent--text"
                        :class="{
                          selectedbutton: PopupData.ButtonStyleTypeId === 1,
                        }"
                        @click="setButtonStyleTypeId(1)"
                        >Button</v-btn
                      >
                    </v-col>
                    <v-col cols="auto" class="pr-1 pr-md-3">
                      <v-btn
                        filled
                        color="accent"
                        x-small
                        class="py-3 white--text"
                        :class="{
                          selectedbutton: PopupData.ButtonStyleTypeId === 2,
                        }"
                        @click="setButtonStyleTypeId(2)"
                        >Button</v-btn
                      >
                    </v-col>
                    <v-col cols="auto">
                      <v-btn
                        filled
                        color="accentdark"
                        x-small
                        class="py-3 white--text"
                        :class="{
                          selectedbutton: PopupData.ButtonStyleTypeId === 3,
                        }"
                        @click="setButtonStyleTypeId(3)"
                        >Button</v-btn
                      ></v-col
                    >
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    label="Button Links"
                    prepend-inner-icon="mdi-link"
                    outlined
                    dense
                    hide-details
                    :rules="NullableURLprotocolrules"
                    v-model="PopupData.ButtonLink"
                    placeholder="https://"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-form>
        </vue-scroll>
        <v-card-actions class="d-sm-none borderTop-1 border-accentxlight">
          <v-row class="align-center mb-n1 text-right justify-end">
            <v-col cols="auto" class="text-right justify-end">
              <v-btn
                outlined
                class="mr-2"
                style="color: #df492f"
                v-if="ShowDeleteButtonOnPopup"
                small
                @click="RemoveNoticeboardFromPopup()"
                >Delete</v-btn
              >
              <v-btn
                outlined
                class="accentlight--text mr-2"
                small
                @click="popupDialog = false"
                >Cancel</v-btn
              >
              <v-btn
                outlined
                class="accent--text"
                small
                :disabled="!isValid || !pickerImageUrl"
                @click="SubmitForm"
                :loading="PopupData.savingData"
                >Save</v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="Cropperdialog"
      content-class="imagecropermodal"
      max-width="320"
      style="height: 500px"
    >
      <v-card class="mb-0">
        <v-card-title
          class="accentdark--text borderBottom-1 border-accentxlight"
        >
          <v-row class="align-center">
            <v-col class="accentdark--text">Banner Image</v-col>
            <v-col cols="auto">
              <v-btn
                outlined
                class="accentlight--text mr-2"
                small
                @click="cancelCrop"
                >Cancel</v-btn
              >
              <v-btn
                outlined
                class="accent--text"
                small
                @click="SaveImageCrop"
                :disabled="!imageuploadedforCropping"
                >Save</v-btn
              >
            </v-col>
          </v-row>
        </v-card-title>

        <v-card-text>
          <image-cropper
            v-on:imageUploaded="imageuploadedforCropping = true"
            ref="imagecropComp"
            :AspectRatioHeight="1"
            :AspectRatioWidth="2"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showRemoveConfirmDialog" max-width="350">
      <v-card class="mb-0">
        <v-card-text>
          <v-row>
            <v-col class="fw-500 mdFont accentdark--text">
              Are you sure you want to remove this item?
            </v-col>
          </v-row>
          <v-row class="justify-end mt-1">
            <v-col cols="12" class="text-right">
              <v-btn
                outlined
                class="accentlight--text mr-2"
                small
                @click="showRemoveConfirmDialog = false"
                >Cancel</v-btn
              >
              <v-btn
                outlined
                class="accent--text"
                small
                @click="RemoveNoticeboard(currentRemoveId)"
                >Yes</v-btn
              >
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import ImageCropper from "../../components/Popup/ImageCropper.vue";
import apimethods from "../../mixins/apimethods";
import helpermethods from "../../mixins/helpermethods";

export default {
  components: {
    ImageCropper,
  },
  mixins: [apimethods, helpermethods],
  data() {
    return {
      imageuploadedforCropping: false,
      popupDialog: false,
      pickerImageUrl: undefined,
      isValid: false,
      Required: [(v) => !!v || "Item is required"],
      RequiredAndLimit: [
        (v) => !!v || "Item is required",
        (v) => (v && v.length <= 50) || "Value must be less than 50 characters",
      ],
      listofInAllNoticeboard: [],
      listofActiveNoticeboard: [],
      listofInActiveNoticeboard: [],
      updateImage: false,
      NoticeboardtoBeEdit: {},
      ShowDeleteButtonOnPopup: false,
      image: {
        size: "",
        height: "",
        width: "",
      },
      PopupData: {
        Title: "",
        SubTitle: "",
        ButtonText: "",
        ButtonLink: "",
        ButtonStyleTypeId: 0,
        savingData: false,
      },
      Cropperdialog: false,
      showRemoveConfirmDialog: false,
      currentRemoveId: 0,
      popupType: "",
    };
  },
  name: "NoticeboardPage",
  props: {
    msg: String,
  },
  watch: {
    Cropperdialog: function (newValue, oldValue) {
      if (!newValue) this.cancelCrop();
      if (newValue && this.$refs.imagecropComp) {
        setTimeout(() => {
          this.$refs.imagecropComp.onButtonClick();
        }, 10);
      }
    },
  },
  created() {
    this.GetBanners();
  },
  methods: {
    cancelCrop() {
      this.$refs.imagecropComp.$data.img = null;
      this.$refs.imagecropComp.$refs.uploader.value = "";
      this.$refs.imagecropComp.CroppedImage = undefined;
      this.$data.imageuploadedforCropping = false;
      this.Cropperdialog = false;
    },
    SaveImageCrop() {
      this.updateImage = true;
      if (this.$refs.imagecropComp.CroppedImage != undefined)
        this.pickerImageUrl =
          this.$refs.imagecropComp.CroppedImage.toDataURL("image/jpg");
      this.Cropperdialog = false;
      this.$refs.imagecropComp.CroppedImage = undefined;
      this.$refs.imagecropComp.$data.img = null;
      this.$data.imageuploadedforCropping = false;
    },
    openDialog(popType, item) {
      this.updateImage = false;
      this.popupType = popType;
      this.popupDialog = true;
      if (popType == "edit") {
        this.ShowDeleteButtonOnPopup = true;
        this.NoticeboardtoBeEdit = item;
        this.PopupHeading = "Edit Noticeboard";
        this.PopupData.Title = item.title;
        this.PopupData.SubTitle = item.subtitle;
        this.PopupData.ButtonText = item.buttonText;
        this.PopupData.ButtonLink = item.url;
        this.pickerImageUrl = item.imageUrl;
        this.PopupData.ButtonStyleTypeId = item.buttonStyle;
      } else {
        this.PopupHeading = "Add Notice";
        this.ShowDeleteButtonOnPopup = false;
        this.pickerImageUrl = "";
        this.PopupData.Title = "";
        this.PopupData.SubTitle = "";
        this.PopupData.ButtonText = "";
        this.PopupData.ButtonLink = "";
      }
    },
    SubmitForm() {
      if (this.isValid) {
        if (this.popupType == "create") {
          this.SaveNoticeboard();
        } else {
          this.EditNoticeboard();
        }
      }
    },
    confirmRemove(noticeboardId) {
      this.$data.showRemoveConfirmDialog = true;
      this.$data.currentRemoveId = noticeboardId;
    },
    SaveNoticeboard() {
      if (this.pickerImageUrl == undefined || this.pickerImageUrl == "") {
        this.$notify({
          group: "notification",
          type: "error",
          title: "Message",
          text: "Please upload Image",
        });
      }
      var bodyFormData = new FormData();
      bodyFormData.append("title", this.PopupData.Title);
      bodyFormData.append("subtitle", this.PopupData.SubTitle);
      bodyFormData.append("buttonText", this.PopupData.ButtonText);
      bodyFormData.append("url", this.PopupData.ButtonLink);
      bodyFormData.append("buttonStyle", this.PopupData.ButtonStyleTypeId);

      var filename = this.generateUUID() + ".png";
      bodyFormData.append("BannerImageName", filename);

      var blobBin = atob(this.pickerImageUrl.split(",")[1]);
      var array = [];
      for (var i = 0; i < blobBin.length; i++) {
        array.push(blobBin.charCodeAt(i));
      }
      var file = new Blob([new Uint8Array(array)], { type: "image/png" });
      bodyFormData.append("file", file);

      var self = this;
      self.PopupData.savingData = true;
      this.PostFormRequestWithAuthorization(
        "api/Noticeboard/Add",
        undefined,
        bodyFormData
      )
        .then((res) => {
          this.$store.commit("hideLoadingModal");
          self.popupDialog = false;
          self.PopupData.savingData = false;
          if (
            res != undefined &&
            res.data != undefined &&
            res.data.status == true
          ) {
            self.$notify({
              group: "notification",
              title: "Message",
              text: "Notice Added Successfully",
            });
            self.GetBanners();
          } else if (
            res != undefined &&
            res.data != undefined &&
            res.data.status == false
          ) {
            self.$notify({
              group: "notification",
              type: "error",
              title: "Message",
              text: res.data.errorMessage,
            });
          }
        })
        .catch((ex) => {
          this.$store.commit("hideLoadingModal");
          self.PopupData.savingData = false;
          console.log(ex);
        });
    },
    EditNoticeboard() {
      if (
        this.updateImage &&
        (this.pickerImageUrl == undefined || this.pickerImageUrl == "")
      ) {
        this.$notify({
          group: "notification",
          type: "error",
          title: "Message",
          text: "Please upload Image",
        });
      }
      var bodyFormData = new FormData();
      bodyFormData.append("id", this.NoticeboardtoBeEdit.id);
      bodyFormData.append("title", this.PopupData.Title);
      bodyFormData.append("subtitle", this.PopupData.SubTitle);
      bodyFormData.append("buttonText", this.PopupData.ButtonText);
      bodyFormData.append("url", this.PopupData.ButtonLink);
      bodyFormData.append("updateImage", this.updateImage);
      bodyFormData.append("buttonStyle", this.PopupData.ButtonStyleTypeId);

      if (this.updateImage) {
        var filename = this.generateUUID() + ".png";
        bodyFormData.append("BannerImageName", filename);

        var blobBin = atob(this.pickerImageUrl.split(",")[1]);
        var array = [];
        for (var i = 0; i < blobBin.length; i++) {
          array.push(blobBin.charCodeAt(i));
        }
        var file = new Blob([new Uint8Array(array)], { type: "image/png" });
        bodyFormData.append("file", file);
      }

      var self = this;
      self.PopupData.savingData = true;
      this.PostFormRequestWithAuthorization(
        "api/Noticeboard/Edit",
        undefined,
        bodyFormData
      )
        .then((res) => {
          this.$store.commit("hideLoadingModal");

          self.PopupData.savingData = false;
          self.popupDialog = false;
          if (
            res != undefined &&
            res.data != undefined &&
            res.data.status == true
          ) {
            self.$notify({
              group: "notification",
              title: "Message",
              text: "Notice updated Successfully",
              type: "success",
            });
            self.GetBanners();
          } else if (
            res != undefined &&
            res.data != undefined &&
            res.data.status == false
          ) {
            self.$notify({
              group: "notification",
              type: "error",
              title: "Message",
              text: res.data.errorMessage,
            });
          }
        })
        .catch((ex) => {
          this.$store.commit("hideLoadingModal");
          self.PopupData.savingData = false;
          console.log(ex);
        });
    },
    generateUUID() {
      var d = new Date().getTime();
      if (
        typeof performance !== "undefined" &&
        typeof performance.now === "function"
      ) {
        d += performance.now(); //use high-precision timer if available
      }
      var newGuid = "xxxxxxxx".replace(/[xy]/g, function (c) {
        var r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
      });

      return newGuid;
    },
    GetBanners() {
      try {
        var self = this;
        this.$store.commit("showLoadingModal");

        var request = this.GetRequestWithAuthorization(
          "api/Noticeboard/Get",
          undefined
        );
        if (request != undefined) {
          request.then((res) => {
            this.$store.commit("hideLoadingModal");

            if (
              res != undefined &&
              res.data != undefined &&
              res.data.data != undefined
            ) {
              self.listofInAllNoticeboard = res.data.data;
              self.listofActiveNoticeboard = res.data.data.filter(
                (x) => x.isActive == true
              );
              self.listofInActiveNoticeboard = res.data.data.filter(
                (x) => x.isActive == false
              );
            }
          });
        }

        request.catch((ex) => {});
      } catch (exa) {
        this.$store.commit("hideLoadingModal");

        console.log(exa);
      }
    },
    ChangeActiveStatus(noticeboardId, active) {
      var self = this;
      this.$store.commit("showLoadingModal");

      this.GetRequestWithAuthorization("api/Noticeboard/MarkActiveUnActive", [
        {
          Key: "noticeboardId",
          Value: noticeboardId,
        },
        {
          Key: "active",
          Value: active,
        },
      ])
        .then((res) => {
          if (
            res != undefined &&
            res.data != undefined &&
            res.data.status == true
          ) {
            self.$notify({
              group: "notification",
              title: "Message",
              text: "Status Updated Successfully",
            });
            self.dialog = false;
            //alert("Status Changed");
            self.GetBanners();
          }
        })
        .catch((ex) => {
          this.$store.commit("hideLoadingModal");

          console.log(ex);
        });
    },
    RemoveNoticeboardFromPopup() {
      this.popupDialog = false;
      this.confirmRemove(this.NoticeboardtoBeEdit.id);
    },
    RemoveNoticeboard(noticeboardId) {
      this.$data.showRemoveConfirmDialog = false;
      var self = this;
      this.$store.commit("showLoadingModal");

      this.GetRequestWithAuthorization("api/Noticeboard/Remove", [
        {
          Key: "noticeboardId",
          Value: noticeboardId,
        },
      ])
        .then((res) => {
          if (
            res != undefined &&
            res.data != undefined &&
            res.data.status == true
          ) {
            self.$notify({
              group: "notification",
              title: "Message",
              text: "Notice removed Successfully",
            });
            //alert("Magazine removed");
            self.dialog = false;
            self.GetBanners();
          }
        })
        .catch((ex) => {
          this.$store.commit("hideLoadingModal");

          console.log(ex);
        });
    },
    setButtonStyleTypeId(id) {
      if (this.PopupData.ButtonStyleTypeId === id) {
        this.PopupData.ButtonStyleTypeId = 0;
      } else {
        this.PopupData.ButtonStyleTypeId = id;
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
