<template>
  <v-row class="dashboardview ma-0 base p-relative homeview min-height-100">
    <v-container class="mt-md-15 ma-3 mx-lg-auto pt-md-15 profilePage">
      <v-row class="white rounded align-center box-shadow-1 pt-3 pt-md-0">
        <v-col
          cols="12"
          class="pa-0 col-md-auto line-height-0 justify-center text-center"
        >
          <img :src="profileInfo.profileImageURL" class="profileimg" />
        </v-col>
        <v-col
          cols="12"
          class="py-0 pl-lg-8 text-center text-lg-left col-md justify-center"
        >
          <!-- <p class="accent--text mdFont mb-2 mt-3 mt-lg-0">{{ profileInfo.role }}</p> -->
          <p class="accentdark--text xlgFont fw-700 mb-1">
            {{ profileInfo.firstName }} {{ profileInfo.lastName }}
          </p>
          <p class="departmentchip">{{ profileInfo.department }}</p>
        </v-col>
      </v-row>
      <v-row class="mx-n6 mt-md-8 mt-4">
        <v-col class="pr-1 pr-md-3" cols="6" md="3">
          <div class="border-secondary rounded border-1 profileinfo">
            <div class="secondaryLight--text">
              <span class="line-height-0 material-icons lgFont line-h mr-1">
                badge
              </span>
              <span class="label">First Name</span>
            </div>
            <div>{{ profileInfo.firstName }}</div>
          </div>
        </v-col>
        <v-col class="pl-1 pl-md-3" cols="6" md="3">
          <div class="border-secondary rounded border-1 profileinfo">
            <div class="secondaryLight--text">
              <span class="line-height-0 material-icons lgFont line-h mr-1">
                badge
              </span>
              <span class="label">Last Name</span>
            </div>
            <div>{{ profileInfo.lastName }}</div>
          </div>
        </v-col>
        <v-col class="pl-1 pl-md-3 pt-0 pt-md-3" cols="6" md="3">
          <div class="border-secondary rounded border-1 profileinfo">
            <div class="secondaryLight--text">
              <span class="line-height-0 material-icons lgFont line-h mr-1">
                meeting_room
              </span>
              <span class="label">Department</span>
            </div>
            <div>{{ profileInfo.department }}</div>
          </div>
        </v-col>
        <!-- <v-col   class="pl-1 pl-md-3 pt-0 pt-md-3" cols="6" md="3">
          <div class="border-secondary rounded border-1 profileinfo">
            <div class="secondaryLight--text">
              <span class="line-height-0 material-icons lgFont line-h mr-1">
                fact_check
              </span>
              <span class="label">User Type</span>
            </div>
            <div>{{ profileInfo.role }}</div>
          </div>
        </v-col> -->
      </v-row>
      <v-row class="mx-n6 mt-0 mt-md-3">
        <!-- <v-col class="pr-1 pr-md-3" cols="6" md="3">
          <div class="border-secondary rounded border-1 profileinfo">
            <div class="secondaryLight--text">
              <span class="line-height-0 material-icons lgFont line-h mr-1">
                badge
              </span>
              <span class="label">Designation</span>
            </div>
            <div>{{ profileInfo.designation }}</div>
          </div>
        </v-col> -->
        <!-- <v-col class="pl-1 pl-md-3" cols="6" md="3">
          <div class="border-secondary rounded border-1 profileinfo">
            <div class="secondaryLight--text">
              <span class="line-height-0 material-icons lgFont line-h mr-1">
                meeting_room
              </span>
              <span class="label">Department</span>
            </div>
            <div>{{ profileInfo.department }}</div>
          </div>
        </v-col> -->
        <!-- <v-col   class="pr-1 pr-md-p3 pt-0 pt-md-3" cols="6" md="3">
          <div class="border-secondary rounded border-1 profileinfo">
            <div class="secondaryLight--text">
              <span class="line-height-0 material-icons lgFont line-h mr-1">
                wc
              </span>
              <span class="label">Gender</span>
            </div>
            <div>{{ profileInfo.genderString }}</div>
          </div>
        </v-col>
        <v-col   class="pl-1 pl-md-3 pt-0 pt-md-3" cols="6" md="3">
          <div class="border-secondary rounded border-1 profileinfo">
            <div class="secondaryLight--text">
              <span class="line-height-0 material-icons lgFont line-h mr-1">
                cake
              </span>
              <span class="label">Date of Birth</span>
            </div>
            <div>{{ profileInfo.dateOfBirthFormatted }}</div>
          </div>
        </v-col> -->
      </v-row>
      <v-row class="mx-n6 mt-0 mt-md-3">
        <!-- <v-col cols="6" md="3" class="pr-1 pr-md-3">
          <div class="border-secondary rounded border-1 profileinfo">
            <div class="secondaryLight--text">
              <span class="line-height-0 material-icons lgFont line-h mr-1">
                assignment_ind
              </span>
              <span class="label">Joining Date</span>
            </div>
            <div>{{ profileInfo.joiningDateFormatted }}</div>
          </div>
        </v-col>
        <v-col  cols="12" md="3" class="pt-0 pt-md-3">
          <div class="border-secondary rounded border-1 profileinfo">
            <div class="secondaryLight--text">
              <span class="line-height-0 material-icons lgFont line-h mr-1">
                mail
              </span>
              <span class="label">Email</span>
            </div>
            <div>{{ profileInfo.email }}</div>
          </div>
        </v-col>
        <v-col  cols="6" md="3" class="pr-1 pr-md-3 pt-0 pt-md-3">
          <div class="border-secondary rounded border-1 profileinfo">
            <div class="secondaryLight--text">
              <span class="line-height-0 material-icons lgFont line-h mr-1">
                phone_iphone
              </span>
              <span class="label">Phone</span>
            </div>
            <div>{{ profileInfo.phone }}</div>
          </div>
        </v-col>
          <v-col cols="6" md="3" class="d-md-none pl-1 pl-md-3 pt-0 pt-md-3">
          <div class="border-secondary rounded border-1 profileinfo">
            <div class="secondaryLight--text">
              <span class="line-height-0 material-icons lgFont line-h mr-1">
                location_on
              </span>
              <span class="label">City</span>
            </div>
            <div>{{ profileInfo.city }}</div>
          </div>
        </v-col> -->
      </v-row>
      <v-row class="mx-n6 mb-md-3 mt-0 mt-md-3">
        <!-- <v-col cols="6" md="3" class="d-none d-md-block pr-0 pr-md-3">
          <div class="border-secondary rounded border-1 profileinfo">
            <div class="secondaryLight--text">
              <span class="line-height-0 material-icons lgFont line-h mr-1">
                location_on
              </span>
              <span class="label">City</span>
            </div>
            <div>{{ profileInfo.city }}</div>
          </div>
        </v-col>
        <v-col  cols="12" md="9" class="">
          <div class="border-secondary rounded border-1 profileinfo">
            <div class="secondaryLight--text">
              <span class="line-height-0 material-icons lgFont line-h mr-1">
                home
              </span>
              <span class="label">Address</span>
            </div>
            <div>{{ profileInfo.address }}</div>
          </div>
        </v-col> -->
      </v-row>
      <div class="footerclear"></div>
    </v-container>

    <Footer v-if="$route.path != '/' && !$route.path.includes('admin')" />
  </v-row>
</template>

<script>
import apimethods from "../mixins/apimethods";
import Footer from "../components/Footer.vue";
export default {
  name: "ProfilePage",
  created() {
    this.GetProfileInfo();
  },
  components: { Footer },
  mixins: [apimethods],
  data() {
    return {
      profileInfo: {},
    };
  },
  methods: {
    GetProfileInfo() {
      try {
        var self = this;
        this.$store.commit("showLoadingModal");
        var request = this.GetRequestWithAuthorization(
          "user/GetProfileInfo",
          undefined
        );
        if (request != undefined) {
          request.then((res) => {
            this.$store.commit("hideLoadingModal");

            if (
              res != undefined &&
              res.data != undefined &&
              res.data.data != undefined
            ) {
              self.profileInfo = res.data.data;
            }
          });
        }

        request.catch((ex) => {});
      } catch (exa) {
        this.$store.commit("hideLoadingModal");
        console.log(exa);
      }
    },
  },
};
</script>