<template>
  <v-row class="mt-0">
    <v-col cols="12" lg="6" class="pr-lg-0" v-if="!HideNewspaper">
      <!-- <v-overlay

        :value="ShowNewspaperLoading"
        style="background-color: Red !important; width: 100%; w"
      > -->

      <!-- </v-overlay> -->
      <v-card class="mb-0">
        <v-card-title class="borderBottom-1 border-accentxlight"
          >Newspapers</v-card-title
        >
        <v-card-text>
          <v-row
            :class="{ 'justify-center': listofActiveNewspaper.length == 1 }"
          >
            <v-progress-circular
              v-if="ShowNewspaperLoading"
              value="true"
              indeterminate
              size="24"
              style="width: 100%"
            ></v-progress-circular>
            <v-slide-group
              v-model="model"
              class="px-0 py-1 newsslider"
              show-arrows
              v-if="!ShowNewspaperLoading"
            >
              <v-slide-item
                v-for="item in listofActiveNewspaper"
                :key="item.id"
                class="newscard"
              >
                <v-col class="d-flex flex-column" @click="GoToUrl(item.link)">
                  <v-row no-gutters class="align-center">
                    <v-col cols="12" class="text-right"
                      ><span class="material-icons-outlined accentlight--text"
                        >insert_link</span
                      ></v-col
                    >
                  </v-row>
                  <v-row no-gutters class="fill-height align-center">
                    <v-col>
                      <img :src="item.thumbnailURL" />
                    </v-col>
                  </v-row>
                </v-col>
              </v-slide-item>
            </v-slide-group>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>

    <v-col cols="12" lg="6" class="pt-0 pt-lg-3" v-if="!HideMagazine">
      <v-card class="mb-0">
        <v-card-title class="borderBottom-1 border-accentxlight"
          >Magazines</v-card-title
        >
        <v-card-text class="pt-2 pb-1 px-0">
          <v-row
            class="ma-0"
            :class="{ 'justify-center': listofActiveMagazine.length == 1 }"
          >
            <v-progress-circular
              v-if="ShowMagazineLoading"
              value="true"
              indeterminate
              size="24"
              style="width: 100%"
            ></v-progress-circular>
            <v-slide-group
              v-if="!ShowMagazineLoading"
              v-model="model"
              class="px-2 py-1 newsslider"
              show-arrows
            >
              <v-slide-item
                v-for="item in listofActiveMagazine"
                :key="item.id"
                class="newscard"
              >
                <v-col class="d-flex flex-column" @click="GoToUrl(item.link)">
                  <v-row no-gutters class="align-center">
                    <v-col cols="12" class="text-right"
                      ><span class="material-icons-outlined accentlight--text"
                        >insert_link</span
                      ></v-col
                    >
                  </v-row>
                  <v-row no-gutters class="fill-height align-center">
                    <v-col>
                      <img :src="item.thumbnailURL" />
                    </v-col>
                  </v-row>
                </v-col>
              </v-slide-item>
            </v-slide-group>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import apimethods from "../../mixins/apimethods";
export default {
  name: "NewspapersAndMagazines",
  mixins: [apimethods],
  data() {
    return {
      listofActiveNewspaper: [],
      listofActiveMagazine: [],
      HideNewspaper: false,
      ShowNewspaperLoading: false,
      HideMagazine: false,
      ShowMagazineLoading: false,
    };
  },
  created() {
    this.GetActiveNewspaper();
    this.GetActiveMagazine();
  },
  methods: {
    GetActiveNewspaper() {
      try {
        this.ShowNewspaperLoading = true;
        var self = this;
        var request = this.GetRequestWithAuthorization(
          "api/Newspaper/GetActive",
          undefined
        );
        if (request != undefined) {
          request.then((res) => {
            self.ShowNewspaperLoading = false;
            if (
              res != undefined &&
              res.data != undefined &&
              res.data.data != undefined
            ) {
              self.listofActiveNewspaper = res.data.data;

              if (
                self.listofActiveNewspaper == undefined ||
                self.listofActiveNewspaper.length == 0
              ) {
                self.HideNewspaper = true;
              }
            } else {
              self.HideNewspaper = true;
              self.ShowNewspaperLoading = false;
            }
          });
        }

        request.catch((ex) => {
          this.ShowNewspaperLoading = false;
        });
      } catch (exa) {
        this.ShowNewspaperLoading = false;
        console.log(exa);
      }
    },
    GetActiveMagazine() {
      try {
        var self = this;
        self.ShowMagazineLoading = true;
        var request = this.GetRequestWithAuthorization(
          "api/Magazine/GetActive",
          undefined
        );
        if (request != undefined) {
          request.then((res) => {
            self.ShowMagazineLoading = false;
            if (
              res != undefined &&
              res.data != undefined &&
              res.data.data != undefined
            ) {
              self.listofActiveMagazine = res.data.data;
            }

            if (
              self.listofActiveMagazine == undefined ||
              self.listofActiveMagazine.length == 0
            ) {
              self.HideMagazine = true;
            }
          });
        }

        request.catch((ex) => {
          self.ShowMagazineLoading = false;
        });
      } catch (exa) {
        self.ShowMagazineLoading = false;
        self.HideMagazine = true;
        console.log(exa);
      }
    },
    GoToUrl(link) {
      window.open(link, "_blank");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
