<template>
  <v-col cols="12" sm="12" lg="12" class="pr-lg-1">
    <v-card class="fill-height wd-100">
      <v-card-title class="borderBottom-1 border-accentxlight"
        >News & Updates</v-card-title
      >
      <v-card-text class="py-2 pr-1 newupdatediv">
        <vue-scroll :ops="ops">
          <div class="scrolllimit">
            <v-row
              no-gutters
              class="py-1 pr-4 mb-2 newsupdate borderBottom-1 border-secondary"
              v-for="(item, index) in listofFilteredNews"
              :key="index"
            >
              <v-col
                cols="12"
                sm="auto"
                class="cursor-pointer"
                @click="previewNews(JSON.parse(JSON.stringify(item)))"
              >
                <img v-if="item.images.length > 0" :src="item.images[0].url" />
              </v-col>
              <v-col
                cols="12"
                class="pl-sm-3 min-wd-0 cursor-pointer col-sm"
                @click="previewNews(JSON.parse(JSON.stringify(item)))"
              >
                <p class="fw-500 xsFont mb-0 text-truncate">
                  {{ item.title }}
                </p>
                <p class="xxxsFont accentlight--text mb-0 py-1">
                  {{ item.category.title }} | Dated: {{ item.publishedOn }}
                </p>
                <v-col cols="12" class="fw-400 xsFont p-relative pa-0">
                  <div class="newsupdatecontent ceomessage seemorediv">
                    <div v-html="item.description"></div>
                  </div>
                </v-col>
              </v-col>
              <v-col cols="12" class="pa-0 pt-2">
                <reaction-component
                  :postId="item.id"
                  :selectedReaction="item.selectedReaction"
                  :listofLikedReactions="item.listofLikedReactions"
                  :listofLovedReactions="item.listofLovedReactions"
                  :listofApplauseReactions="item.listofApplauseReactions"
                  :listofAllReactions="item.listofAllReactions"
                  @reaction-selected="handleReaction"
                />
              </v-col>
            </v-row></div
        ></vue-scroll>
        <v-row
          no-gutters
          class="readmorebtndiv pr-4"
          v-if="listofNews.length > 4"
        >
          <v-col>
            <v-btn
              @click.stop="allNewsDialog = true"
              block
              class="white--text"
              color="accentlight"
              >More News & Updates</v-btn
            >
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-dialog v-model="allNewsDialog" max-width="750" scrollable>
      <v-card class="mb-0">
        <v-card-title class="pa-3">News & Updates</v-card-title>
        <vue-scroll>
          <v-card-text class="pa-0 accentdark--text">
            <v-row class="ma-0 pa-3">
              <v-col cols="12" class="pt-0">
                <v-row
                  no-gutters
                  class="py-1 pr-4 mb-2 newsupdate borderBottom-1 border-base cursor-pointer"
                  v-for="(item, index) in listofNews"
                  :key="index"
                  @click="previewNews(JSON.parse(JSON.stringify(item)))"
                >
                  <v-col cols="12" sm="auto">
                    <img
                      v-if="item.images.length > 0"
                      :src="item.images[0].url"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    class="pl-sm-3 min-wd-0 cursor-pointer col-sm"
                  >
                    <p class="fw-500 xsFont mb-0 text-truncate">
                      {{ item.title }}
                    </p>
                    <p class="xxxsFont accentlight--text mb-0 py-2">
                      {{ item.category.title }} | Dated: {{ item.publishedOn }}
                    </p>
                    <v-col cols="12" class="fw-400 xsFont p-relative pa-0">
                      <div class="newsupdatecontent ceomessage seemorediv">
                        <div v-html="item.description"></div>
                      </div>
                    </v-col>
                  </v-col>
                  <v-col cols="12">
                    <reaction-component
                      :postId="item.id"
                      :selectedReaction="item.selectedReaction"
                      :listofLikedReactions="item.listofLikedReactions"
                      :listofLovedReactions="item.listofLovedReactions"
                      :listofApplauseReactions="item.listofApplauseReactions"
                      :listofAllReactions="item.listofAllReactions"
                      @reaction-selected="handleReaction"
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </vue-scroll>
      </v-card>
    </v-dialog>
    <v-dialog v-model="previewNewsDialog" max-width="750" scrollable>
      <v-card class="mb-0" v-if="previewNewsDialog">
        <vue-scroll>
          <v-card-text class="pa-0 accentdark--text">
            <v-row class="mx-0">
              <v-col class="px-0">
                <img
                  v-if="selectedImageUrl != ''"
                  :src="selectedImageUrl"
                  class="newspreviewheader"
                />
              </v-col>
            </v-row>

            <v-row class="ma-0 pa-3">
              <v-col cols="12" class="pt-0">
                <v-row no-gutters class="mt-3">
                  <v-col cols="12">
                    <h2>{{ newsupdateModel.title }}</h2>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="12" class="mt-3">
                    <p>
                      {{ newsupdateModel.category.title }} | Dated:
                      {{ newsupdateModel.publishedOn }}
                    </p>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col
                    cols="12"
                    class="xsFont"
                    v-html="newsupdateModel.description"
                  >
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="12" md="10" class="mx-auto">
                    <v-slide-group
                      v-model="model"
                      class="px-0 py-1 newsslider newsmodalitems"
                      show-arrows
                    >
                      <v-slide-item
                        class="px-2"
                        v-for="(item, i) in newsupdateModel.images"
                        :key="i"
                      >
                        <div class="ceomodalitems">
                          <iframe
                            v-if="item.assetType == 'drive'"
                            :src="item.url"
                            width="200px"
                            height="180px"
                          ></iframe>
                          <iframe
                            v-if="item.assetType == 'youtube'"
                            width="200px"
                            height="180px"
                            :src="item.url"
                            title="YouTube video player"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen
                          ></iframe>

                          <video-player
                            v-if="item.assetType == 'video'"
                            class="video-player-box"
                            ref="videoPlayerPopup"
                            :options="item.playerPopupOptions"
                          />
                          <img
                            v-if="item.assetType == 'Image'"
                            :src="item.url"
                            @click="updateSelectedImage(item.url)"
                            class="cursor-pointer"
                          />
                        </div>
                      </v-slide-item>
                    </v-slide-group>
                  </v-col>
                </v-row>
                <reaction-component
                  :postId="newsupdateModel.id"
                  :selectedReaction="newsupdateModel.selectedReaction"
                  :listofLikedReactions="newsupdateModel.listofLikedReactions"
                  :listofLovedReactions="newsupdateModel.listofLovedReactions"
                  :listofApplauseReactions="
                    newsupdateModel.listofApplauseReactions
                  "
                  :listofAllReactions="newsupdateModel.listofAllReactions"
                  @reaction-selected="handleReaction"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </vue-scroll>
      </v-card>
    </v-dialog>
  </v-col>
</template>

<script>
import apimethods from "../../mixins/apimethods";
import helpermethods from "../../mixins/helpermethods";
import { videoPlayer } from "vue-video-player";
import ReactionComponent from "./Reaction.vue";

export default {
  mixins: [apimethods, helpermethods],
  components: { videoPlayer, ReactionComponent },

  data() {
    return {
      ops: {
        scrollPanel: {
          scrollingX: false,
        },
      },
      allNewsDialog: false,
      model: null,
      previewNewsDialog: false,
      listofNews: [],
      newsupdateModel: {},
      listofFilteredNews: [],
      selectedImageUrl: "",
      listofAllReactions: [],
      listofLikedReactions: [],
      listofLovedReactions: [],
      listofApplauseReactions: [],
    };
  },
  name: "NewsAndUpdates",
  mounted() {
    this.GetActiveNews();
  },
  methods: {
    previewNews(news) {
      this.newsupdateModel = news;
      this.previewNewsDialog = true;
      if (this.newsupdateModel.images.length > 0) {
        this.selectedImageUrl = this.newsupdateModel.images[0].url;
      }
    },
    GetActiveNews() {
      this.$store.commit("showLoadingModal");
      var self = this;
      self.listofNews = [];

      this.GetRequestWithAuthorization("api/News/Get", [
        {
          Key: "activeOnly",
          Value: true,
        },
      ])
        .then((res) => {
          this.$store.commit("hideLoadingModal");

          if (
            res != undefined &&
            res.data != undefined &&
            res.data.data != undefined
          ) {
            self.listofNews = JSON.parse(JSON.stringify(res.data.data));
            self.listofNews.forEach((news) => {
              news.listofAllReactions = news.reactions;
              if (news.listofAllReactions.length > 0) {
                news.listofLikedReactions = news.listofAllReactions.filter(
                  (x) => x.reactionId == 1
                );
                news.listofLovedReactions = news.listofAllReactions.filter(
                  (x) => x.reactionId == 2
                );
                news.listofApplauseReactions = news.listofAllReactions.filter(
                  (x) => x.reactionId == 3
                );
                news.listofAllReactions.forEach((reaction) => {
                  if (reaction.isReactionSelected) {
                    news.isReactionSelected = true;
                    news.selectedReaction = reaction.selectedReaction;
                  }
                });
              }
            });
            self.listofFilteredNews = self.listofNews.slice(0, 4);
            if (this.previewNewsDialog) {
              this.newsupdateModel = self.listofNews.find(
                (x) => x.id == this.newsupdateModel.id
              );
            }

            if (self.listofNews) {
              self.listofNews.forEach((el) => {
                el.images.forEach((element) => {
                  if (element.assetType == "video") {
                    element.playerOptions = {
                      // videojs options
                      muted: false,
                      language: "en",
                      width: "335px",
                      height: "200px",
                      sources: [
                        {
                          type: "video/mp4",
                          src: element.url,
                        },
                      ],
                    };
                    element.playerPopupOptions = {
                      // videojs options
                      muted: false,
                      language: "en",
                      width: "250px",
                      height: "180px",
                      sources: [
                        {
                          type: "video/mp4",
                          src: element.url,
                        },
                      ],
                    };
                  }
                });
              });
            }
            setTimeout(() => {
              self.allocateseemorediv(null);
            }, 10);
          }
        })
        .catch((ex) => {
          this.$store.commit("hideLoadingModal");
        });
    },
    updateSelectedImage(url) {
      this.selectedImageUrl = url;
    },
    handleReaction(reactionData) {
      const { postId, selectedReaction } = reactionData;
      let news = this.listofNews.find((x) => x.id == postId);
      if (
        news.isReactionSelected &&
        selectedReaction == news.selectedReaction
      ) {
        this.removeReaction(postId);
      } else if (news.isReactionSelected) {
        this.editReaction(postId, selectedReaction);
      } else {
        this.addReaction(postId, selectedReaction);
      }
    },
    addReaction(postId, reactionId) {
      this.$store.commit("showLoadingModal");
      this.isSavingReaction = true;

      var bodyFormData = new FormData();
      bodyFormData.append("postId", postId);
      bodyFormData.append("reactionId", reactionId);
      var url = "api/Reaction/AddNewsAndUpdatePostReaction";
      this.PostFormRequestWithAuthorization(url, undefined, bodyFormData)
        .then((res) => {
          this.$store.commit("hideLoadingModal");
          this.isSavingReaction = false;
          if (
            res != undefined &&
            res.data != undefined &&
            res.data.status == true
          ) {
            this.$notify({
              group: "notification",
              title: "Message",
              text: "Reaction Added Successfully",
              type: "Success",
            });
          } else {
            this.$notify({
              group: "notification",
              title: "Error",
              text: "Error Adding Reaction",
              type: "error",
            });
          }
          this.GetActiveNews();
        })
        .catch((ex) => {
          this.$store.commit("hideLoadingModal");
          this.isSavingReaction = false;
          console.log(ex);
        });
    },
    editReaction(postId, reactionId) {
      this.$store.commit("showLoadingModal");
      this.isSavingReaction = true;

      var bodyFormData = new FormData();
      bodyFormData.append("postId", postId);
      bodyFormData.append("reactionId", reactionId);
      var url = "api/Reaction/EditNewsAndUpdatePostReaction";
      this.PostFormRequestWithAuthorization(url, undefined, bodyFormData)
        .then((res) => {
          this.$store.commit("hideLoadingModal");
          this.isSavingReaction = false;
          if (
            res != undefined &&
            res.data != undefined &&
            res.data.status == true
          ) {
            this.$notify({
              group: "notification",
              title: "Message",
              text: "Reaction Update Successfully",
              type: "Success",
            });
          } else {
            this.$notify({
              group: "notification",
              title: "Error",
              text: "Error Updating Reaction",
              type: "error",
            });
          }
          this.GetActiveNews();
        })
        .catch((ex) => {
          this.$store.commit("hideLoadingModal");
          this.isSavingReaction = false;
          console.log(ex);
        });
    },
    removeReaction(postId) {
      this.$store.commit("showLoadingModal");
      this.isSavingReaction = true;

      var url = "api/Reaction/RemoveNewsAndUpdatePostReaction";
      this.GetRequestWithAuthorization(url, [
        {
          Key: "postId",
          Value: postId,
        },
      ])
        .then((res) => {
          this.$store.commit("hideLoadingModal");
          this.isSavingReaction = false;
          if (
            res != undefined &&
            res.data != undefined &&
            res.data.status == true
          ) {
            this.$notify({
              group: "notification",
              title: "Message",
              text: "Reaction Deleted Successfully",
              type: "Success",
            });
          } else {
            this.$notify({
              group: "notification",
              title: "Error",
              text: "Error Removing Reaction",
              type: "error",
            });
          }
          this.GetActiveNews();
        })
        .catch((ex) => {
          this.$store.commit("hideLoadingModal");
          this.isSavingReaction = false;
          console.log(ex);
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
