<template>
  <v-row no-gutters>
    <v-col cols="12" class="mainslider">
      <v-carousel hide-delimiter-background show-arrows="false">
        <v-carousel-item v-for="item in listofActiveNoticeboard" :key="item.id">
          <v-img
            :src="item.imageUrl"
            class="white--text justify-center align-center text-center"
            height="350px"
          >
            <h1 class="white--text xxxlgFont fw-700 px-4 mb-2">
              {{ item.title }}
            </h1>
            <h3 class="white--text fw-400 xlgFont px-4">
              {{ item.subtitle }}
            </h3>
            <v-btn
              outlined
              class="accent--text py-4 mt-4"
              large
              height="44px"
              v-if="item.buttonStyle == 1"
              @click="OpenInNewTab(item.url)"
              >{{ item.buttonText }}</v-btn
            >
            <v-btn
              filled
              color="accent"
              class="white--text py-3 mt-4"
              large
              v-if="item.buttonStyle == 2"
              @click="OpenInNewTab(item.url)"
              >{{ item.buttonText }}</v-btn
            >
            <v-btn
              filled
              color="accentdark"
              class="white--text py-3 mt-4"
              large
              v-if="item.buttonStyle == 3"
              @click="OpenInNewTab(item.url)"
              >{{ item.buttonText }}</v-btn
            >
            <!-- <v-btn
              class="white accentdark--text mt-4"
              
              
              ></v-btn
            > -->
          </v-img>
        </v-carousel-item>
      </v-carousel>
    </v-col>
  </v-row>
</template>
      <script>
import apimethods from "../../mixins/apimethods";
export default {
  mixins: [apimethods],
  name: "Slider",
  data() {
    return {
      listofActiveNoticeboard: [],
    };
  },
  created() {
    this.GetBanners();
  },
  methods: {
    OpenInNewTab(link) {
      window.open(link, "_blank");
    },
    GetBanners() {
      try {
        var self = this;
        this.$store.commit("showLoadingModal");

        var request = this.GetRequestWithAuthorization(
          "api/Noticeboard/GetActive",
          undefined
        );
        if (request != undefined) {
          request.then((res) => {
            this.$store.commit("hideLoadingModal");

            if (
              res != undefined &&
              res.data != undefined &&
              res.data.data != undefined
            ) {
              self.listofActiveNoticeboard = res.data.data;
            }
          });
        }

        request.catch((ex) => {});
      } catch (exa) {
        this.$store.commit("hideLoadingModal");

        console.log(exa);
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>