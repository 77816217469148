<template>
  <div class="loginpage fill-height d-flex flex-column">
    <v-row no-gutters class="fill-height align-center pa-3">
      <v-col class="mx-auto logindiv white pa-6 rounded">
        <v-row
          class="mx-0 mt-0 mb-1 align-center justify-center justify-sm-left"
        >
          <v-col cols="auto">
            <img
              src="../assets/login-logo.svg"
              class="loginlogo cursor-pointer"
              @click="redirecttostile()"
            />
          </v-col>
          <v-col
            cols="auto"
            class="accentxdark--text text-center fw-700 xxlg1Font"
          >
            Connect
          </v-col>
        </v-row>
        <v-form ref="modalForm" v-model="formValid">
          <v-row class="ma-0">
            <v-col cols="12" class="pb-5">
              <v-text-field
                ref="emailField"
                placeholder="Email"
                dense
                outlined
                hide-details
                autocomplete="false"
                aria-autocomplete="off"
                v-model="email"
                :class="{ 'error--text': !emailValid }"
                requried
                :rules="[(v) => !!v || 'Email is required']"
                @input="emailValid = true"
                @blur="validateEmail()"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="ma-0">
            <v-col cols="12" class="pb-5">
              <v-text-field
                placeholder="Password"
                dense
                class="pwdfield"
                autocomplete="false"
                hide-details
                aria-autocomplete="off"
                outlined
                v-model="password"
                :rules="[(v) => !!v || 'Password is required']"
                @keyup.enter="Login"
                :append-icon="
                  password ? (value ? 'visibility_off' : 'visibility') : ''
                "
                @click:append="() => (value = !value)"
                :type="value ? 'password' : 'text'"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
        <v-row class="ma-0 align-center">
          <v-col cols="12">
            <v-btn
              v-on:click="Login"
              block
              class="accent white--text elevation-0"
              :loading="onLoading"
              :disabled="!formValid"
              ><span class="material-icons-outlined lgFont mr-1">login</span
              >Login</v-btn
            >
          </v-col>
        </v-row>
        <v-row class="ma-0 align-center">
          <v-col cols="12" class="text-center xsFont fw-500">
            This Portal is for STILE Employees Only
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col class="text-center white--text pb-3"
        >COPYRIGHT © {{ year }} SHABBIR TILES & CERAMICS LIMITED | ALL RIGHTS
        RESERVED.</v-col
      >
    </v-row>
  </div>
</template>

<script>
import apimethods from "../mixins/apimethods";

export default {
  mixins: [apimethods],
  name: "LoginPage",
  props: {
    msg: String,
  },
  data() {
    return {
      email: "",
      password: "",
      onLoading: false,
      emailValid: true,
      formValid: false,
      value: "password",
      emailRules: [(v) => !!v || "E-mail is required"],
    };
  },
  computed: {
    year() {
      return new Date().getFullYear();
    },
  },
  methods: {
    redirecttostile() {
      window.open("https://www.stile.com.pk/", "_blank").focus();
    },
    validEmail: function (email) {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    validateEmail() {
      if (this.validEmail(this.email)) {
        this.emailValid = true;
      } else {
        this.emailValid = false;
      }
    },
    ShowDashboard() {
      this.$router.push("/home");
    },

    Login() {
      this.onLoading = true;
      var self = this;
      var api_base_url = process.env.VUE_APP_API_LINK;
      const axios = require("axios");
      var bodyFormData = new FormData();
      bodyFormData.append("Username", this.email);
      bodyFormData.append("Password", this.password);
      bodyFormData.append("GrantType", "password");
      bodyFormData.append("ticks", 123123);
      var api_url = api_base_url + "oauth/Authorize";
      axios({
        method: "post",
        url: api_url,
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          if (response.data.status == true) {
            localStorage.UserData = JSON.stringify(response.data);
            self.FetchViewRoles();
          } else {
            self.$notify({
              group: "notification",
              title: "Error",
              text: response.data.message,
              type: "error",
            });
            self.onLoading = false;
          }
        })
        .catch(function (response) {
          console.log(response);
          self.onLoading = false;
        });
    },

    FetchViewRoles() {
      try {
        this.GetRequestWithAuthorization(
          "user/GetLoggedInUserRoleAccess",
          undefined
        )
          .then((res) => {
            if (res != undefined) {
              this.onLoading = false;
              localStorage.ViewRoles = JSON.stringify(res.data.data);
              this.GetAppInitialData();
              this.FetchUserInfo();
            }
          })
          .catch((ex) => {
            this.onLoading = false;
            console.log("Error fetching view roles: " + ex);
          });
      } catch (exa) {
        console.log(exa);
      }
    },
    GetAppInitialData() {
      var request = this.GetRequestWithAuthorization(
        "api/MasterData/GetAppInitialData",
        undefined
      )
        .then((res) => {
          if (
            res != undefined &&
            res.data != undefined &&
            res.data.data != undefined
          ) {
            var responseData = res.data.data;
            this.saveIndexedData("Cities", responseData.listOfCities);
            this.saveIndexedData("Genders", responseData.listOfGenders);
            this.saveIndexedData("UserRoles", responseData.listOfUserRoles);
            this.saveIndexedData("EventTypes", responseData.listOfEventTypes);
            this.saveIndexedData("Colors", responseData.listOfColor);
          }
        })
        .catch((ex) => {});
    },
    FetchUserInfo() {
      try {
        this.GetRequestWithAuthorization("user/GetCurrentUserInfo", undefined)
          .then((res) => {
            if (res != undefined) {
              this.onLoading = false;
              localStorage.UserDetail = JSON.stringify(res.data.data);
              this.ShowDashboard();
            }
          })
          .catch((ex) => {
            this.$notify({
              group: "notification",
              title: "Error",
              text: "Unable to fetch user information",
              type: "error",
            });
            this.onLoading = false;
            console.log("Error fetching view roles: " + ex);
          });
      } catch (exa) {
        console.log(exa);
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
