<template>
  <v-row class="dashboardview ma-0 base p-relative homeview min-height-100">
    <v-col>
      <v-row class="dblue white--text mt-md-15 pt-8 pb-5">
        <v-container>
          <v-row class="align-center">
            <v-col cols="12" sm="6" class="order-1 order-sm-0">
              <p class="fw-500 xxlgFont mb-1">{{ starclubmaindesc.title }}</p>
              <v-col v-html="starclubmaindesc.description" class="pl-0">
              </v-col>
            </v-col>
            <v-col cols="12" sm="6" class="text-center order-0 order-sm-1">
              <img :src="starclubmaindesc.imageURL" class="starclubheader"
            /></v-col>
          </v-row>
        </v-container>
      </v-row>

      <v-row class="base pt-5">
        <v-container>
          <v-row
            no-gutters
            v-for="item in starclubQuarters.slice(0, 1)"
            :key="item.id"
          >
            <v-col cols="12">
              <v-row>
                <v-col class="text-center fw-500 xlgFont accentdark--text">{{
                  item.quarterTypeLong
                }}</v-col>
              </v-row>
              <v-row no-gutters class="mb-6 mt-6 justify-center">
                <v-slide-group
                  v-model="model"
                  class="pa-md-4 d-md-none starcarasouel"
                  show-arrows
                >
                  <v-slide-item
                    v-for="acheiver in item.acheivers"
                    :key="acheiver.id"
                  >
                    <div class="text-center staruserdiv">
                      <div class="starimg line-height-0">
                        <img :src="acheiver.acheiver.profileImageURL" />
                      </div>
                      <div class="stardetail accent mt-n4 white--text pa-3">
                        <p class="lgFont mb-0 pt-4">
                          {{ acheiver.acheiver.firstName }}
                          {{ acheiver.acheiver.lastName }}
                        </p>
                        <p class="mb-0 smFont text-truncate">
                          {{ acheiver.acheiver.department }}
                        </p>
                      </div>
                    </div>
                  </v-slide-item>
                </v-slide-group>

                <div
                  class="text-center staruserdiv d-none d-md-block"
                  v-for="acheiver in item.acheivers"
                  :key="acheiver.id"
                >
                  <div class="starimg line-height-0">
                    <img :src="acheiver.acheiver.profileImageURL" />
                  </div>
                  <div class="stardetail accent mt-n4 white--text pa-3">
                    <p class="lgFont mb-0 pt-4">
                      {{ acheiver.acheiver.firstName }}
                      {{ acheiver.acheiver.lastName }}
                    </p>
                    <p class="mb-0 smFont">
                      {{ acheiver.acheiver.department }}
                    </p>
                  </div>
                </div>
              </v-row>
            </v-col>
          </v-row>
          <v-row
            no-gutters
            class="oldstars"
            v-for="item in starclubQuarters.slice(1, starclubQuarters.length)"
            :key="item.id"
          >
            <v-col cols="12">
              <v-row>
                <v-col class="text-center fw-500 xlgFont accentdark--text">{{
                  item.quarterTypeLong
                }}</v-col>
              </v-row>
              <v-row no-gutters class="mb-6 mt-6 justify-center">
                <v-slide-group
                  v-model="model"
                  class="pa-md-4 d-md-none starcarasouel"
                  show-arrows
                >
                  <v-slide-item
                    v-for="acheiver in item.acheivers"
                    :key="acheiver.id"
                  >
                    <div class="text-center staruserdiv">
                      <div class="starimg line-height-0">
                        <img :src="acheiver.acheiver.profileImageURL" />
                      </div>
                      <div class="stardetail accent mt-n4 white--text pa-3">
                        <p class="lgFont mb-0 pt-4">
                          {{ acheiver.acheiver.firstName }}
                          {{ acheiver.acheiver.lastName }}
                        </p>
                        <p class="mb-0 smFont">
                          {{ acheiver.acheiver.department }}
                        </p>
                      </div>
                    </div>
                  </v-slide-item>
                </v-slide-group>

                <div
                  class="text-center staruserdiv d-none d-md-block"
                  v-for="acheiver in item.acheivers"
                  :key="acheiver.id"
                >
                  <div class="starimg line-height-0">
                    <img :src="acheiver.acheiver.profileImageURL" />
                  </div>
                  <div class="stardetail accent mt-n4 white--text pa-3">
                    <p class="lgFont mb-0 pt-4">
                      {{ acheiver.acheiver.firstName }}
                      {{ acheiver.acheiver.lastName }}
                    </p>
                    <p class="mb-0 smFont">
                      {{ acheiver.acheiver.department }}
                    </p>
                  </div>
                </div>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-row>

      <div class="footerclear"></div>
    </v-col>

    <Footer v-if="$route.path != '/' && !$route.path.includes('admin')" />
  </v-row>
</template>

<script>
import Footer from "../components/Footer.vue";
import apimethods from "../mixins/apimethods";

export default {
  mixins: [apimethods],

  name: "StarClubAcheiverPage",
  data() {
    return {
      starclubmaindesc: {},
      starclubQuarters: [],
    };
  },

  components: { Footer },
  mounted() {
    this.GetStarClubData();
  },
  methods: {
    GetStarClubData() {
      var self = this;
      this.$store.commit("showLoadingModal");
      this.GetRequestWithAuthorization("api/StarClub/Get", undefined)
        .then((res) => {
          this.$store.commit("hideLoadingModal");

          if (
            res != undefined &&
            res.data != undefined &&
            res.data.data != undefined
          ) {
            self.starclubmaindesc = {
              id: res.data.data.id,
              title: res.data.data.title,
              description: res.data.data.description,
              imageURL: res.data.data.imageURL + "?" + performance.now(),
            };
            self.starclubQuarters = res.data.data.starClubAcheivers;
          }
        })
        .catch((ex) => {
          this.$store.commit("hideLoadingModal");
        });
    },
  },
};
</script>
