<template>
  <v-row class="d-flex fill-height ma-0">
    <v-col class="px-0 pl-md-0 pr-lg-3 pt-0 pt-lg-3">
      <v-row class="ma-0 fill-height p-relative box-shadow-1">
        <v-col class="white rounded d-flex fill-height flex-column p-absolute">
          <v-row class="align-center">
            <v-col class="lgFont accentdark--text fw-700"> Our Company </v-col>
            <v-col cols="auto"> </v-col>
          </v-row>
          <v-row class="fill-height p-relative mt-0 mb-1">
            <v-col class="">
              <v-row class="fill-height p-absolute wd-100">
                <vue-scroll :ops="ops">
                  <v-row
                    class="borderBottom-1 borderTop-1 border-secondary my-2 mx-3 xsFont"
                    v-for="item in DescriptionList"
                    :key="item.id"
                  >
                    <v-col cols="auto" v-if="item.photoUrl">
                      <img :src="item.photoUrl" class="starclubmini rounded companylist" />
                    </v-col>
                    <v-col class="pl-0">
                      <v-row class="align-center">
                        <v-col
                          class="accentdark--text lgFont fw-500 min-wd-0 text-truncate"
                          >{{ item.title }}</v-col
                        >
                        <v-col cols="auto" class="text-right pr-0">
                          <v-btn
                            text
                            color="accentlight"
                            small
                            @click.stop="OpenPopup(item)"
                            class="editbtn mr-1"
                          >
                            <span class="material-icons-outlined lgFont mr-1"
                              >edit</span
                            >Edit
                          </v-btn>
                        </v-col>
                      </v-row>
                      <v-row class="mt-2 align-center">
                        <v-col
                          cols="12"
                          class="accentdark--text pt-0 xxsFont pr-lg-12 fw-400 p-relative"
                        >
                          <div class="ceomessage ourcompanyabt seemorediv">
                            <div v-html="item.description"></div>
                            <a
                              @click="
                                ShowDiscriptionDialog(
                                  item.title,
                                  item.description
                                )
                              "
                              class="readmoredot d-none"
                              >... See More</a
                            >
                          </div>
                          <!-- <div
                            v-html="item.description"
                            class="ceomessage"
                            :class="{'ourcompanyabt': item.ShowMoreButton}"
                          ></div>
                          <a
                            v-if="item.ShowMoreButton"
                            @click="
                              ShowDiscriptionDialog(
                                item.title,
                                item.description
                              )
                            "
                            class="readmoredot"
                            >... See More</a
                          > -->
                        </v-col>
                        <!-- <v-col
                          class=""
                          v-html="item.description"
                        >
                           
                        </v-col>-->
                      </v-row>
                    </v-col>
                  </v-row>

                  <company-policy-component :isEditable="true" />
                </vue-scroll>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
    <v-dialog v-model="editpost">
      <v-card class="mb-0">
        <v-card-title
          class="accentdark--text borderBottom-1 border-accentxlight"
        >
          <v-row class="align-center">
            <v-col class="accentdark--text">Our Company</v-col>
            <v-col cols="auto" class="d-none d-sm-block">
              <v-btn
                outlined
                class="accentlight--text mr-2 px-4"
                small
                @click="ClosePopup()"
                >Cancel</v-btn
              >
              <v-btn
                outlined
                class="accent--text px-5"
                small
                :loading="PopupData.SavingData"
                :disabled="PopupData.isValid || PopupData.Description == ''"
                @click="SaveDescription"
                >Save</v-btn
              >
            </v-col>
          </v-row>
        </v-card-title>
        <vue-scroll>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Heading"
                  outlined
                  dense
                  maxlength="100"
                  hide-details
                  v-model="PopupData.Heading"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <div class="document-editor__toolbar"></div>
                <div class="document-editor__editable-container">
                  <ckeditor
                    style="height: 180px !important; background-color: white"
                    :editor="editor"
                    :config="editorConfig"
                    :rules="[(v) => !!v.trim() || 'Description is required']"
                    @ready="onReady"
                    v-model="PopupData.Description"
                  ></ckeditor>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pt-0" v-if="PopupData.showImageSelection">
                <v-tabs show-arrows dense class="browsetab" height="40px">
                  <v-tab active-class="base notransform accentdark--text"
                    >Upload a Photo</v-tab
                  >
                  <v-tab-item class="pt-3 pl-3 pr-1">
                    <v-row>
                      <v-col cols="auto" class="pl-0">
                        <div
                          class="imguploaderdiv uploadmultiimg ourcompanypreview"
                          @click="onButtonClick"
                        >
                          <span
                            class="material-icons-outlined accentlight--text uploadicon"
                          >
                            upload_file
                          </span>
                          <p class="accentdark--text uploadtext mb-0">
                            Upload a Photo
                          </p>
                        </div>
                        <!-- <v-btn
                          outlined
                          class="accentlight--text text-notransform px-8 py-4"
                          small
                          @click="onButtonClick"
                          >Browse</v-btn
                        > -->

                        <input
                          ref="fileuploadInput"
                          class="d-none"
                          type="file"
                          name="img"
                          accept="image/x-png,image/jpeg"
                          @change="UploadFile($event)"
                        />
                      </v-col>

                      <v-col class="pl-0" v-if="PopupData.ImageUrl">
                        <img class="imgpreview ourcompanypreview" :src="PopupData.ImageUrl" />
                      </v-col>
                    </v-row>
                  </v-tab-item>
                </v-tabs>
              </v-col>
            </v-row>
          </v-card-text>
        </vue-scroll>
        <v-card-actions class="d-sm-none borderTop-1 border-accentxlight">
          <v-row class="align-center mx-0 mb-n1 text-right justify-end">
            <v-col cols="auto" class="text-right pt-5 justify-end">
              <v-btn
                outlined
                class="accentlight--text mr-2 px-4"
                small
                @click="ClosePopup()"
                >Cancel</v-btn
              >
              <v-btn
                outlined
                class="accent--text px-5"
                small
                :loading="PopupData.SavingData"
                :disabled="PopupData.isValid || PopupData.Description == ''"
                @click="SaveDescription"
                >Save</v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showDetailDialog" scrollable max-width="700px">
      <v-card class="mb-0">
        <v-card-title
          class="accentdark--text borderBottom-1 border-accentxlight"
        >
          <v-row class="align-center">
            <v-col class="accentdark--text">{{ DetailDialogTitle }}</v-col>
            <v-col cols="auto">
              <v-btn
                plain
                class="secondarydark--text px-0"
                x-small
                @click="showDetailDialog = false"
              >
                <span class="material-icons-outlined"> close </span></v-btn
              >
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <div v-html="DetailDialogDescription"></div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import CompanyPolicyComponent from "../../components/CompanyPolicyComponent.vue";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import DocumentEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import apimethods from "../../mixins/apimethods";
import helpermethods from "../../mixins/helpermethods";
export default {
  name: "OurCompanyPage",
  mixins: [apimethods, helpermethods],
  components: {
    CompanyPolicyComponent,
    ckeditor: CKEditor.component,
  },
  props: {
    msg: String,
  },
  data() {
    return {
      showDetailDialog: false,
      DetailDialogTitle: "",
      DetailDialogDescription: "",
      editpost: false,
      CompanyPolicyComponenteditpost: false,
      editor: DocumentEditor,
      DescriptionList: [],
      PopupData: {
        isValid: false,
        SavingData: false,
        Heading: undefined,
        Description: undefined,
        ImageUrl: undefined,
        ImageName: undefined,
        updateImage: undefined,
        ImageFile: undefined,
        existingItem: undefined,
        showImageSelection: false,
      },
      ops: {
        scrollPanel: {
          scrollingX: false,
        },
      }, // Your options
      editorConfig: {
        toolbar: {
          items: [
            "bold",
            "italic",
            "underline",
            "numberedList",
            "indent",
            "outdent",
          ],
          shouldNotGroupWhenFull: true,
        },
      },
    };
  },

  created() {
    this.LoadAboutUsDescription();
  },
  methods: {
    onReady(editor) {
      setTimeout(() => {
        const toolbarContainer = document.querySelector(
          ".document-editor__toolbar"
        );
        toolbarContainer.appendChild(editor.ui.view.toolbar.element);
        var pictureUploadbtn = toolbarContainer.getElementsByClassName(
          "ck-file-dialog-button"
        );
        if (pictureUploadbtn != undefined && pictureUploadbtn.length > 0)
          pictureUploadbtn[0].classList.add("d-none");
      }, 200);
    },
    ShowDiscriptionDialog(title, description) {
      this.DetailDialogDescription = description;
      this.showDetailDialog = true;
      this.DetailDialogTitle = title;
    },
    OpenPopup(item) {
      this.editpost = true;
      this.PopupData.Heading = item.title;
      this.PopupData.Description = item.description;
      this.PopupData.ImageUrl = item.photoUrl;
      this.PopupData.ImageName = item.photoName;
      this.PopupData.existingItem = item;
      this.PopupData.ImageFile = undefined;
      this.PopupData.updateImage = false;
      if (this.$refs.fileuploadInput != undefined)
        this.$refs.fileuploadInput.value = null;
      if (item.descriptionTypeId != 6) this.PopupData.showImageSelection = true;
      else this.PopupData.showImageSelection = false;
    },
    ClosePopup() {
      this.editpost = false;
    },
    LoadAboutUsDescription() {
      this.$store.commit("showLoadingModal");
      var self = this;
      this.GetRequestWithAuthorization(
        "api/OurCompany/GetAllCompanyDescription",
        undefined
      )
        .then((res) => {
          self.$store.commit("hideLoadingModal");
          if (
            res != undefined &&
            res.data != undefined &&
            res.data.data != undefined
          ) {
            var result = JSON.parse(JSON.stringify(res.data.data));
            // result.forEach((element) => {
            //   if (
            //     element.description != undefined &&
            //     element.description.length > 800
            //   )
            //     element.ShowMoreButton = true;
            //   else element.ShowMoreButton = false;
            // });
            self.DescriptionList = result;
            setTimeout(() => {
              self.allocateseemorediv(null);
            }, 10);
          }
        })
        .catch((ex) => {
          self.$store.commit("hideLoadingModal");
        });
    },
    SaveDescription() {
      if (
        this.PopupData.ImageFile == undefined &&
        this.PopupData.showImageSelection == true &&
        this.PopupData.ImageUrl == ""
      ) {
        this.$notify({
          group: "notification",
          title: "Error",
          text: "Please select File",
          type: "error",
        });
        this.savingData = false;
        return;
      }
      var self = this;
      self.PopupData.SavingData = true;
      var bodyFormData = new FormData();
      bodyFormData.append("title", this.PopupData.Heading);
      bodyFormData.append("description", this.PopupData.Description);
      bodyFormData.append("id", this.PopupData.existingItem.id);
      bodyFormData.append("isPhotoUpdated", this.PopupData.updateImage);
      bodyFormData.append("file", this.PopupData.ImageFile);
      bodyFormData.append("photoName", this.PopupData.ImageName);
      bodyFormData.append(
        "descriptionTypeId",
        this.PopupData.existingItem.descriptionTypeId
      );

      this.PostFormRequestWithAuthorization(
        "api/OurCompany/UpdateCompanyDescription",
        undefined,
        bodyFormData
      )
        .then((res) => {
          this.editpost = false;
          self.PopupData.SavingData = false;
          if (
            res != undefined &&
            res.data != undefined &&
            res.data.status == true
          ) {
            self.$notify({
              group: "notification",
              title: "Message",
              text: "Description updated Successfully",
              type: "success",
            });
            self.dialog = false;
            self.LoadAboutUsDescription();
          }
        })
        .catch((ex) => {
          self.PopupData.SavingData = false;
          console.log(ex);
        });
    },
    onButtonClick() {
      this.$refs.fileuploadInput.click();
    },
    UploadFile(event) {
      if (event.target.files != null && event.target.files.length > 0) {
        var arrayFile = Array.from(event.target.files);
        this.PopupData.ImageFile = arrayFile[0];
        this.PopupData.ImageName = this.PopupData.ImageFile.name;
        this.PopupData.ImageUrl = URL.createObjectURL(this.PopupData.ImageFile);
        this.PopupData.updateImage = true;
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
