<template>
  <v-row class="d-flex fill-height ma-0">
    <v-col class="px-0 pl-md-0 pr-lg-3 pt-0 pt-lg-3">
      <v-row class="ma-0 fill-height p-relative box-shadow-1">
        <v-col class="white rounded d-flex fill-height flex-column p-absolute">
          <v-row class="align-center">
            <v-col cols="12" class="col-sm lgFont accentdark--text fw-700">
              Employees Directory
            </v-col>
            <v-col cols="auto" class="pr-0">
              <v-btn
                outlined
                color="accent"
                class="px-2 px-sm-3 empbtn"
                :loading="isExcelSelecting"
                @click.stop="UploadEmployeeExcelFile()"
                ><span class="material-icons-outlined mr-1 lgFont"
                  >file_upload</span
                >Import
                <span class="d-none d-md-inline-block"
                  >&nbsp;Spreadsheet</span
                ></v-btn
              >
              <input
                ref="exceluploader"
                class="d-none"
                type="file"
                name="excel"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                v-on:input="changeExcelFile"
              />
            </v-col>
            <v-col cols="auto" class="pr-0 pl-1 pl-sm-3">
              <v-btn
                outlined
                color="accentdark"
                class="white--text px-2 px-sm-3 empbtn"
                @click.stop="DownloadSampleFormatExcelFile()"
                ><span class="material-icons-outlined mr-1 lgFont"
                  >cloud_download</span
                >Sample</v-btn
              >
            </v-col>
            <v-col cols="auto" class="pl-1 pl-sm-3">
              <v-btn
                class="accent white--text px-2 px-sm-3 empbtn"
                @click.stop="OpenPopup('create', undefined)"
                ><span class="material-icons-outlined mr-1 lgFont"
                  >add_circle_outline</span
                ><span class="d-none d-md-inline-block">Add&nbsp;</span>
                Employee
                <span class="d-none d-md-inline-block"
                  >&nbsp;Profile</span
                ></v-btn
              >
            </v-col>
          </v-row>
          <v-row class="align-center">
            <v-col cols="auto" class="pr-0">Search</v-col>
            <v-col md="2" class="col-xl pr-0">
              <v-text-field
                label="First, Last Name"
                outlined
                dense
                hide-details
                v-model="SearchedName"
                @keypress.enter="searchByName"
              ></v-text-field
            ></v-col>
            <v-col cols="auto" class="pr-0 d-md-none">
              <v-btn
                :class="{ accentdark: ShowFilters }"
                outlined
                color="secondary"
                class="employeefilter"
                @click="MobileSearchFilterPopup = true"
              >
                <span class="secondary--text material-icons-outlined xlgFont"
                  >filter_list</span
                ></v-btn
              >
            </v-col>
            <v-col cols="auto" class="pr-0 d-none d-md-block">
              <v-btn
                :class="{ accentdark: ShowFilters }"
                outlined
                color="secondary"
                class="employeefilter"
                @click="ShowFilters = !ShowFilters"
              >
                <span class="secondary--text material-icons-outlined xlgFont"
                  >filter_list</span
                ></v-btn
              >
            </v-col>

            <v-col
              xl="2"
              v-show="ShowFilters"
              class="pr-0 d-none d-md-block"
              cols="3"
              ><v-select
                v-show="ShowFilters"
                label="Search by Department"
                dense
                outlined
                hide-details
                item-text="title"
                v-model="SelectedDepartment"
                return-object
                :items="ListOfDepartments"
              ></v-select
            ></v-col>
            <v-col xl="2" v-show="ShowFilters" class="pr-0 d-none d-md-block"
              ><v-select
                v-show="ShowFilters"
                label="Search by City"
                dense
                outlined
                hide-details
                item-text="name"
                v-model="SelectedCity"
                :items="ListOfCities"
                return-object
              ></v-select
            ></v-col>
            <v-col cols="auto">
              <v-btn
                class="employeefilter"
                :class="{ accentdark: allowReset }"
                outlined
                color="secondary"
                @click="resetFilters"
                :disabled="!allowReset"
              >
                <span class="secondary--text material-icons-outlined xlgFont"
                  >refresh</span
                ></v-btn
              >
            </v-col>
          </v-row>
          <v-row class="fill-height p-relative mb-0">
            <v-col>
              <v-row class="fill-height p-absolute wd-100 employeedirectory">
                <vue-scroll :ops="ops" @handle-scroll="onScroll">
                  <v-col cols="12">
                    <v-card
                      outlined
                      class="pa-2 mb-2"
                      v-for="employee in ListOfEmployees"
                      :key="employee.id"
                    >
                      <v-row
                        class="accentlight--text xsFont align-center ma-0 ma-md-n3"
                      >
                        <v-col
                          cols="3"
                          md="auto"
                          class="line-height-0 py-1 py-md-3 order-0 order-md-0"
                        >
                          <img
                            :src="employee.profileImageURL"
                            class="employeepic"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          class="col-sm min-wd-0 py-1 py-md-3 order-2 order-md-1"
                        >
                          Name
                          <div class="accentdark--text text-truncate fw-500">
                            {{ employee.firstName + " " + employee.lastName }}
                          </div>
                        </v-col>
                        <v-col
                          cols="6"
                          sm="2"
                          md="2"
                          class="py-1 py-md-3 order-4 order-md-3"
                        >
                          User Type
                          <v-tooltip bottom color="accentdark">
                            <template v-slot:activator="{ on, attrs }">
                              <div
                                v-bind="attrs"
                                v-on="on"
                                class="accentdark--text text-truncate fw-500"
                              >
                                {{ employee.role }}
                              </div>
                            </template>
                            <span> {{ employee.role }}</span>
                          </v-tooltip>
                        </v-col>
                        <v-col
                          cols="6"
                          sm="4"
                          class="py-1 py-md-3 col-md order-5 min-wd-0 order-md-4"
                        >
                          Email
                          <v-tooltip bottom color="accentdark">
                            <template v-slot:activator="{ on, attrs }">
                              <div
                                v-bind="attrs"
                                v-on="on"
                                class="accentdark--text text-truncate fw-500"
                              >
                                {{ employee.email }}
                              </div>
                            </template>
                            <span> {{ employee.email }}</span>
                          </v-tooltip>
                        </v-col>
                        <v-col
                          cols="6"
                          sm="3"
                          md="2"
                          class="pl-md-0 py-1 py-md-3 order-7 order-md-6"
                        >
                          Department
                          <div class="accentdark--text text-truncate fw-500">
                            {{ employee.department }}
                          </div>
                        </v-col>

                        <v-col
                          cols="9"
                          md="auto"
                          class="pl-0 order-1 py-1 pr-0 py-md-3 order-md-8 text-right"
                        >
                          <v-tooltip bottom color="accentdark">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                text
                                color="accentlight"
                                v-bind="attrs"
                                class="sendbtn px-0 px-sm-3"
                                v-on="on"
                                @click="SendCredentials(employee)"
                              >
                                <span
                                  class="material-icons-outlined lgFont mr-1"
                                  >email</span
                                >
                                Send</v-btn
                              >
                            </template>
                            <span>Send Login Credentials via Email</span>
                          </v-tooltip>

                          <v-btn
                            text
                            color="accentlight"
                            class="mr-0 removebtn px-0"
                            @click="
                              ConfirmRemove(
                                employee.id,
                                employee.firstName + ' ' + employee.lastName
                              )
                            "
                          >
                            <span class="material-icons lgFont mr-1"
                              >delete</span
                            >Remove</v-btn
                          >

                          <v-btn
                            class="editbtn px-0 px-md-3"
                            text
                            color="accentlight"
                            @click="OpenPopup('edit', employee)"
                          >
                            <span class="material-icons-outlined lgFont mr-1"
                              >edit</span
                            >
                            Edit</v-btn
                          >
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                </vue-scroll>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>

    <v-dialog
      v-model="Cropperdialog"
      content-class="imagecropermodal"
      max-width="320"
      style="height: 500px"
    >
      <v-card class="mb-0">
        <v-card-title
          class="accentdark--text borderBottom-1 border-accentxlight"
        >
          <v-row class="align-center">
            <v-col class="accentdark--text">Profile Image</v-col>
            <v-col cols="auto">
              <v-btn
                outlined
                class="accentlight--text mr-2"
                small
                @click="cancelCrop"
                >Cancel</v-btn
              >
              <v-btn
                outlined
                :disabled="!imageuploadedforCropping"
                class="accent--text"
                small
                @click="SaveImageCrop"
                >Save</v-btn
              >
            </v-col>
          </v-row>
        </v-card-title>

        <v-card-text>
          <image-cropper
            v-on:imageUploaded="imageuploadedforCropping = true"
            ref="imagecropComp"
            :AspectRatioHeight="10"
            :AspectRatioWidth="10"
          />
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="MobileSearchFilterPopup" max-width="320">
      <v-card class="mb-0">
        <v-card-title
          class="accentdark--text borderBottom-1 border-accentxlight"
        >
          <v-row class="align-center">
            <v-col class="accentdark--text">Search Filter</v-col>
          </v-row>
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="12"
              ><v-select
                label="Search by Department"
                dense
                outlined
                hide-details
                item-text="title"
                v-model="SelectedDepartment"
                return-object
                :items="ListOfDepartments"
              ></v-select
            ></v-col>
            <v-col cols="12"
              ><v-select
                label="Search by City"
                dense
                outlined
                hide-details
                item-text="name"
                v-model="SelectedCity"
                :items="ListOfCities"
                return-object
              ></v-select
            ></v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-sm-none borderTop-1 border-accentxlight">
          <v-row class="align-center mb-n1 text-right justify-end">
            <v-col cols="auto" class="text-right pt-5 justify-end">
              <v-btn
                outlined
                class="accentlight--text mr-2"
                small
                @click="MobileSearchFilterPopup = false"
                >Cancel</v-btn
              >
              <v-btn
                outlined
                class="accent--text"
                small
                @click="ApplyMobileFilter"
                >Apply</v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      scrollable
      v-model="employeeDialog"
      content-class="dashboardmodal"
      max-width="750"
    >
      <v-card class="mb-0">
        <v-card-title
          class="accentdark--text borderBottom-1 border-accentxlight"
        >
          <v-row class="align-center">
            <v-col>{{ PopupHeading }}</v-col>
            <v-col cols="auto" class="d-none d-sm-block">
              <v-btn
                outlined
                class="accentlight--text mr-2"
                small
                @click="employeeDialog = false"
                >Cancel</v-btn
              >
              <v-btn
                outlined
                class="accent--text"
                small
                @click="SubmitForm"
                :loading="PopupData.savingData"
                :disabled="!PopupData.isValid"
                >Save</v-btn
              >
            </v-col>
          </v-row>
        </v-card-title>
        <vue-scroll>
          <v-card-text>
            <v-form v-model="PopupData.isValid" ref="employeeForm">
              <v-row>
                <v-col cols="12" sm="3">
                  <div
                    class="imguploaderdiv profilephotodiv"
                    v-if="!pickerImageUrl"
                    @click="Cropperdialog = true"
                  >
                    <span
                      class="material-icons-outlined accentlight--text uploadicon"
                    >
                      upload_file
                    </span>
                    <p class="accentdark--text mdFont py-2 mb-0">
                      Upload a Photo
                    </p>
                  </div>

                  <img
                    class="cursor-pointer profileimgadmin"
                    v-if="pickerImageUrl"
                    :src="pickerImageUrl"
                    @click="Cropperdialog = true"
                  />
                </v-col>
                <v-col cols="12" sm="9">
                  <v-row>
                    <v-col cols="12" sm="4">
                      <v-text-field
                        label="First Name"
                        outlined
                        dense
                        maxlength="100"
                        hide-details
                        :rules="Required"
                        v-model="PopupData.FirstName"
                      ></v-text-field
                    ></v-col>
                    <v-col cols="12" sm="4">
                      <v-text-field
                        label="Last Name"
                        maxlength="100"
                        outlined
                        dense
                        hide-details
                        :rules="Required"
                        v-model="PopupData.LastName"
                      ></v-text-field
                    ></v-col>
                    <v-col cols="12" sm="4">
                      <v-select
                        label="Gender"
                        outlined
                        dense
                        hide-details
                        item-text="name"
                        return-object
                        :rules="Required"
                        :items="ListofGenders"
                        v-model="PopupData.SelectedGender"
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="4"
                      ><v-select
                        label="User Type"
                        dense
                        outlined
                        hide-details
                        return-object
                        :rules="Required"
                        :items="ListOfUserRoles"
                        item-text="name"
                        v-model="PopupData.SelectedUserType"
                        :disabled="PopupData.DisableUserTypeSelection"
                      ></v-select
                    ></v-col>
                    <v-col cols="12" sm="4">
                      <v-text-field
                        label="Email"
                        outlined
                        dense
                        hide-details
                        :rules="Emailrules"
                        maxlength="100"
                        v-model="PopupData.Email"
                        :disabled="PopupData.DisabledEmailField"
                      ></v-text-field
                    ></v-col>
                    <v-col cols="12" sm="4">
                      <v-select
                        label="Department"
                        dense
                        outlined
                        hide-details
                        item-text="title"
                        return-object
                        :rules="Required"
                        :items="PopupData.ListOfDepartments"
                        v-model="PopupData.SelectedDepartment"
                      ></v-select
                    ></v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="4">
                      <!-- <v-date-picker
                      label="Date of Birth"
                      outlined
                      dense
                      hide-details
                      :rules="Required"
                      v-model="PopupData.DateOfBirth"
                    ></v-date-picker> -->
                      <v-menu
                        ref="DOBmenu"
                        v-model="DOBmenu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="PopupData.DateOfBirth"
                            label="Date of Birth"
                            outlined
                            dense
                            hide-details
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            :rules="Required"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          no-title
                          v-model="PopupData.DateOfBirth"
                          :active-picker.sync="activeDOBPicker"
                          :max="
                            new Date(
                              Date.now() -
                                new Date().getTimezoneOffset() * 60000
                            )
                              .toISOString()
                              .substr(0, 10)
                          "
                          min="1950-01-01"
                          @input="DOBmenu = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-select
                        label="City"
                        outlined
                        dense
                        hide-details
                        item-text="name"
                        return-object
                        :items="ListOfCities"
                        :rules="Required"
                        v-model="PopupData.SelectedCity"
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </vue-scroll>
        <v-card-actions class="d-sm-none borderTop-1 border-accentxlight">
          <v-row class="align-center mb-n1 text-right justify-end">
            <v-col cols="auto" class="text-right pt-5 justify-end">
              <v-btn
                outlined
                class="accentlight--text mr-2"
                small
                @click="employeeDialog = false"
                >Cancel</v-btn
              >
              <v-btn
                outlined
                class="accent--text"
                small
                @click="SubmitForm"
                :loading="PopupData.savingData"
                :disabled="!PopupData.isValid"
                >Save</v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="excelUploadDialog" max-width="500">
      <v-card class="mb-0">
        <v-card-title
          class="accentdark--text borderBottom-1 border-accentxlight"
        >
          <v-row class="align-center">
            <v-col class="accentdark--text">Import Spreadsheet</v-col>
            <v-col
              cols="auto"
              class="cursor-pointer"
              @click="excelUploadDialog = false"
            >
              X
            </v-col>
          </v-row>
        </v-card-title>

        <v-card-text>
          <v-row class="align-center uploaddiv">
            <v-col class="">
              <v-row class="align-center ma-0 innerdiv">
                <v-col class="">
                  <v-row no-gutters class="align-center">
                    <v-col cols="auto" class="pr-2 ma-0 line-height-0"
                      ><span
                        class="f-60 secondarydark--text material-icons-outlined xlgFont"
                      >
                        upload_file
                      </span></v-col
                    >
                    <v-col class="ma-0">{{
                      excelFileName ? excelFileName : "Upload A Spreadsheet"
                    }}</v-col>
                  </v-row>
                </v-col>
                <v-col cols="auto">
                  <v-btn
                    v-if="!excelFileName"
                    outlined
                    class="accent--text mr-2 text-notransform"
                    :loading="isExcelSelecting"
                    :disabled="isStartUploadDisabled"
                    small
                    @click="fileChangeClick"
                    >Browse</v-btn
                  >
                  <v-btn
                    v-if="excelFileName"
                    outlined
                    class="accent--text mr-2"
                    :loading="isExcelSelecting"
                    :disabled="isStartUploadDisabled"
                    small
                    @click="startUploadExcelData"
                    >Import</v-btn
                  >
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row v-if="excelFailedList.length > 0">
            <v-col>
              <vue-scroll>
                <div class="max-h-300">
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left" style="white-space: nowrap">
                            First Name
                          </th>
                          <th class="text-left" style="white-space: nowrap">
                            Last Name
                          </th>
                          <th
                            class="text-left"
                            style="white-space: nowrap; min-width: 150px"
                          >
                            Message
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(item, index) in excelFailedList"
                          :key="index"
                        >
                          <td class="xsFont">
                            {{ item.firstName ? item.firstName : "N/A" }}
                          </td>
                          <td class="xsFont">
                            {{ item.lastName ? item.lastName : "N/A" }}
                          </td>
                          <td
                            class="xsFont error--text"
                            style="white-space: nowrap"
                          >
                            {{ item.error }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </div>
              </vue-scroll>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showRemoveConfirmDialog" max-width="350">
      <v-card class="mb-0">
        <v-card-text>
          <v-row>
            <v-col class="fw-500 mdFont accentdark--text">
              Are you sure you want to remove this employee ({{
                currentRemoveName
              }})?
            </v-col>
          </v-row>
          <v-row>
            <v-col class="fw-500 smFont error--text">
              Note: This action cannot be undone.
            </v-col>
          </v-row>
          <v-row class="justify-end mt-1">
            <v-col cols="12" class="text-right">
              <v-btn
                outlined
                class="accentlight--text mr-2"
                small
                @click="showRemoveConfirmDialog = false"
                >Cancel</v-btn
              >
              <v-btn
                outlined
                class="accent--text"
                small
                @click="RemoveEmployee(currentRemoveId)"
                >Yes</v-btn
              >
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import ImageCropper from "../../components/Popup/ImageCropper.vue";
import apimethods from "../../mixins/apimethods";
import helpermethods from "../../mixins/helpermethods";

export default {
  components: {
    ImageCropper,
  },
  mixins: [apimethods, helpermethods],
  created() {
    this.GetUserInitialData();
    this.GetEmployees();
  },
  data() {
    return {
      showRemoveConfirmDialog: false,
      currentRemoveId: 0,
      currentRemoveName: "",
      ops: {
        scrollPanel: {
          scrollingX: false,
        },
      }, // Your options
      imageuploadedforCropping: false,
      isAlreadyRequestLaunched: false,
      excelFileName: "",
      excelFailedList: [],
      excelRows: {},
      excelUploadDialog: false,
      isExcelSelecting: false,
      isStartUploadDisabled: false,
      totalRecords: 0,
      skip: 0,
      take: 20,
      employeeDialog: false,
      Cropperdialog: false,
      MobileSearchFilterPopup: false,
      ListOfDepartments: [],
      ListofGenders: [],
      ListOfCities: [],
      ListOfUserRoles: [],
      SelectedDepartment: undefined,
      SelectedCity: undefined,
      ShowFilters: false,
      SearchedName: "",
      ListOfEmployees: [],
      pickerImageUrl: undefined,
      activeDOBPicker: null,
      activeJoiningPicker: null,
      DOBmenu: false,
      Required: [(v) => !!v || "Item is required"],
      Emailrules: [
        (value) => !!value || "Required.",
        (value) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value) ||
          "E-mail must be valid",
      ],
      PopupData: {
        isValid: false,
        PopupHeading: "",
        FirstName: "",
        LastName: "",
        SelectedUserType: undefined,
        Email: "",
        SelectedDepartment: undefined,
        DateOfBirth: "",
        SelectedCity: undefined,
        ListOfDepartments: [],
        SelectedGender: undefined,
        savingData: false,
        UpdateImage: false,
        EditEmployeeObject: undefined,
        DisableUserTypeSelection: false,
        DisabledEmailField: false,
      },
    };
  },
  name: "EmployeesPage",
  props: {
    msg: String,
  },
  watch: {
    Cropperdialog: function (newValue, oldValue) {
      if (!newValue) this.cancelCrop();
      if (newValue && this.$refs.imagecropComp) {
        setTimeout(() => {
          this.$refs.imagecropComp.onButtonClick();
        }, 10);
      }
    },
    SelectedDepartment: function (val) {
      if (this.isMobile) return;
      this.skip = 0;
      this.ListOfEmployees = [];
      this.totalRecords = 0;
      this.GetEmployees();
    },
    SelectedCity: function (val) {
      if (this.isMobile) return;

      this.skip = 0;
      this.totalRecords = 0;
      this.ListOfEmployees = [];
      this.GetEmployees();
    },
    DOBmenu(val) {
      val && setTimeout(() => (this.activeDOBPicker = "YEAR"));
    },
  },
  computed: {
    allowReset() {
      return (
        this.SearchedName != "" ||
        (this.SelectedDepartment != undefined &&
          this.SelectedDepartment != null) ||
        (this.SelectedCity != undefined && this.SelectedCity != null)
      );
    },
  },
  methods: {
    ApplyMobileFilter() {
      if (this.SelectedDepartment || this.SelectedCity) {
        this.skip = 0;
        this.ListOfEmployees = [];
        this.totalRecords = 0;
        this.GetEmployees();
      }
      this.MobileSearchFilterPopup = false;
    },
    UploadEmployeeExcelFile() {
      this.$refs.exceluploader.value = "";
      this.excelFileName = "";
      this.isStartUploadDisabled = false;
      this.excelFailedList = [];
      this.excelUploadDialog = true;
    },
    fileChangeClick() {
      window.addEventListener(
        "focus",
        () => {
          this.isExcelSelecting = false;
        },
        { once: true }
      );
      this.$refs.exceluploader.click();
    },
    changeExcelFile(e) {
      if (
        e.target.files.length > 0 &&
        (e.target.files[0].type ==
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
          e.target.files[0].type == "application/vnd.ms-excel")
      ) {
        this.readExcel(e.target.files[0]);
      } else {
        this.$notify({
          group: "notification",
          title: "Error",
          text: "Invalid file format, Please use the appropriate file format and try again.",
          type: "error",
        });
        this.$refs.exceluploader.value = "";
      }
    },
    ExcelDateToJSDate(serial) {
      var utc_days = Math.floor(serial - 25569);
      var utc_value = utc_days * 86400;
      var date_info = new Date(utc_value * 1000);

      var fractional_day = serial - Math.floor(serial) + 0.0000001;

      var total_seconds = Math.floor(86400 * fractional_day);

      var seconds = total_seconds % 60;

      total_seconds -= seconds;

      var hours = Math.floor(total_seconds / (60 * 60));
      var minutes = Math.floor(total_seconds / 60) % 60;

      var resDate = new Date(
        date_info.getFullYear(),
        date_info.getMonth(),
        date_info.getDate(),
        hours,
        minutes,
        seconds
      );
      resDate.setDate(resDate.getDate() + 1);
      return resDate;
    },
    readExcel(selectedFile) {
      this.$store.commit("showLoadingModal");
      this.excelFileName = selectedFile.name;
      var XLSX = require("xlsx");
      const reader = new FileReader();
      var self = this;

      reader.onload = (e) => {
        /* Parse data */
        const bstr = e.target.result;
        const wb = XLSX.read(bstr, { type: "binary" });
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        /* Convert array of arrays */
        const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
        self.$refs.exceluploader.value = "";
        self.confirmUpload(data);
      };

      reader.readAsBinaryString(selectedFile);
    },
    confirmUpload(data) {
      this.isStartUploadDisabled = false;
      this.excelFailedList = [];
      this.excelRows = this.filterEmptyRowsInExcel(data);
      this.$store.commit("hideLoadingModal");
      if (this.excelRows == null || this.excelRows.length <= 1) {
        this.excelUploadDialog = false;
        this.$notify({
          group: "notification",
          title: "Error",
          text: "No Records found",
          type: "error",
        });
        return;
      }
      if (
        this.excelRows[0].length != 7 ||
        !this.validateExcelFirstRow(this.excelRows[0])
      ) {
        this.excelUploadDialog = false;
        this.$notify({
          group: "notification",
          title: "Error",
          text: "The file you uploaded is not the expected excel file. Please upload correct file, you can download the sample file using sample button.",
          type: "error",
        });
        return;
      }

      this.excelFileName +=
        " (" + (this.excelRows.length - 1) + " record(s) found)";
      this.excelUploadDialog = true;
    },
    filterEmptyRowsInExcel(exceldata) {
      return exceldata.filter((o) =>
        Object.keys(o).some((k) => o[k].trim() != "")
      );
    },
    validateExcelFirstRow(firstRow) {
      if (firstRow[0] != "FirstName") return false;

      if (firstRow[1] != "LastName") return false;

      if (firstRow[2] != "Gender") return false;

      if (firstRow[3] != "Email") return false;

      if (firstRow[4] != "Department") return false;

      if (firstRow[5] != "DateOfBirth") return false;

      if (firstRow[6] != "City") return false;

      return true;
    },
    validateExcelRow(row) {
      if (!row.firstName || row.firstName.trim() == "") return false;

      if (!row.lastName || row.lastName.trim() == "") return false;

      if (!row.genderString || row.genderString.trim() == "") return false;

      if (!row.email || row.email.trim() == "") return false;

      if (!row.department || row.department.trim() == "") return false;

      if (!row.dateOfBirth) return false;

      if (!row.city || row.city.trim() == "") return false;

      return true;
    },
    startUploadExcelData() {
      if (this.excelRows == null || this.excelRows.length <= 1) {
        this.excelUploadDialog = false;
        this.$notify({
          group: "notification",
          title: "Error",
          text: "No Records found",
          type: "error",
        });
        return;
      }
      this.isStartUploadDisabled = true;
      this.isExcelSelecting = true;
      var recordsSuccessfullyUploaded = 0;
      var lstPromises = [];
      var recordsUploaded = [];
      for (var i = 1; i < this.excelRows.length; i++) {
        var currentRow = this.excelRows[i];
        var self = this;
        var bodyFormData = {};
        bodyFormData.firstName = currentRow[0] ? currentRow[0] : "";
        bodyFormData.lastName = currentRow[1];
        bodyFormData.genderString = currentRow[2];
        bodyFormData.userType = 4;
        bodyFormData.email = currentRow[3] ? currentRow[3] : "";
        bodyFormData.department = currentRow[4];
        bodyFormData.dateOfBirth = this.ExcelDateToJSDate(currentRow[5]);
        bodyFormData.city = currentRow[6];
        var elementJson = JSON.stringify(bodyFormData);

        if (!this.validateExcelRow(bodyFormData)) {
          bodyFormData.error =
            "Excel Row has Incorrect Data, All columns are required.";
          self.excelFailedList.push(JSON.parse(JSON.stringify(bodyFormData)));
          continue;
        }

        var isDuplicate = recordsUploaded.find(
          (x) => x.email.trim() == bodyFormData.email.trim()
        );

        if (isDuplicate) {
          bodyFormData.error = "duplicate (email) record.";
          self.excelFailedList.push(JSON.parse(JSON.stringify(bodyFormData)));
          console.log(self.excelFailedList);
          continue;
        }
        recordsUploaded.push(bodyFormData);
        lstPromises.push(
          this.PostRequestWithAuthorization(
            "api/Employee/AddEmployeeFromExcel",
            undefined,
            elementJson
          )
            .then((res) => {
              if (res != undefined && res.data != undefined) {
                if (res.data.status == false) {
                  bodyFormData.error = res.data.errorMessage;
                  self.excelFailedList.push(
                    JSON.parse(JSON.stringify(bodyFormData))
                  );
                  self.excelFailedList.reverse();
                } else if (res.data.status == true) {
                  recordsSuccessfullyUploaded++;
                }
              }
            })
            .catch((ex) => {
              console.log(ex);
            })
        );
      }
      Promise.all(lstPromises).then((values) => {
        self.isExcelSelecting = false;
        if (recordsSuccessfullyUploaded > 0) {
          self.ListOfEmployees = [];
          self.GetEmployees();
          self.$notify({
            group: "notification",
            title: "Records Uploaded",
            text:
              recordsSuccessfullyUploaded + " record(s) successfully uploaded.",
            type: "success",
          });
        }
        if (self.excelFailedList == null || self.excelFailedList.length == 0) {
          self.excelUploadDialog = false;
        }
      });
    },
    DownloadSampleFormatExcelFile() {
      this.$store.commit("showLoadingModal");
      var self = this;
      this.GetRequestWithAuthorization(
        "api/MasterData/GetSampleEmployeeUploadExcelFile",
        undefined
      )
        .then((res) => {
          if (
            res != undefined &&
            res.data != undefined &&
            res.data.data != undefined
          ) {
            this.$store.commit("hideLoadingModal");
            if (res.data.data) {
              var link = document.createElement("a");
              link.setAttribute("download", name);
              link.href = res.data.data;
              document.body.appendChild(link);
              link.click();
              link.remove();
            } else {
              self.$notify({
                group: "notification",
                title: "Error",
                text: res.data.errorMessage,
                type: "error",
              });
            }
          }
        })
        .catch((ex) => {
          this.$store.commit("hideLoadingModal");
        });
    },
    resetFilters() {
      var reset =
        this.SelectedDepartment != null ||
        this.SelectedCity != null ||
        this.SearchedName != "";
      if (!reset) {
        return;
      }
      this.SearchedName = "";
      this.SelectedDepartment = null;
      this.SelectedCity = null;
      this.skip = 0;
      this.totalRecords = 0;
      this.ListOfEmployees = [];
      this.GetEmployees();
    },
    SendCredentials(employee) {
      this.$store.commit("showLoadingModal");
      var self = this;
      this.GetRequestWithAuthorization("api/Employee/SendCredentialsViaEmail", [
        {
          Key: "employeeId",
          Value: employee.id,
        },
      ])
        .then((res) => {
          if (
            res != undefined &&
            res.data != undefined &&
            res.data.data != undefined
          ) {
            this.$store.commit("hideLoadingModal");

            if (res.data.data == true) {
              self.$notify({
                group: "notification",
                title: "Success",
                text: "Email Sent Successfully.",
                type: "success",
              });
            } else {
              self.$notify({
                group: "notification",
                title: "Error",
                text: res.data.errorMessage,
                type: "error",
              });
            }
          }
        })
        .catch((ex) => {
          this.$store.commit("hideLoadingModal");
        });
    },
    searchByName() {
      this.skip = 0;
      this.ListOfEmployees = [];
      this.totalRecords = 0;
      this.GetEmployees();
    },
    onScroll(vertical, horizontal, nativeEvent) {
      if (
        vertical.process == 1 &&
        (this.totalRecords == 0 ||
          this.totalRecords > this.ListOfEmployees.length)
      ) {
        this.skip = this.ListOfEmployees.length;
        this.GetEmployees();
      }
    },
    GetUserInitialData() {
      this.fetchDatafromInd("Cities").then((cities) => {
        this.ListOfCities = cities;
      });

      var self = this;
      this.fetchDatafromInd("UserRoles").then((userRoles) => {
        if (userRoles != undefined && userRoles.length > 0) {
          var userDetailStr = localStorage.getItem("UserDetail");
          if (userDetailStr != null) {
            var userDetail = JSON.parse(userDetailStr);
            if (userDetail.roleId == 1) {
              this.ListOfUserRoles = userRoles;
            } else {
              self.ListOfUserRoles = userRoles.filter((x) => x.id == 4);
            }
          } else {
            self.ListOfUserRoles = userRoles.filter((x) => x.id == 4);
          }
        }
      });

      this.fetchDatafromInd("Genders").then((genders) => {
        this.ListofGenders = genders;
      });

      var self = this;
      this.GetRequestWithAuthorization("api/Department/Get", undefined)
        .then((res) => {
          if (
            res != undefined &&
            res.data != undefined &&
            res.data.data != undefined
          ) {
            self.ListOfDepartments = res.data.data;
            self.PopupData.ListOfDepartments = res.data.data;
          }
        })
        .catch((ex) => {});
    },

    ConfirmRemove(emp, name) {
      this.currentRemoveId = emp;
      this.currentRemoveName = name;
      this.showRemoveConfirmDialog = true;
    },
    RemoveEmployee(empId) {
      this.$store.commit("showLoadingModal");
      this.showRemoveConfirmDialog = false;
      var self = this;
      this.GetRequestWithAuthorization("api/Employee/Remove", [
        {
          Key: "employeeId",
          Value: this.currentRemoveId,
        },
      ])
        .then((res) => {
          this.$store.commit("hideLoadingModal");

          if (res != undefined && res.data != undefined) {
            if (res.data.status == true) {
              self.$notify({
                group: "notification",
                title: "Message",
                text: "Employee Removed Successfully",
                type: "success",
              });
              self.skip = 0;
              self.ListOfEmployees = [];
              self.totalRecords = 0;
              self.GetEmployees();
            } else {
              self.$notify({
                group: "notification",
                title: "Error",
                text: res.data.errorMessage,
                type: "error",
              });
            }
          }
        })
        .catch((ex) => {
          this.$store.commit("hideLoadingModal");
          console.log(ex);
        });
    },

    OpenPopup(type, item) {
      this.PopupType = type;
      this.employeeDialog = true;
      this.PopupData.UpdateImage = false;
      if (this.PopupType == "create") {
        this.PopupHeading = "Add Profile";
        this.PopupData.EditEmployeeObject = "";
        this.PopupData.FirstName = "";
        this.PopupData.LastName = "";
        if (
          this.ListOfUserRoles != undefined &&
          this.ListOfUserRoles.length > 0
        ) {
          this.PopupData.SelectedUserType =
            this.ListOfUserRoles[this.ListOfUserRoles.length - 1];
        }
        this.PopupData.Email = "";
        this.PopupData.SelectedDepartment = undefined;
        this.PopupData.DateOfBirth = "";
        this.PopupData.SelectedCity = undefined;
        this.PopupData.SelectedGender = undefined;
        this.pickerImageUrl = "";
        this.PopupData.DisableUserTypeSelection =
          this.checkUserTypeSelectionAllowed();

        this.PopupData.DisabledEmailField = false;
        this.$refs.employeeForm.resetValidation();
      } else {
        this.PopupHeading = "Edit Profile";
        this.PopupData.EditEmployeeObject = item;
        this.pickerImageUrl = item.profileImageURL;
        this.PopupData.FirstName = item.firstName;
        this.PopupData.LastName = item.lastName;
        var userTypetemp = this.ListOfUserRoles.filter(
          (x) => x.id == item.userType
        );
        if (userTypetemp != undefined && userTypetemp.length > 0)
          this.PopupData.SelectedUserType = userTypetemp[0];
        this.PopupData.Email = item.email;
        var userDepartmenttemp = this.PopupData.ListOfDepartments.filter(
          (x) => x.id == item.departmentId
        );
        if (userDepartmenttemp != undefined && userDepartmenttemp.length > 0) {
          this.PopupData.SelectedDepartment = userDepartmenttemp[0];
        }

        this.PopupData.DateOfBirth = item.dateOfBirthFormatted;
        var usercitytemp = this.ListOfCities.filter((x) => x.id == item.cityId);
        if (usercitytemp != undefined && usercitytemp.length > 0)
          this.PopupData.SelectedCity = usercitytemp[0];
        var usergendertemp = this.ListofGenders.filter(
          (x) => x.id == item.gender
        );
        if (usergendertemp != undefined && usergendertemp.length > 0)
          this.PopupData.SelectedGender = usergendertemp[0];
        this.PopupData.DisableUserTypeSelection =
          this.checkUserTypeSelectionAllowed();
        this.PopupData.DisabledEmailField = true;
      }
    },
    checkUserTypeSelectionAllowed() {
      var userDetailStr = localStorage.getItem("UserDetail");
      if (userDetailStr != null) {
        var userDetail = JSON.parse(userDetailStr);
        return userDetail.roleId != 1;
      }
      return true;
    },

    GetEmployees() {
      if (this.isAlreadyRequestLaunched) return;
      this.isAlreadyRequestLaunched = true;
      this.$store.commit("showLoadingModal");
      var self = this;
      var object = {};
      object.take = this.take;
      object.Skip = this.skip;
      object.NameFilter = this.SearchedName;
      if (this.SelectedDepartment != undefined)
        object.departmentId = this.SelectedDepartment.id;
      else object.departmentId = 0;
      if (this.SelectedCity != undefined) object.cityId = this.SelectedCity.id;
      else object.cityId = 0;
      var elementJson = JSON.stringify(object);
      this.PostRequestWithAuthorization(
        "api/Employee/Get",
        undefined,
        elementJson
      )
        .then((res) => {
          self.isAlreadyRequestLaunched = false;

          this.$store.commit("hideLoadingModal");
          if (
            res != undefined &&
            res.data != undefined &&
            res.data.data != undefined
          ) {
            self.ListOfEmployees.push(...res.data.data);
            self.totalRecords = res.data.totalRecords;
            //self.ListOfEmployees = res.data.data;
          }
        })
        .catch((ex) => {
          self.isAlreadyRequestLaunched = false;

          this.$store.commit("hideLoadingModal");
        });
    },
    cancelCrop() {
      this.$refs.imagecropComp.$data.img = null;
      this.$refs.imagecropComp.$refs.uploader.value = "";
      this.Cropperdialog = false;
      this.$data.imageuploadedforCropping = false;
    },
    SaveImageCrop() {
      this.PopupData.UpdateImage = true;
      if (this.$refs.imagecropComp.CroppedImage != undefined)
        this.pickerImageUrl =
          this.$refs.imagecropComp.CroppedImage.toDataURL("image/jpg");
      this.Cropperdialog = false;
      this.$data.imageuploadedforCropping = false;
    },

    SubmitForm() {
      var self = this;
      self.PopupData.savingData = true;
      if (this.PopupType == "create") {
        if (this.pickerImageUrl == undefined || this.pickerImageUrl == "") {
          this.$notify({
            group: "notification",
            title: "Error",
            text: "Please select image",
            type: "error",
          });
          self.PopupData.savingData = false;
          return;
        }

        var bodyFormData = new FormData();
        bodyFormData.append("firstName", this.PopupData.FirstName);
        bodyFormData.append("lastName", this.PopupData.LastName);
        bodyFormData.append("gender", this.PopupData.SelectedGender.id);
        bodyFormData.append("userType", this.PopupData.SelectedUserType.id);
        bodyFormData.append("email", this.PopupData.Email);
        bodyFormData.append("cityId", this.PopupData.SelectedCity.id);
        bodyFormData.append(
          "departmentId",
          this.PopupData.SelectedDepartment.id
        );
        bodyFormData.append("dateOfBirth", this.PopupData.DateOfBirth);
        var filename = this.generateUUID() + ".png";
        bodyFormData.append("profileImageFileName", filename);

        var blobBin = atob(this.pickerImageUrl.split(",")[1]);
        var array = [];
        for (var i = 0; i < blobBin.length; i++) {
          array.push(blobBin.charCodeAt(i));
        }
        var file = new Blob([new Uint8Array(array)], { type: "image/png" });

        //var fileOfBlob = new File([blob], filename);
        bodyFormData.append("file", file);

        this.$store.commit("showLoadingModal");

        this.PostFormRequestWithAuthorization(
          "api/Employee/Add",
          undefined,
          bodyFormData
        )
          .then((res) => {
            this.$store.commit("hideLoadingModal");

            self.PopupData.savingData = false;
            if (
              res != undefined &&
              res.data != undefined &&
              res.data.status == true
            ) {
              self.$notify({
                group: "notification",
                title: "Message",
                text: "Employee Added Successfully",
                type: "success",
              });
              self.employeeDialog = false;
              self.ListOfEmployees = [];
              self.GetEmployees();
            } else if (
              res != undefined &&
              res.data != undefined &&
              res.data.status == false
            ) {
              self.$notify({
                group: "notification",
                type: "error",
                title: "Message",
                text: res.data.errorMessage,
              });
            }
          })
          .catch((ex) => {
            this.$store.commit("hideLoadingModal");

            self.PopupData.savingData = false;
            console.log(ex);
          });
      } else {
        if (
          this.PopupData.UpdateImage &&
          (this.pickerImageUrl == undefined || this.pickerImageUrl == "")
        ) {
          this.$notify({
            group: "notification",
            title: "Error",
            text: "Please select image",
            type: "error",
          });
          self.PopupData.savingData = false;
          return;
        }

        var bodyFormData = new FormData();
        bodyFormData.append("firstName", this.PopupData.FirstName);
        bodyFormData.append("lastName", this.PopupData.LastName);
        bodyFormData.append("gender", this.PopupData.SelectedGender.id);
        bodyFormData.append("userType", this.PopupData.SelectedUserType.id);
        bodyFormData.append("email", this.PopupData.Email);
        bodyFormData.append("cityId", this.PopupData.SelectedCity.id);
        bodyFormData.append("updatePicture", this.PopupData.UpdateImage);
        bodyFormData.append("id", this.PopupData.EditEmployeeObject.id);
        bodyFormData.append(
          "departmentId",
          this.PopupData.SelectedDepartment.id
        );
        bodyFormData.append("dateOfBirth", this.PopupData.DateOfBirth);
        if (this.PopupData.UpdateImage) {
          var filename = this.generateUUID() + ".jpg";
          bodyFormData.append("profileImageFileName", filename);
          var blobBin = atob(this.pickerImageUrl.split(",")[1]);
          var array = [];
          for (var i = 0; i < blobBin.length; i++) {
            array.push(blobBin.charCodeAt(i));
          }
          var file = new Blob([new Uint8Array(array)], { type: "image/png" });

          //var fileOfBlob = new File([blob], filename);
          bodyFormData.append("file", file);
        }

        this.$store.commit("showLoadingModal");

        this.PostFormRequestWithAuthorization(
          "api/Employee/Edit",
          undefined,
          bodyFormData
        )
          .then((res) => {
            this.$store.commit("hideLoadingModal");

            self.PopupData.savingData = false;
            if (
              res != undefined &&
              res.data != undefined &&
              res.data.status == true
            ) {
              self.$notify({
                group: "notification",
                title: "Message",
                text: "Employee Updated Successfully",
                type: "success",
              });
              self.employeeDialog = false;
              self.ListOfEmployees = [];
              self.GetEmployees();
            } else if (
              res != undefined &&
              res.data != undefined &&
              res.data.status == false
            ) {
              self.$notify({
                group: "notification",
                type: "error",
                title: "Message",
                text: res.data.errorMessage,
              });
            }
          })
          .catch((ex) => {
            this.$store.commit("hideLoadingModal");

            self.PopupData.savingData = false;
            console.log(ex);
          });
      }
    },
    generateUUID() {
      // Public Domain/MIT
      var d = new Date().getTime();
      if (
        typeof performance !== "undefined" &&
        typeof performance.now === "function"
      ) {
        d += performance.now(); //use high-precision timer if available
      }
      var newGuid = "xxxxxxxx".replace(/[xy]/g, function (c) {
        var r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
      });

      return newGuid;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
