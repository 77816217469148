var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{staticClass:"justify-center ma-0 menubtns"},[_c('v-btn',{staticClass:"mx-1",class:{
      activetextcolor: _vm.$route.path == '/home',
      nonactivetextcolor: _vm.$route.path != '/home',
    },attrs:{"text":"","plain":""},on:{"click":function($event){return _vm.RouteTo('/home')}}},[_vm._v(" Home ")]),(_vm.showDashboard)?_c('v-btn',{staticClass:"mx-1",class:{
      activetextcolor: _vm.$route.path.includes('admin'),
      nonactivetextcolor: !_vm.$route.path.includes('admin'),
    },attrs:{"text":"","plain":""},on:{"click":function($event){return _vm.RouteTo(_vm.adminRoute)}}},[_vm._v(" Dashboard ")]):_vm._e(),_c('v-btn',{staticClass:"mx-1",class:{
      activetextcolor: _vm.$route.path == '/ourcompany',
      nonactivetextcolor: _vm.$route.path != '/ourcompany',
    },attrs:{"text":"","plain":""},on:{"click":function($event){return _vm.RouteTo('/ourcompany')}}},[_vm._v(" Our Company ")]),_c('v-btn',{staticClass:"mx-1",class:{
      activetextcolor: _vm.$route.path == '/departmentinfocus',
      nonactivetextcolor: _vm.$route.path != '/departmentinfocus',
    },attrs:{"text":"","plain":""},on:{"click":function($event){return _vm.RouteTo('/departmentinfocus')}}},[_vm._v(" Departments ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }