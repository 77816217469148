<template>
  <v-row class="border-secondary align-center">
    <v-col cols="auto" class="py-2 reactionicons">
      <v-btn
        fab
        icon
        x-small
        @click="handleReaction(1)"
        class="mr-1"
        :class="{ selectedbtn: isSelected(1) }"
      >
        <img src="../../assets/like.png" />
      </v-btn>
      <v-btn
        fab
        icon
        x-small
        @click="handleReaction(2)"
        class="mr-1"
        :class="{ selectedbtn: isSelected(2) }"
      >
        <img src="../../assets/love.png" />
      </v-btn>
      <v-btn
        fab
        icon
        x-small
        @click="handleReaction(3)"
        class="mr-1"
        :class="{ selectedbtn: isSelected(3) }"
      >
        <img src="../../assets/applause.png" />
      </v-btn>
    </v-col>
    <v-col
      cols="py-2 px-0 fw-500 cursor-pointer"
      @click.stop="reactiondialog = true"
      >{{ reactionText }}</v-col
    >
    <v-dialog
      v-model="reactiondialog"
      max-width="440"
      scrollable
      content-class="reactiondialog"
    >
      <v-card class="mb-0">
        <v-card-title class="pa-3 borderBottom-1 border-accentxlight">
          <v-row class="align-center mx-n5 px-2">
            <v-col class="pb-2">Reactions</v-col>
          </v-row>
        </v-card-title>
        <vue-scroll>
          <v-card-text class="accentdark--text px-5">
            <v-row>
              <v-col class="pt-0 px-0 px-md-3">
                <v-tabs dense height="45px" class="reactiontabs" fixed-tabs>
                  <v-tab active-class="base accentdark--text"> ALL </v-tab>
                  <v-tab active-class="base accentdark--text">
                    <img src="../../assets/like.png" class="mr-2" />
                    {{ listofLikedReactions.length }}
                  </v-tab>
                  <v-tab active-class="base accentdark--text">
                    <img src="../../assets/love.png" class="mr-2" />
                    {{ listofLovedReactions.length }}
                  </v-tab>
                  <v-tab active-class="base accentdark--text">
                    <img src="../../assets/applause.png" class="mr-2" />
                    {{ listofApplauseReactions.length }}
                  </v-tab>

                  <v-tab-item class="pb-3 pt-6 pl-3 pr-1">
                    <v-row
                      v-for="item in listofAllReactions"
                      class="align-center borderBottom-1 border-accentxlight"
                    >
                      <v-col cols="auto">
                        <v-badge avatar overlap bottom>
                          <template v-slot:badge>
                            <v-avatar size="20">
                              <img
                                v-if="item.reactionId == 1"
                                src="../../assets/like.png"
                              />
                              <img
                                v-if="item.reactionId == 2"
                                src="../../assets/love.png"
                              />
                              <img
                                v-if="item.reactionId == 3"
                                src="../../assets/applause.png"
                              />
                            </v-avatar>
                          </template>

                          <v-avatar size="30">
                            <v-img
                              :src="
                                item.employeeImage
                                  ? item.employeeImage
                                  : 'https://cdn.vuetifyjs.com/images/john.png'
                              "
                            ></v-img>
                          </v-avatar>
                        </v-badge>
                      </v-col>
                      <v-col class="fw-500">
                        {{ item.employeeName }}
                        <span v-if="item.userId == currentUserId">(You)</span>
                      </v-col>
                    </v-row>
                  </v-tab-item>

                  <v-tab-item class="pb-3 pt-6 pl-3 pr-1">
                    <v-row
                      v-for="item in listofLikedReactions"
                      class="align-center borderBottom-1 border-accentxlight"
                    >
                      <v-col cols="auto">
                        <v-badge avatar overlap bottom>
                          <template v-slot:badge>
                            <v-avatar size="20">
                              <img src="../../assets/like.png" />
                            </v-avatar>
                          </template>

                          <v-avatar size="30">
                            <v-img
                              :src="
                                item.employeeImage
                                  ? item.employeeImage
                                  : 'https://cdn.vuetifyjs.com/images/john.png'
                              "
                            ></v-img>
                          </v-avatar>
                        </v-badge>
                      </v-col>
                      <v-col class="fw-500">
                        {{ item.employeeName }}
                        <span v-if="item.userId == currentUserId">(You)</span>
                      </v-col>
                    </v-row>
                  </v-tab-item>

                  <v-tab-item class="pb-3 pt-6 pl-3 pr-1">
                    <v-row
                      v-for="item in listofLovedReactions"
                      class="align-center borderBottom-1 border-accentxlight"
                    >
                      <v-col cols="auto">
                        <v-badge avatar overlap bottom>
                          <template v-slot:badge>
                            <v-avatar size="20">
                              <img src="../../assets/love.png" />
                            </v-avatar>
                          </template>

                          <v-avatar size="30">
                            <v-img
                              :src="
                                item.employeeImage
                                  ? item.employeeImage
                                  : 'https://cdn.vuetifyjs.com/images/john.png'
                              "
                            ></v-img>
                          </v-avatar>
                        </v-badge>
                      </v-col>
                      <v-col class="fw-500">
                        {{ item.employeeName }}
                        <span v-if="item.userId == currentUserId">(You)</span>
                      </v-col>
                    </v-row>
                  </v-tab-item>

                  <v-tab-item class="pb-3 pt-6 pl-3 pr-1">
                    <v-row
                      v-for="item in listofApplauseReactions"
                      class="align-center borderBottom-1 border-accentxlight"
                    >
                      <v-col cols="auto">
                        <v-badge avatar overlap bottom>
                          <template v-slot:badge>
                            <v-avatar size="20">
                              <img src="../../assets/applause.png" />
                            </v-avatar>
                          </template>

                          <v-avatar size="30">
                            <v-img
                              :src="
                                item.employeeImage
                                  ? item.employeeImage
                                  : 'https://cdn.vuetifyjs.com/images/john.png'
                              "
                            ></v-img>
                          </v-avatar>
                        </v-badge>
                      </v-col>
                      <v-col class="fw-500">
                        {{ item.employeeName }}
                        <span v-if="item.userId == currentUserId">(You)</span>
                      </v-col>
                    </v-row>
                  </v-tab-item>
                </v-tabs>
              </v-col>
            </v-row>
          </v-card-text>
        </vue-scroll>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import apimethods from "../../mixins/apimethods";

export default {
  name: "Reaction",
  mixins: [apimethods],
  props: {
    postId: {
      required: true,
    },
    selectedReaction: {
      default: 0,
    },
    listofLikedReactions: {
      type: Array,
      default: () => [],
    },
    listofLovedReactions: {
      type: Array,
      default: () => [],
    },
    listofApplauseReactions: {
      type: Array,
      default: () => [],
    },
    listofAllReactions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      reactiondialog: false,
      currentUserId: null,
    };
  },
  computed: {
    reactionText() {
      if (this.listofAllReactions.length > 0) {
        if (this.selectedReaction) {
          return `You & ${this.listofAllReactions.length - 1} Other(s)`;
        } else {
          return `${this.listofAllReactions.length} Other(s)`;
        }
      }
    },
  },
  created() {
    var userDetailStr = localStorage.getItem("UserDetail");
    if (userDetailStr != null) {
      var userDetail = JSON.parse(userDetailStr);
      this.currentUserId = userDetail.userId;
    }
  },
  methods: {
    handleReaction(reactionId) {
      this.$emit("reaction-selected", {
        postId: this.postId,
        selectedReaction: reactionId,
      });
    },
    isSelected(reactionId) {
      return this.selectedReaction == reactionId;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>