<template>
  <v-row class="ma-0 admindashboard fill-height">
    <v-row class="ma-0">
      <v-main class="base admindashboardmain">
        <v-row class="d-flex fill-height ma-0">
          <v-col cols="12" lg="2" class="px-0 px-lg-3">
            <side-menu-component />
          </v-col>
          <v-col class="px-lg-0 py-0 admincontent fill-height col-lg " md="12">
            <router-view />
          </v-col>
        </v-row>
      </v-main>
    </v-row>
  </v-row>
</template>

<script>
import SideMenuComponent from "../../components/SideMenuComponent.vue";
export default {
  components: { SideMenuComponent },
  data() {
    return {
      dialog: false,
    };
  },
  name: "AdminLayout",
  props: {
    msg: String,
  },
  methods: {
    RouteTo(url) {
      this.$router.push(url);
    },
  },
};
</script>
    