<template>
  <v-app>
    <v-row class="dashboardview ma-0 fill-height p-relative">
      <v-col cols="12" class="py-0">
        <v-row class="fill-height p-absolute base wd-100 my-0">
          <vue-scroll class="mainscroll" :ops="ops">
            <v-col
              cols="12"
              class="py-0 px-0 px-md-0"
              v-if="$route.path != '/'"
            >
              <app-bar-component :navigationType="$route.path" />
            </v-col>

            <v-col
              cols="12"
              class="px-0 pt-0 p-absolute fill-height"
              :class="{
                'pb-0': $route.path == '/',
                mobiledashboard: $route.path.includes('/admin'),
              }"
            >
              <router-view />
              <notifications
                group="notification"
                ref="notifications"
                position="bottom right"
              />
              <v-overlay :value="showLoading">
                <v-progress-circular
                  indeterminate
                  size="64"
                ></v-progress-circular>
              </v-overlay>
              {{ $router.name }}
            </v-col>
          </vue-scroll>
        </v-row>
      </v-col>
      <div id="fb-root"></div>
    </v-row>
  </v-app>
</template>
<script>
import apimethods from "./mixins/apimethods";
import Footer from "./components/Footer.vue";
import AppBarComponent from "./components/AppBarComponent.vue";

export default {
  name: "App",
  mixins: [apimethods],
  components: { AppBarComponent, Footer },

  data: () => ({
    AppBarVisibility: false,
    DrawerVisibility: false,
    drawer: null,
    ops: {
      scrollPanel: {
        scrollingX: false,
      },
    },
    //
  }),

  mounted() {
    let recaptchaScript2 = document.createElement("script");
    recaptchaScript.setAttribute("async", "");
    recaptchaScript2.setAttribute(
      "src",
      "https://platform.twitter.com/widgets.js"
    );
    document.head.appendChild(recaptchaScript2);

    var gtag = document.createElement("script");
    gtag.setAttribute("async", "");
    gtag.setAttribute(
      "src",
      "https://www.googletagmanager.com/gtag/js?id=G-K65TX2M8SP"
    );
    document.head.appendChild(gtag);

    this.$nextTick(() => {
      this.$refs.notifications.duration = 10000;
    });
  },

  computed: {
    showLoading() {
      return this.$store.state.showLoading;
    },
  },

  created() {
    this.GetAppInitialData();

    const loggedIn = localStorage.getItem("UserData");

    if (loggedIn) {
      this.FetchUserInfo();
    }
  },

  watch: {
    $route(to, from) {
      let meta = this.$route.meta;
      if (meta != undefined) {
        if (meta.showAppBar != undefined)
          this.$data.AppBarVisibility = meta.showAppBar;
        if (meta.showDrawer != undefined)
          this.$data.DrawerVisibility = meta.showDrawer;
      }
    },
  },

  methods: {
    GetAppInitialData() {
      var request = this.GetRequestWithAuthorization(
        "api/MasterData/GetAppInitialData",
        undefined
      )
        .then((res) => {
          if (
            res != undefined &&
            res.data != undefined &&
            res.data.data != undefined
          ) {
            var responseData = res.data.data;
            this.saveIndexedData("Cities", responseData.listOfCities);
            this.saveIndexedData("Genders", responseData.listOfGenders);
            this.saveIndexedData("UserRoles", responseData.listOfUserRoles);
            this.saveIndexedData("EventTypes", responseData.listOfEventTypes);
            this.saveIndexedData("Colors", responseData.listOfColor);
          }
        })
        .catch((ex) => {});
    },

    FetchUserInfo() {
      try {
        this.GetRequestWithAuthorization("user/GetCurrentUserInfo", undefined)
          .then((res) => {
            if (res != undefined) {
              this.onLoading = false;
              var userDetail = res.data.data;

              var existingUserDetail = localStorage.UserDetail;
              if (
                userDetail.forceLogout == true ||
                (existingUserDetail != undefined &&
                  existingUserDetail != "" &&
                  userDetail.roleId != JSON.parse(existingUserDetail).roleId)
              ) {
                this.LogoutUser();
              } else {
                localStorage.UserDetail = JSON.stringify(userDetail);
              }
            }
          })
          .catch((ex) => {
            this.onLoading = false;
            console.log("Error fetching user Detail: " + ex);
          });
      } catch (exa) {
        console.log(exa);
      }
    },

    LogoutUser() {
      localStorage.removeItem("UserDetail");
      localStorage.removeItem("UserData");
      localStorage.removeItem("ViewRoles");
      this.$router.push("/");
    },
  },
};
</script>
