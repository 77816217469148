<template>
  <v-row class="justify-center ma-0 menubtns">
    <v-btn
      v-bind:class="{
        activetextcolor: $route.path == '/home',
        nonactivetextcolor: $route.path != '/home',
      }"
      text
      plain
      class="mx-1"
      @click="RouteTo('/home')"
    >
      Home
    </v-btn>
    <v-btn
      v-if="showDashboard"
      v-bind:class="{
        activetextcolor: $route.path.includes('admin'),
        nonactivetextcolor: !$route.path.includes('admin'),
      }"
      text
      plain
      class="mx-1"
      @click="RouteTo(adminRoute)"
    >
      Dashboard
    </v-btn>
    <v-btn
      v-bind:class="{
        activetextcolor: $route.path == '/ourcompany',
        nonactivetextcolor: $route.path != '/ourcompany',
      }"
      text
      plain
      class="mx-1"
      @click="RouteTo('/ourcompany')"
    >
      Our Company
    </v-btn>
    <v-btn
      v-bind:class="{
        activetextcolor: $route.path == '/departmentinfocus',
        nonactivetextcolor: $route.path != '/departmentinfocus',
      }"
      text
      plain
      class="mx-1"
      @click="RouteTo('/departmentinfocus')"
    >
      Departments
    </v-btn>
    <!-- <v-btn
      v-bind:class="{
        activetextcolor: $route.path == '/starclubacheiver',
        nonactivetextcolor: $route.path != '/starclubacheiver',
      }"
      text
      plain
      class="mx-1"
      @click="RouteTo('/starclubacheiver')"
    >
      Star Club Program
    </v-btn> -->
  </v-row>
</template>


<script>
export default {
  mounted() {},

  created() {
    var ViewRoles = localStorage.ViewRoles;
    if (ViewRoles != undefined) {
      ViewRoles = JSON.parse(ViewRoles);
      var adminViewRoles = ViewRoles.filter((x) => x.route.includes("admin"));
      this.$data.showDashboard =
        adminViewRoles != undefined && adminViewRoles.length > 0 ? true : false;

      if (adminViewRoles != undefined && adminViewRoles.length > 0) {
        this.$data.showDashboard = true;
        this.$data.adminRoute = adminViewRoles[0].route;
      }
    }
  },
  data() {
    return {
      adminRoute: "",
      showDashboard: false,
    };
  },
  name: "MenuBtsComponent",
  methods: {
    RouteTo(url) {
      this.$router.push(url);
    },
  },
};
</script>


<style scoped>
.activetextcolor {
  color: var(--v-accent-base) !important;
}

.nonactivetextcolor {
  color: var(--v-accentdark-base) !important;
}
</style>