<template>
  <v-col cols="12" sm="6" class="pl-sm-1 mt-3 mt-sm-0">
    <v-card class="fill-height">
      <v-card-title class="borderBottom-1 border-accentxlight"
        >Star Club Program</v-card-title
      >

      <v-card-text class="pt-2 pb-0 pr-1">
        <vue-scroll>
          <div class="scrolllimit">
            <v-row
              no-gutters
              class="pt-1 starachiever"
              v-for="item in starclubQuarter.acheivers"
              :key="item.id"
            >
              <v-col cols="auto">
                <img :src="item.acheiver.profileImageURL" />
              </v-col>
              <v-col class="pl-3">
                <p class="fw-500 mdFont mb-0">
                  {{ item.acheiver.firstName }} {{ item.acheiver.lastName }}
                </p>
                <p class="xsFont">
                  {{ item.acheiver.department }}
                </p>
              </v-col>
            </v-row>
          </div>
        </vue-scroll>
      </v-card-text>
    </v-card>
  </v-col>
</template>
     <script>
import apimethods from "../../mixins/apimethods";

export default {
  mixins: [apimethods],

  name: "NewsAndUpdates",
  data() {
    return {
      starclubQuarter: [],
    };
  },
  mounted() {
    this.GetStarClubData();
  },
  methods: {
    GetStarClubData() {
      var self = this;
      this.$store.commit("showLoadingModal");
      this.GetRequestWithAuthorization("api/StarClub/Get", undefined)
        .then((res) => {
          this.$store.commit("hideLoadingModal");

          if (
            res != undefined &&
            res.data != undefined &&
            res.data.data != undefined
          ) {
            self.starclubQuarter = res.data.data.starClubAcheivers[0];
          }
        })
        .catch((ex) => {
          this.$store.commit("hideLoadingModal");
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
