<template>
  <v-row
    class="dashboardview ma-0 mb-0 base p-relative homeview min-height-100"
  >
    <v-col>
      <v-row class="white mt-md-15 pt-12 pb-0 pb-md-9 ma-0 rounded" v-if="AboutDescription">
        <v-container>
          <v-row class="align-center">
            <v-col
              cols="12"
              sm="6"
              class="accentdark--text  order-1 order-sm-0 pr-8 line-height-30 mdFont1"
            >
              <p class="fw-500 xxlgFont mb-3">{{ AboutDescription.title }}</p>
              <p v-html="AboutDescription.description"></p>
            </v-col>
            <v-col cols="12"
              sm="6" class="text-center order-0 order-sm-1 pl-3">
              <img :src="AboutDescription.photoUrl" class="companyheader"
            /></v-col>
          </v-row>
        </v-container>
      </v-row>

      <v-row class="base mt-md-10 pt-0 pb-md-9">
        <v-container>
          <v-row class="mb-3" v-if="VisionDescription">
            <v-col cols="12" class="pb-md-1">
              <div
                class="companyprofileblue white--text  pa-4
                  pa-md-7"
                v-bind:style="bannerBgImage(VisionDescription.photoUrl)"
              >
                <!-- :style="{background-image: url(../assets/CompanyBg.png), url(" ' + {{VisionDescription.photoUrl}} + ' ") ;" ' -->

                <p class="fw-500 xxlgFont mb-1">
                  {{ VisionDescription.title }}
                </p>
                <p
                  class="mdFont1 mb-0"
                  v-html="VisionDescription.description"
                ></p>
              </div>
            </v-col>
            <v-col cols="12" md="8" class="pt-md-1 pr-md-1" v-if="MissionDescription">
              <div
                class="
                  companyprofileblue
                  white--text
                  pa-4
                  pa-md-7
                  d-flex
                  flex-column
                  fill-height
                "
                v-bind:style="bannerBgImage(MissionDescription.photoUrl)"
              >
                <p class="fw-500 xxlgFont mb-1">
                  {{ MissionDescription.title }}
                </p>
                <p class="mdFont1" v-html="MissionDescription.description"></p>
              </div>
            </v-col>
            <v-col  cols="12" md="4" class="pt-md-1 pl-md-1">
              <div
                class="
                  companyprofileblue
                  white--text
                  pa-4
                  pa-md-7
                  d-flex
                  flex-column
                  fill-height
                "
                v-bind:style="bannerBgImage(BusinessActivityDescription.photoUrl)"
              >
                <p class="fw-500 xxlgFont mb-2">
                  {{ BusinessActivityDescription.title }}
                </p>
                <p
                  class="mdFont1"
                  v-html="BusinessActivityDescription.description"
                ></p>
              </div>
            </v-col>
          </v-row>

          <v-row class="mt-0 mt-md-3">
            <v-col cols="12" sm="6" class="text-center">
              <img :src="OfficeDescription.photoUrl" class="companyheader1"
            /></v-col>
            <v-col
              cols="12" sm="6" 
              class="accentdark--text line-height-30 mdFont1"
              v-html="OfficeDescription.description"
            >
            </v-col>
          </v-row>
        </v-container>
      </v-row>

      <v-row no-gutter class="base pb-md-5" v-if="ProductDescription">
        <v-col class="borderTop-1 border-secondaryxLight pt-5 pt-md-8">
          <v-row>
            <v-container>
              <v-row class="">
                <v-col
                  cols="12"
                  class="accentdark--text line-height-30 mdFont1"
                  v-html="ProductDescription.description"
                >
                </v-col>
              </v-row>
            </v-container>
          </v-row>
        </v-col>
      </v-row>

      <v-row class="white py-md-5 pb-3 pb-md-8">
        <v-container>
          <company-policy-component :isEditable="false"/>
        </v-container>
      </v-row>

      <div class="footerclear"></div>
    </v-col>

    <Footer v-if="$route.path != '/' && !$route.path.includes('admin')" />
  </v-row>
</template>

<script>
import apimethods from "../mixins/apimethods";
import CompanyPolicyComponent from "../components/CompanyPolicyComponent.vue";
import Footer from "../components/Footer.vue";
export default {
  name: "OurCompanyMainPage",
  mixins: [apimethods],
  components: { Footer, CompanyPolicyComponent },
  mounted() {},
  data() {
    return {
      DescriptionList: [],
      AboutDescription: {},
      VisionDescription: {},
      MissionDescription: {},
      BusinessActivityDescription: {},
      OfficeDescription: {},
      ProductDescription: {},
    };
  },
  created() {
    this.LoadAboutUsDescription();
  },
  methods: {
    bannerBgImage(image) {
      return (
        "background-image: url(" +
        require("../assets/CompanyBg.png") +
        "), url(" +
        image +
        ") ;"
      );
    },
    LoadAboutUsDescription() {
      this.$store.commit("showLoadingModal");
      var self = this;
      this.GetRequestWithAuthorization(
        "api/OurCompany/GetAllCompanyDescription",
        undefined
      )
        .then((res) => {
          self.$store.commit("hideLoadingModal");
          if (
            res != undefined &&
            res.data != undefined &&
            res.data.data != undefined
          ) {
            self.DescriptionList = JSON.parse(JSON.stringify(res.data.data));
            self.LoadData(self.DescriptionList);
          }
        })
        .catch((ex) => {
          self.$store.commit("hideLoadingModal");
        });
    },
    LoadData(descriptionList) {
      descriptionList.forEach((descElement) => {
        switch (descElement.descriptionTypeId) {
          case 1:
            this.AboutDescription = descElement;
            break;
          case 2:
            this.VisionDescription = descElement;
            break;
          case 3:
            this.MissionDescription = descElement;
            break;
          case 4:
            this.BusinessActivityDescription = descElement;
            break;
          case 5:
            this.OfficeDescription = descElement;
            break;
          case 6:
            this.ProductDescription = descElement;
            break;
        }
      });
    },
  },
};
</script>
