<template>
  <v-row class="d-flex fill-height ma-0">
    <v-col class="px-0 pl-md-0 pr-lg-3 pt-0 pt-lg-3">
      <v-row class="ma-0 fill-height p-relative box-shadow-1">
        <v-col class="white rounded d-flex fill-height flex-column p-absolute">
          <v-row class="align-center">
            <v-col class="lgFont accentdark--text fw-700"> Weather </v-col>
            <v-col cols="auto">
              <v-btn  class="accent white--text" @click.stop="AddWeather"
                ><span class="material-icons-outlined mr-1 lgFont"
                  >add_circle_outline</span
                >Add Weather</v-btn
              >
            </v-col>
          </v-row>

          <v-row class="fill-height p-relative  mb-0">
            <v-col>
              <v-row class="fill-height p-absolute wd-100">
                <vue-scroll>
                  <v-col cols="12" class="pr-6">
                    <v-row
                      class="align-center"
                      v-if="activeWeathersList && activeWeathersList.length > 0"
                    >
                      <v-col cols="auto" class="accent--text fw-500 mdFont1"
                        >Active Weather</v-col
                      >
                    </v-row>

                    <v-row class="align-center weatherslider">
                      <v-col
                        cols="12"
                        sm="4"
                        md="3"
                        class="pr-0 pt-0"
                        v-for="item in activeWeathersList"
                        :key="item.id"
                      >
                        <div
                          class="
                            weathercard
                            border-accentxlight border-1
                            pa-3
                            ma-0
                          "
                        >
                          <v-row no-gutters class="align-center">
                            <v-col class="xlgFont fw-700 black--text">
                              {{ item.city.temprature }}
                              <sup class="fw-400 mdFont">&deg;C</sup>
                            </v-col>
                            <v-col cols="auto" class="line-height-0">
                              <v-tooltip bottom color="accentdark">
                                <template v-slot:activator="{ on, attrs }">
                                  <img
                                    v-bind="attrs"
                                    v-on="on"
                                    v-if="item.city.weatherConditionIcon"
                                    :src="
                                      require(`../../assets/OpenWeather/${item.city.weatherConditionIcon}.png`)
                                    "
                                  />
                                </template>
                                <span>
                                  {{
                                    item.city.weatherConditionDescription
                                  }}</span
                                >
                              </v-tooltip></v-col
                            >
                          </v-row>
                          <v-row no-gutters class="mt-1 align-center">
                            <v-col class="fw-400">
                              {{ item.city.name }}
                              <div class="chip ml-1">PK</div></v-col
                            >
                          </v-row>
                          <v-row class="mt-1">
                            <v-col cols="12" class="pt-0 pl-2">
                              <v-row no-gutters class="align-center">
                                <v-col cols="auto">
                                  <v-btn
                                    
                                    text
                                    class="pl-0 pr-1 mr-2 removebtn"
                                    
                                    color="accentlight"
                                    @click="confirmRemove(item.id)"
                                  >
                                    <span class="material-icons lgFont mr-1"
                                      >delete</span
                                    >
                                    Remove</v-btn
                                  >
                                </v-col>
                                <v-col cols="auto" class="mr-2">
                                  <v-checkbox
                                    small
                                    hide-details
                                    v-model="item.isActive"
                                    @change="
                                      ChangeActiveStatus(item.id, item.isActive)
                                    "
                                    class="mt-0 pt-0 customcheckbox"
                                    label="ACTIVE"
                                  ></v-checkbox
                                ></v-col>
                              </v-row>
                            </v-col>
                          </v-row>
                        </div>
                      </v-col>
                    </v-row>

                    <v-row
                      class="align-center"
                      v-if="
                        inactiveWeathersList && inactiveWeathersList.length > 0
                      "
                    >
                      <v-col cols="auto" class="accentlight--text fw-500 mdFont1"
                        >Inactive Weather</v-col
                      >
                    </v-row>

                    <v-row class="align-center weatherslider">
                      <v-col
                         cols="12"
                        sm="4"
                        md="3"
                        class="pr-0 pt-0"
                        v-for="item in inactiveWeathersList"
                        :key="item.id"
                      >
                        <div
                          class="
                            weathercard
                            border-accentxlight border-1
                            pa-3
                            ma-0
                          "
                        >
                          <v-row no-gutters class="align-center">
                            <v-col class="xlgFont fw-700 black--text">
                              {{ item.city.temprature }}
                              <sup class="fw-400 mdFont">&deg;C</sup>
                            </v-col>
                            <v-col cols="auto">
                              <v-tooltip bottom color="accentdark">
                                <template v-slot:activator="{ on, attrs }">
                                  <img
                                    v-bind="attrs"
                                    v-on="on"
                                    v-if="item.city.weatherConditionIcon"
                                    :src="
                                      require(`../../assets/OpenWeather/${item.city.weatherConditionIcon}.png`)
                                    "
                                  />
                                </template>
                                <span>
                                  {{
                                    item.city.weatherConditionDescription
                                  }}</span
                                >
                              </v-tooltip></v-col
                            >
                          </v-row>
                          <v-row no-gutters class="mt-1 align-center">
                            <v-col class="fw-400">
                              {{ item.city.name }}
                              <div class="chip ml-1">PK</div></v-col
                            >
                          </v-row>
                          <v-row class="mt-1">
                            <v-col cols="12" class="pt-0 pl-2">
                              <v-row no-gutters class="align-center">
                                <v-col cols="auto">
                                  <v-btn
                                    
                                    text
                                    class="pl-0 pr-1 removebtn mr-2"
                                    
                                    color="accentlight"
                                    @click="confirmRemove(item.id)"
                                  >
                                    <span class="material-icons lgFont mr-1"
                                      >delete</span
                                    >
                                    Remove</v-btn
                                  >
                                </v-col>
                                <v-col cols="auto" class="mr-2">
                                  <v-checkbox
                                    small
                                    hide-details
                                    v-model="item.isActive"
                                    class="mt-0 pt-0 customcheckbox"
                                    @change="
                                      ChangeActiveStatus(item.id, item.isActive)
                                    "
                                    label="ACTIVE"
                                  ></v-checkbox
                                ></v-col>
                              </v-row>
                            </v-col>
                          </v-row>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                </vue-scroll>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
    <v-dialog
      v-model="addWeatherDialog"
      content-class="dashboardmodal"
      max-width="350"
    >
      <v-card class="mb-0">
        <v-form ref="addWeatherForm" v-model="formValid">
          <v-card-title
            class="accentdark--text borderBottom-1 border-accentxlight"
          >
            <v-row class="align-center">
              <v-col>Add Weather</v-col>
              <v-col cols="auto"  class="d-none d-sm-block">
                <v-btn
                  outlined
                  
                  class="accentlight--text mr-2"
                  small
                  @click="addWeatherDialog = false"
                  >Cancel</v-btn
                >
                <v-btn
                  :loading="isAddingWeather"
                  :disabled="!formValid"
                  outlined
                  
                  class="accent--text"
                  @click="SaveWeather"
                  small
                  >Save</v-btn
                >
              </v-col>
            </v-row>
          </v-card-title>

          <v-card-text>
            <v-row
              ><v-col cols="12">
                <v-autocomplete
                  label="Select City"
                  outlined
                  item-text="name"
                  dense
                  :rules="[(v) => !!v || 'City is required']"
                  hide-details
                  v-model="selectedCityId"
                  :items="remainingCitiesList"
                  item-value="id"
                ></v-autocomplete></v-col
            ></v-row>
          </v-card-text>
           <v-card-actions class="d-sm-none borderTop-1 border-accentxlight">
          <v-row class="align-center mx-0 mb-n1 text-right justify-end">
            <v-col cols="auto" class="text-right pt-5 justify-end">
             <v-btn
                  outlined
                  
                  class="accentlight--text mr-2"
                  small
                  @click="addWeatherDialog = false"
                  >Cancel</v-btn
                >
                <v-btn
                  :loading="isAddingWeather"
                  :disabled="!formValid"
                  outlined
                  
                  class="accent--text"
                  @click="SaveWeather"
                  small
                  >Save</v-btn
                >
            </v-col>
          </v-row>
        </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showRemoveConfirmDialog" max-width="350">
      <v-card class="mb-0">
        <v-card-text>
          <v-row>
            <v-col class="fw-500 mdFont accentdark--text">
              Are you sure you want to remove this item?
            </v-col>
          </v-row>
          <v-row class="justify-end mt-1">
            <v-col cols="12" class="text-right">
              <v-btn
                outlined
                
                class="accentlight--text mr-2"
                small
                @click="showRemoveConfirmDialog = false"
                >Cancel</v-btn
              >
              <v-btn
                outlined
                
                class="accent--text"
                small
                @click="RemoveWeather(currentRemoveId)"
                >Yes</v-btn
              >
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import apimethods from "../../mixins/apimethods";

export default {
  name: "WeatherPage",
  mixins: [apimethods],
  data() {
    return {
      showRemoveConfirmDialog: false,
      currentRemoveId: 0,
      addWeatherDialog: false,
      isAddingWeather: false,
      formValid: false,
      selectedCityId: 0,
      listOfCities: [],
      listOfWeathers: [],
    };
  },
  computed: {
    activeWeathersList() {
      return this.listOfWeathers.filter((x) => x.isActive == true);
    },
    inactiveWeathersList() {
      return this.listOfWeathers.filter((x) => x.isActive == false);
    },
    remainingCitiesList() {
      let addedCityIds = this.listOfWeathers.map(({ cityId }) => cityId);
      return this.listOfCities.filter((x) => !addedCityIds.includes(x.id));
    },
  },
  props: {},
  mounted() {
    this.GetAllWeathers();
    var self = this;
    this.fetchDatafromInd("Cities").then((cities) => {
      self.listOfCities = cities;
    });
  },
  methods: {
    getImgUrl(pic) {
      return require("../../assets/" + pic + ".png");
    },
    AddWeather() {
      this.selectedCityId = 0;
      this.addWeatherDialog = true;
      this.$refs.addWeatherForm.resetValidation();
    },
    GetAllWeathers() {
      this.$store.commit("showLoadingModal");
      var self = this;
      this.GetRequestWithAuthorization("api/Weather/Get", undefined)
        .then((res) => {
          this.$store.commit("hideLoadingModal");
          if (
            res != undefined &&
            res.data != undefined &&
            res.data.data != undefined
          ) {
            self.listOfWeathers = res.data.data;
          }
        })
        .catch((ex) => {
          this.$store.commit("hideLoadingModal");
        });
    },
    SaveWeather() {
      this.$store.commit("showLoadingModal");
      this.isAddingWeather = true;

      var self = this;
      var bodyFormData = {};
      bodyFormData.cityId = this.selectedCityId;
      bodyFormData.isActive = true;
      var elementJson = JSON.stringify(bodyFormData);

      this.PostRequestWithAuthorization(
        "api/Weather/Add",
        undefined,
        elementJson
      )
        .then((res) => {
          this.isAddingWeather = false;
          if (res != undefined && res.data != undefined) {
            if (res.data.status == true) {
              self.$notify({
                group: "notification",
                title: "Success",
                text: "Weather Added Successfully",
                type: "success",
              });
              self.GetAllWeathers();
              self.addWeatherDialog = false;
            } else {
              self.$notify({
                group: "notification",
                title: "Error",
                text: res.data.errorMessage,
                type: "error",
              });
            }
          }
        })
        .catch((ex) => {
          this.$store.commit("hideLoadingModal");
          this.isAddingWeather = false;
          console.log(ex);
        });
    },

    ChangeActiveStatus(WeatherId, active) {
      var self = this;
      this.$store.commit("showLoadingModal");

      this.GetRequestWithAuthorization("api/Weather/MarkActiveUnActive", [
        {
          Key: "WeatherId",
          Value: WeatherId,
        },
        {
          Key: "active",
          Value: active,
        },
      ])
        .then((res) => {
          if (
            res != undefined &&
            res.data != undefined &&
            res.data.status == true
          ) {
            self.$notify({
              group: "notification",
              title: "Message",
              text: "Status updated Successfully",
              type: "success",
            });
            self.GetAllWeathers();
          }
        })
        .catch((ex) => {
          this.$store.commit("hideLoadingModal");

          console.log(ex);
        });
    },
    confirmRemove(weatherId) {
      this.$data.showRemoveConfirmDialog = true;
      this.$data.currentRemoveId = weatherId;
    },
    RemoveWeather(WeatherId) {
      this.$data.showRemoveConfirmDialog = false;
      var self = this;
      this.$store.commit("showLoadingModal");

      this.GetRequestWithAuthorization("api/Weather/Remove", [
        {
          Key: "WeatherId",
          Value: WeatherId,
        },
      ])
        .then((res) => {
          if (
            res != undefined &&
            res.data != undefined &&
            res.data.status == true
          ) {
            self.$notify({
              group: "notification",
              title: "Success",
              text: "Weather removed Successfully",
              type: "success",
            });
            self.showRemoveConfirmDialog = false;
            self.GetAllWeathers();
          }
        })
        .catch((ex) => {
          this.$store.commit("hideLoadingModal");
          console.log(ex);
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
