<template>
  <v-row no-gutters class="mb-3 mt-n3 mt-lg-0 fill-height">
    <v-col cols="12" sm="6" lg="12" class="pr-sm-1 pr-lg-0">
      <v-card class="fill-height">
        <v-card-title class="borderBottom-1 border-accentxlight">
          <v-row no-gutters class="align-center">
            <v-col @click.stop="dialog = true">Events</v-col>
            <v-col
              cols="auto"
              class="mdFont accentdark--text fw-500 d-none d-md-flex"
              ><span v-if="$refs.calendar">{{ $refs.calendar.title }}</span>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text
          class="pa-0 mb-n3 eventcal homecal"
          @click.stop="dialog = true"
        >
          <v-calendar
            class="cursor-pointer"
            ref="calendar"
            v-model="focus"
            color="secondaryxxLight"
            :events="events"
            :event-color="getEventColor"
            type="month"
            @click:date="DateClick"
            @change="updateRange"
            event-overlap-mode="stack"
            event-width="2px"
            event-height="2px"
            event-more-text=""
            :category-hide-dynamic="true"
            event-margin-bottom="0"
          >
            <template v-slot:event="{}" disabled="true"> </template>
          </v-calendar>
        </v-card-text>
      </v-card>
    </v-col>
    <StarClub class="d-none d-sm-block d-lg-none pl-sm-1 pl-lg-0"></StarClub>

    <v-dialog
      v-model="dialog"
      max-width="750"
      scrollable
      content-class="eventcaldialog"
    >
      <v-card class="mb-0">
        <v-card-title class="pa-3">
          <v-row
            class="
              align-center
              borderBottom-1
              border-accentxlight
              mx-n5
              px-2
              mb-0
            "
          >
            <v-col class="pb-2">Events</v-col>
          </v-row>
        </v-card-title>
        <vue-scroll :ops="ops">
          <v-card-text class="pa-0 accentdark--text">
            <v-row class="ma-0 pa-3">
              <v-col cols="12" class="pt-0 px-0 px-sm-2">
                <v-sheet height="64">
                  <v-toolbar flat class="px-0">
                    <v-btn
                      class="
                        mr-4
                        ml-n3
                        mdFont1
                        text-capitalize
                        todaybtn
                        accentdark--text
                        elevation-0
                        secondaryxxLight
                      "
                      @click="setToday"
                    >
                      Today
                    </v-btn>
                    <v-btn fab text small color="grey darken-2" @click="prev">
                      <v-icon small> mdi-chevron-left </v-icon>
                    </v-btn>
                    <v-btn fab text small color="grey darken-2" @click="next">
                      <v-icon small> mdi-chevron-right </v-icon>
                    </v-btn>
                    <v-toolbar-title v-if="$refs.calendarModal">
                      {{ $refs.calendarModal.title }}
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-menu bottom right>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          outlined
                          color="grey darken-2"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <span>{{ typeToLabel[type] }}</span>
                          <v-icon right> mdi-menu-down </v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item @click="type = 'day'">
                          <v-list-item-title>Day</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="type = 'week'">
                          <v-list-item-title>Week</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="type = 'month'">
                          <v-list-item-title>Month</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="type = '4day'">
                          <v-list-item-title>4 days</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                    <v-col cols="4" class="pr-0 mr-n3 d-none d-sm-block">
                      <v-combobox
                        outlined
                        label="Event Type"
                        dense
                        hide-details
                        return-object
                        item-text="title"
                        v-model="filterEventType"
                        :items="EventTypesListWithAll"
                        @change="OnEventTypeChange"
                        spellcheck="false"
                      >
                      </v-combobox>
                    </v-col>
                  </v-toolbar>
                </v-sheet>
                <v-row class="d-sm-none my-3" no-gutters>
                  <v-col cols="12" class="">
                    <v-combobox
                      outlined
                      label="Event Type"
                      dense
                      hide-details
                      return-object
                      item-text="title"
                      v-model="filterEventType"
                      :items="EventTypesListWithAll"
                      @change="OnEventTypeChange"
                      spellcheck="false"
                    >
                    </v-combobox>
                  </v-col>
                </v-row>
                <v-sheet height="350px" class="eventcal homemodalcal">
                  <v-calendar
                    ref="calendarModal"
                    v-model="focus"
                    color="secondaryxxLight"
                    :events="Allevents"
                    :event-color="getEventColor"
                    type="month"
                    @click:date="viewDay"
                    @change="updateRangeFromModal"
                    @click:more="ViewMore"
                    @click:event="ViewEventClick"
                  >
                    <template v-slot:event="{ event }">
                      <v-tooltip bottom color="accentdark">
                        <template v-slot:activator="{ ona, attrsa }">
                          <div
                            v-bind="attrsa"
                            v-on="ona"
                            class="text-truncate min-wd-content text-capitalize"
                          >
                            {{ event.name }}
                          </div>
                        </template>
                        <span> {{ event.name }}</span>
                      </v-tooltip>
                    </template>
                  </v-calendar>
                </v-sheet>
              </v-col>
            </v-row>
          </v-card-text>
        </vue-scroll>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="ViewMoreEvent"
      content-class="dashboardmodal"
      max-width="600"
    >
      <v-card class="mb-0">
        <v-card-text class="eventcaldiv" v-if="MorePopup.EventList">
          <v-row
            class="
              accentlight--text
              py-3
              xsFont
              align-center
              borderLeft-4
              border-accent
              ml-n4
            "
            :style="'border-color:' + item.FullItem.colorHex + '!important;'"
            v-for="item in MorePopup.EventList"
            :key="item.id"
          >
            <v-col
              class="min-wd-0"
              v-if="
                !(
                  item.FullItem.employeeName != undefined &&
                  item.FullItem.employeeName != ''
                )
              "
            >
              Event Type
              <div class="accentdark--text text-truncate fw-500">
                {{ item.name }}
              </div>
            </v-col>
            <v-col
              class="min-wd-0"
              v-if="
                item.FullItem.employeeName != undefined &&
                item.FullItem.employeeName != ''
              "
            >
              Employee
              <div class="accentdark--text text-truncate fw-500">
                {{ item.FullItem.employeeName }}
              </div>
            </v-col>
            <v-col
              cols="4"
              class="pl-0"
              v-if="
                item.FullItem.employeeName != undefined &&
                item.FullItem.employeeName != ''
              "
            >
              Department
              <div class="accentdark--text text-truncate fw-500">
                {{ item.FullItem.department }}
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import StarClub from "./StarClub.vue";
import apimethods from "../../mixins/apimethods";

export default {
  name: "SpecialEvents",
  mixins: [apimethods],
  components: {
    StarClub,
  },
  data: () => ({
    ops: {
      scrollPanel: {
        scrollingX: false,
      },
    }, // Your options
    dialog: false,
    focus: "",
    EndingDate: "",
    StartingDate: "",
    ColorList: [],
    EventTypesList: [],
    type: "month",
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    events: [],
    Allevents: [],
    ViewMoreEvent: false,
    startingticks: 0,
    endingticks: 0,
    filterEventType: undefined,
    MorePopup: {
      EventList: [],
    },
    EventTypesListWithAll: [],
  }),
  created() {
    this.FetchEventTypes();
  },
  methods: {
    ViewMore(moredata) {
      var moment = require("moment");
      var moreSelectedDate = moment(moredata.date);
      this.MorePopup.EventList = this.Allevents.filter(
        (x) =>
          x.start.getDate() == moreSelectedDate.date() &&
          x.start.getMonth() == moreSelectedDate.month()
      );
      this.ViewMoreEvent = true;
      //this.focus = date;
    },
    ViewEventClick(moredata) {
      var moment = require("moment");
      var moreSelectedDate = moment(moredata.day?.date);
      this.MorePopup.EventList = this.Allevents.filter(
        (x) =>
          x.start.getDate() == moreSelectedDate.date() &&
          x.start.getMonth() == moreSelectedDate.month()
      );
      this.ViewMoreEvent = true;
      //this.focus = date;
    },
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    DateClick(evt) {
      this.dialog = true;
    },
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    FetchEventTypes() {
      this.fetchDatafromInd("EventTypes").then((eventTypes) => {
        this.EventTypesList = eventTypes;
        this.LoadEventTypesWithAll(eventTypes);
        this.filterEventType = this.EventTypesListWithAll[0];
      });
    },

    OnEventTypeChange(evtType) {
      //this.filterEventType = evtType;
      this.fetchAllEventOfSelectedMonth(this.startingticks, this.endingticks);
    },
    LoadEventTypesWithAll(compEventTypes) {
      this.EventTypesListWithAll = [];
      this.EventTypesListWithAll.push({
        id: 0,
        title: "All",
        colorHex: "#000000",
      });
      compEventTypes.forEach((element) => {
        this.EventTypesListWithAll.push(element);
      });
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        );
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },

    updateRange({ start, end }) {
      const events = [];

      const min = new Date(`${start.date}T00:00:00`);
      const max = new Date(`${end.date}T23:59:59`);
      this.startingticks = min.getTime() * 10000 + 621355968000000000;
      this.endingticks = max.getTime() * 10000 + 621355968000000000;
      this.fetchEventOfSelectedMonth(this.startingticks, this.endingticks);
      this.events = events;
    },
    updateRangeFromModal({ start, end }) {
      const events = [];

      const min = new Date(`${start.date}T00:00:00`);
      const max = new Date(`${end.date}T23:59:59`);
      this.startingticks = min.getTime() * 10000 + 621355968000000000;
      this.endingticks = max.getTime() * 10000 + 621355968000000000;
      this.fetchAllEventOfSelectedMonth(this.startingticks, this.endingticks);
    },
    fetchEventOfSelectedMonth(strtticks, endticks) {
      this.$store.commit("showLoadingModal");

      var params = [
        {
          Key: "fromUTCticks",
          Value: strtticks,
        },
        {
          Key: "toUTCticks",
          Value: endticks,
        },
      ];
      this.GetRequestWithAuthorization(
        "api/Event/GetEventFromRangeForDasboard",
        params
      )
        .then((res) => {
          this.$store.commit("hideLoadingModal");
          if (
            res != undefined &&
            res.data != undefined &&
            res.data.data != undefined
          ) {
            var eventsRes = res.data.data;
            let events = [];
            let i = 0;
            eventsRes.forEach((element) => {
              i++;
              var eventStartDate = new Date(element.eventDate);
              let eventEndDate = new Date(element.eventDate);
              eventEndDate.setHours(eventEndDate.getHours() + 23);
              events.push({
                name: element.eventTypeTitle,
                start: eventStartDate,
                end: eventEndDate,
                color: element.colorHex,
                singleline: true,
                overlapsNoon: true,
              });
            });
            this.events = events;
          }
        })
        .catch((ex) => {
          this.$store.commit("hideLoadingModal");
        });
    },
    fetchAllEventOfSelectedMonth(strtticks, endticks) {
      this.$store.commit("showLoadingModal");

      var params = [
        {
          Key: "fromUTCticks",
          Value: strtticks,
        },
        {
          Key: "toUTCticks",
          Value: endticks,
        },
      ];
      if (
        this.filterEventType != undefined &&
        this.filterEventType.id != undefined &&
        this.filterEventType.id != 0
      ) {
        params.push({
          Key: "typeId",
          Value: this.filterEventType.id,
        });
      }
      this.GetRequestWithAuthorization("api/Event/GetEventFromRange", params)
        .then((res) => {
          this.$store.commit("hideLoadingModal");
          if (
            res != undefined &&
            res.data != undefined &&
            res.data.data != undefined
          ) {
            var eventsRes = res.data.data;
            let events = [];
            let i = 0;
            eventsRes.forEach((element) => {
              i++;
              var eventStartDate = new Date(element.eventDate);
              let eventEndDate = new Date(element.eventDate);
              eventEndDate.setHours(eventEndDate.getHours() + 23);
              events.push({
                name: element.eventTypeTitle,
                start: eventStartDate,
                end: eventEndDate,
                color: element.colorHex,
                singleline: true,
                overlapsNoon: true,
                FullItem: element,
              });
            });
            this.Allevents = events;
          }
        })
        .catch((ex) => {
          this.$store.commit("hideLoadingModal");
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>