import Vue from 'vue'
import VueRouter from 'vue-router'
import LoginPage from '../views/LoginPage.vue'
import ProfilePage from '../views/ProfilePage.vue'
import EmployeesPage from '../views/admin/EmployeesPage.vue'
import NewspaperPage from '../views/admin/NewspaperPage.vue'
import MagazinePage from '../views/admin/MagazinePage.vue'
import DashboardPage from '../views/DashboardPage.vue'
import AdminLayout from "../views/admin/AdminLayout.vue";
import CEOCornerPage from "../views/admin/CEOCornerPage.vue";
import StarClubPage from "../views/admin/StarClubPage.vue"
import NewsUpdatePage from "../views/admin/NewsUpdatePage.vue"
import DepartmentPage from "../views/admin/DepartmentPage.vue"
import EventPage from "../views/admin/EventPage.vue"
import WeatherPage from "../views/admin/WeatherPage.vue"
import NoticeboardPage from "../views/admin/NoticeboardPage.vue"
import OurCompanyPage from "../views/admin/OurCompanyPage.vue"
import DepartmentDirectoryPage from "../views/admin/DepartmentDirectoryPage.vue"
import TileCalculatorPage from "../views/admin/TileCalculatorPage.vue"
import OurCompanyMainPage from "../views/OurCompanyMainPage.vue"
import DepartmentInFocusPage from "../views/DepartmentInFocusPage.vue"
import StarClubAcheiverPage from "../views/StarClubAcheiverPage.vue"
import apimethods from "../mixins/apimethods";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'LoginPage',
        component: LoginPage,
        meta: { showAppBar: false, showDrawer: false }
    },
    {
        name: "AdminLayout",
        path: "admin",
        component: AdminLayout,
        children:
            [

                {
                    path: '/admin/newspaper',
                    name: 'NewspaperPage',
                    component: NewspaperPage,
                    meta: { showAppBar: true, showDrawer: true }
                },
                {
                    path: '/admin/magazine',
                    name: 'Magazine',
                    component: MagazinePage,
                    meta: { showAppBar: true, showDrawer: true }
                },
                {
                    path: '/admin/employees',
                    name: 'EmployeesPage',
                    component: EmployeesPage,
                    meta: { showAppBar: true, showDrawer: true }
                },
                {
                    path: '/admin/ceocorner',
                    name: 'CEOCornerPage',
                    component: CEOCornerPage,
                    meta: { showAppBar: true, showDrawer: true }
                },
                {
                    path: '/admin/starclub',
                    name: 'StarClubPage',
                    component: StarClubPage,
                    meta: { showAppBar: true, showDrawer: true }
                },
                {
                    path: '/admin/newsupdates',
                    name: 'NewsUpdatePage',
                    component: NewsUpdatePage,
                    meta: { showAppBar: true, showDrawer: true }
                },
                {
                    path: '/admin/departments',
                    name: 'DepartmentPage',
                    component: DepartmentPage,
                    meta: { showAppBar: true, showDrawer: true }
                },
                {
                    path: '/admin/events',
                    name: 'EventPage',
                    component: EventPage,
                    meta: { showAppBar: true, showDrawer: true }
                },
                {
                    path: '/admin/weather',
                    name: 'WeatherPage',
                    component: WeatherPage,
                    meta: { showAppBar: true, showDrawer: true }
                },
                {
                    path: '/admin/noticeboard',
                    name: 'NoticeboardPage',
                    component: NoticeboardPage,
                    meta: { showAppBar: true, showDrawer: true }
                },
                {
                    path: '/admin/ourcompany',
                    name: 'OurCompanyPage',
                    component: OurCompanyPage,
                    meta: { showAppBar: true, showDrawer: true }
                },
                {
                    path: '/admin/departmentdirectory',
                    name: 'DepartmentDirectoryPage',
                    component: DepartmentDirectoryPage,
                    meta: { showAppBar: true, showDrawer: true }
                }
                ,
                {
                    path: '/admin/tilecalculator',
                    name: 'TileCalculatorPage',
                    component: TileCalculatorPage,
                    meta: { showAppBar: true, showDrawer: true }
                }
            ]
    },
    {
        path: '/home',
        name: 'DashboardPage',
        component: DashboardPage,
        meta: { showAppBar: true, showDrawer: false }
    },
    {
        path: '/profile',
        name: 'ProfilePage',
        component: ProfilePage,
        meta: { showAppBar: true, showDrawer: false }
    },
    {
        path: '/ourcompany',
        name: 'OurCompanyMainPage',
        component: OurCompanyMainPage,
        meta: { showAppBar: true, showDrawer: false }
    },
    {
        path: '/departmentinfocus',
        name: 'DepartmentInFocusPage',
        component: DepartmentInFocusPage,
        meta: { showAppBar: true, showDrawer: false }
    },
    {
        path: '/starclubacheiver',
        name: 'StarClubAcheiverPage',
        component: StarClubAcheiverPage,
        meta: { showAppBar: true, showDrawer: false }
    }
]


const router = new VueRouter({
    routes
})

router.beforeEach((to, from, next) => {

    const publicPages = ["/"];
    const authRequired = !publicPages.includes(to.path);
    const loggedIn = localStorage.getItem("UserData");

    if (authRequired && !loggedIn) {
        next("/");
    } else if (to.path == "/" && loggedIn) {
        FetchUserInfo(loggedIn, next);
        next("/home");
    }
    else if (to.path == "/")
        next();
    else {
        FetchUserInfo(loggedIn, next);
        var ViewRoles = localStorage.getItem("ViewRoles");
        if (ViewRoles != undefined) {
            ViewRoles = JSON.parse(ViewRoles);
            var hasAccess = ViewRoles.filter(x => x.route == to.path);
            if (hasAccess != undefined && hasAccess.length > 0) {
                next();
            }
            else {
                next("/home");
            }
        }
        else {
            next("/");
        }

    }

});

function FetchUserInfo(isLoggedIn, next) {
    if (isLoggedIn) {

        try {
            apimethods.methods.GetRequestWithAuthorization("user/GetCurrentUserInfo", undefined)
                .then((res) => {
                    if (res != undefined) {
                        var userDetail = res.data.data;

                        var existingUserDetail = localStorage.UserDetail;
                        if (
                            userDetail.forceLogout == true ||
                            (existingUserDetail != undefined &&
                                existingUserDetail != "" &&
                                userDetail.roleId != JSON.parse(existingUserDetail).roleId)
                        ) {
                            localStorage.removeItem("UserDetail");
                            localStorage.removeItem("UserData");
                            localStorage.removeItem("ViewRoles");
                            next("/");

                        } else {
                            localStorage.UserDetail = JSON.stringify(userDetail);
                        }
                    }
                })
                .catch((ex) => {
                    console.log("Error fetching user Detail: " + ex);
                });
        } catch (exa) {
            console.log(exa);
        }
    }
}

export default router
