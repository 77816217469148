<template>
  <v-row
    class="mx-0 py-2 px-3 mt-0 justify-center p-absolute footer white--text align-center"
    style="bottom: 0; width: 100%"
  >
    <v-col cols="12" md="9">
      <v-row no-gutters class="align-center justify-center">
        <v-col
          cols="12"
          md="auto"
          class="line-height-0 pr-0 pr-md-3 border-sm-0 borderRight-1 border-white"
        >
          <img src="../assets/logo-Stile.png" />
        </v-col>
        <v-col
          class="px-0 px-md-3 py-3 py-md-0 footercontent max-width-content"
        >
          Disclaimer: Due to confidentiality, all authorized users are required
          to use the system appropriately and protect the Intranet and all
          content thereon.<br />
          COPYRIGHT © {{ date }} SHABBIR TILES & CERAMICS LIMITED | ALL RIGHTS
          RESERVED.
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      date: new Date().getFullYear(),
    };
  },
};
</script>


<style scoped>
</style>