<template>
  <v-row>
    <v-col v-show="img">
      <cropper
        class="cropper"
        :src="img"
        :stencil-props="stencilsoptions"
        @change="changeImpactCrop"
      ></cropper>
    </v-col>

    <v-col class="text-center pt-2">
      <v-btn
        color="accent"
        class="white-text md--text px-6"
        rounded
        small
        dense
        depressed
        :loading="isSelecting"
        @click="onButtonClick"
      >
        Browse
      </v-btn>
      <input
        ref="uploader"
        class="d-none"
        type="file"
        name="img"
        accept="image/x-png,image/jpeg"
        v-on:input="changeFile"
      />
    </v-col>
  </v-row>
</template>


<script>
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";

export default {
  data() {
    return {
      firsttime: false,
      img: undefined,
      isSelecting: false,
      CroppedImage: undefined,
      selectedFile: undefined,
      stencilsoptions: undefined,
    };
  },
  name: "ImageCropper",
  props: {
    CroppedImage: undefined,
    AspectRatioWidth: 10,
    AspectRatioHeight: 10,
  },
  created() {
    var ratio = this.$props.AspectRatioWidth / this.$props.AspectRatioHeight;
    this.stencilsoptions = {
      aspectRatio: ratio,
    };
    this.firsttime = true;
  },
  mounted() {
    this.$nextTick(() => {
      if (this.firsttime) {
        this.firsttime = false;
        this.onButtonClick();
      }
    });
  },
  methods: {
    changeImpactCrop(evt) {
      //console.log(coordinates, canvas);
      // console.log(imageObj.coordinates.width + " " + imageObj.coordinates.height);
      if (
        this.img.coordinates != undefined &&
        (this.img.coordinates.width <= 1 || this.img.coordinates.height <= 1)
      ) {
      } else {
        this.CroppedImage = evt.canvas;
      }
    },
    changeFile(e) {
      if (
        e.target.files.length > 0 &&
        (e.target.files[0].type == "image/png" ||
          e.target.files[0].type == "image/jpeg" ||
          e.target.files[0].type == "image/jpg" ||
          e.target.files[0].type == "image/bmp")
      ) {
        this.selectedFile = e.target.files[0];
        this.img = URL.createObjectURL(this.selectedFile);
        this.$emit("imageUploaded", null);
      } else {
        this.$notify({ Text: "Please select correct format" });
        this.$refs.uploader.value = "";
      }
    },
    onButtonClick() {
      this.isSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );
      if (this.$refs.uploader) this.$refs.uploader.click();
    },
  },
};
</script>
