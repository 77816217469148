<template>
  <div class="fill-height-lg">
    <v-navigation-drawer
      floating
      permanent
      class="rounded box-shadow-1 adminsidenav"
    >
      <v-list
        dense
        class="white rounded fill-height dashboardlistmenu d-none d-lg-block"
      >
        <!-- listofAccessNav -->

        <v-list-item-group v-model="selectedItem" color="primary">
          <v-list-item
            v-for="item in listofAccessNav"
            :key="item.Id"
            @click.native="RouteTo(item.route)"
          >
            <v-list-item-content>
              <v-list-item-title>{{ item.Name }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <div class="d-flex flex-grow-1 adminmobnav" style="width: 100%">
      <v-tabs
        show-arrows
        dense
        v-model="selectedItem"
        class="browsetab d-lg-none"
        height="45px"
      >
        <v-tab
          v-for="item in listofAccessNav"
          :key="item.Id"
          @click.native="RouteTo(item.route)"
          active-class="base accentdark--text"
          >{{ item.Name }}</v-tab
        >
      </v-tabs>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    var ViewRoles = localStorage.getItem("ViewRoles");
    if (ViewRoles != undefined) {
      ViewRoles = JSON.parse(ViewRoles);
    }
  },

  created() {
    let ViewRoles = localStorage.ViewRoles;
    if (ViewRoles != undefined && ViewRoles.length > 0) {
      let listofViewRoles = JSON.parse(ViewRoles);
      var adminViewRoles = listofViewRoles.filter((x) =>
        x.route.includes("admin")
      );
      if (adminViewRoles != undefined) {
        adminViewRoles.forEach((element) => {
          switch (element.route) {
            case "/admin/magazine":
              element.Name = "Magazines";
              element.SortOrder = 9;
              break;
            case "/admin/noticeboard":
              element.Name = "Noticeboard";
              element.SortOrder = 1;
              break;
            case "/admin/events":
              element.Name = "Events";
              element.SortOrder = 6;
              break;
            case "/admin/departments":
              element.Name = "Departments";
              element.SortOrder = 5;
              break;
            case "/admin/newsupdates":
              element.Name = "News & Updates";
              element.SortOrder = 4;
              break;
            case "/admin/starclub":
              element.Name = "Star Club Program";
              element.SortOrder = 3;
              break;
            case "/admin/ceocorner":
              element.Name = "CEO Corner";
              element.SortOrder = 2;
              break;
            case "/admin/weather":
              element.Name = "Weather";
              element.SortOrder = 7;
              break;
            case "/admin/employees":
              element.Name = "Employees Directory";
              element.SortOrder = 11;
              break;
            case "/admin/newspaper":
              element.Name = "Newspaper";
              element.SortOrder = 8;
              break;
            case "/admin/departmentdirectory":
              element.Name = "Department Directory";
              element.SortOrder = 10;
              break;
            case "/admin/ourcompany":
              element.Name = "Our Company";
              element.SortOrder = 12;
              break;
            case "/admin/tilecalculator":
              element.Name = "Tile Calculator";
              element.SortOrder = 13;
              break;
          }
          if (element.route != "/admin") this.listofAccessNav.push(element);
        });
        function compareViewRoles(a, b) {
          if (a.SortOrder < b.SortOrder) return -1;
          if (a.SortOrder > b.SortOrder) return 1;
          return 0;
        }
        this.listofAccessNav.sort(compareViewRoles);
      }
    }
    var item = this.listofAccessNav.find((x) => x.route == this.$route.path);
    if (item) this.$data.selectedItem = this.listofAccessNav.indexOf(item);
  },

  data() {
    return {
      listofAccessNav: [],
      selectedItem: {},
    };
  },
  name: "SideMenuComponent",
  props: {
    msg: String,
  },
  watch: {
    $route(to, from) {
      var item = this.listofAccessNav.find((x) => x.route == to.path);
      if (item) this.$data.selectedItem = this.listofAccessNav.indexOf(item);
    },
  },
  methods: {
    RouteTo(url) {
      this.$router.push(url);
    },
  },
};
</script>
