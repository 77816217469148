import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Vuetify from 'vuetify'
import vuetify from './plugins/vuetify'
import '@/sass/site.scss'
import vuescroll from 'vuescroll';
import localforage from 'localforage';
import Notifications from 'vue-notification'
import store from './store'
import VueTheMask from 'vue-the-mask'
import CKEditor from '@ckeditor/ckeditor5-vue2';

Vue.config.productionTip = false

Vue.use( CKEditor );

Vue.use(vuescroll, {
  ops: {
    bar: {
      onlyShowBarOnScroll: false,
      keepShow: true,
      opacity: 0.6,
      background: '#223c61',
    },
    vuescroll: {
      wheelScrollDuration: 300,
      wheelDirectionReverse: false
    },
  },
});
Vue.use(Vuetify)
Vue.use(Notifications)
Vue.use(VueTheMask)

localforage.config({
  name: "stile-db",
  version: 1.0,
  storeName: "stilestorage",
});

Vue.mixin({
  methods: {
    saveIndexedData: function (key, account) {
      return localforage
        .setItem(key, account)
        .then((value) => {
          return value;
        })
        .catch((err) => {
          console.log(
            "oops! the account was too far gone, there was nothing we could do to save him ",
            err
          );
        });
    },
    removeIndexedData: function (key) {
      return localforage
        .removeItem(key)
        .then(() => {
          return true;
        })
        .catch((err) => {
          console.log(err);
          return false;
        });

    },
    fetchDatafromInd: function (key) {

      return localforage.getItem(key).catch((ex) => {
        console.log("Exception on IndexedDB" + ex);
      });

    }
  }
})

// const MyPlugin = {
//   install(Vue, options) {
//     Vue.prototype.globalHelper = () => {
//       alert("Hello world")
//     },
//     Vue.prototype.saveAccount = (key, account) => {
//       return localforage
//         .setItem(key, account)
//         .then((value) => {
//           return value;
//         })
//         .catch((err) => {
//           console.log(
//             "oops! the account was too far gone, there was nothing we could do to save him ",
//             err
//           );
//         });
//     };
//     Vue.prototype.removeAccount = (key) => {
//       return localforage
//         .removeItem(key)
//         .then(() => {
//           return true;
//         })
//         .catch((err) => {
//           console.log(err);
//           return false;
//         });
//     };
//   },
// }
// Vue.use(MyPlugin) 


new Vue({
  router,
  vuetify,
  store,
  render: h => h(App)
}).$mount('#app')
