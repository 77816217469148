<template>
  <div class="headerbar white ">
    <v-app-bar color="white" dark fixed class="accentdark--text">
     
        <v-container>
          <v-row no-gutters class="align-center ">
        <v-col cols="3">
          <div class="d-flex align-center">
            <img
              src="../assets/login-logo.svg"
              class="stilelogo cursor-pointer"
              @click="redirecttostile()"
            />
          </div>
        </v-col>

        <v-col class="navigationmenu text-center">
          <div v-if="!isMobile">
            <MenuBtnsComponent />
          </div>
        </v-col>

        <v-col cols="auto" lg="3" class="text-right">
          <v-row no-gutters class="align-center">
            <v-col class="socialicons d-none d-lg-block">
              <img
                src="../assets/Facebook.svg"
                @click="OpenInNewTab('https://www.facebook.com/ShabbirStile/')"
              />
              <img
                src="../assets/Instagram.svg"
                @click="
                  OpenInNewTab(
                    'https://www.instagram.com/stiletilesceramic/?hl=en'
                  )
                "
              />
              <img
                src="../assets/LinkedIn.svg"
                @click="
                  OpenInNewTab('https://pk.linkedin.com/company/stile-pk')
                "
              />
              <img
                src="../assets/Youtube.svg"
                @click="
                  OpenInNewTab(
                    'https://www.youtube.com/channel/UCBUuTvCDautYnqYlhLYqZDA'
                  )
                "
              />
            </v-col>
            <v-col cols="auto" class="pl-5">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-row
                    no-gutters
                    v-bind="attrs"
                    v-on="on"
                    class="profileicon align-center"
                  >
                    <v-col
                      ><img
                        :src="ProfileImageUrl"
                        class="border-accent border-1"
                        @click="NavigateToProfileifMobile"
                    /></v-col>
                    <v-col
                      cols="auto"
                      class="
                        mdFont
                        fw-600
                        accentdark--text
                        pl-2
                        d-none d-lg-inline-block
                      "
                      >{{ Name }}</v-col
                    >
                  </v-row>
                </template>
                <v-list dense class="py-0 d-none d-lg-block" min-width="130px">
                  <v-list-item @click="$router.push('/profile')">
                    <v-list-item-title
                      class="smFont fw-400 accentdark--text d-flex align-center"
                      ><span class="material-icons mr-2 smFont">
                        account_circle
                      </span>
                      Profile</v-list-item-title
                    >
                  </v-list-item>
                  <v-list-item @click="LogoutUser">
                    <v-list-item-title
                      class="smFont fw-400 accentdark--text d-flex align-center"
                      ><span class="material-icons-outlined mr-2 smFont">
                        logout </span
                      >Logout</v-list-item-title
                    >
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
        </v-col>

        <div class="ml-3" v-if="isMobile">
          <v-app-bar-nav-icon
            color="accent"
            @click.stop="mobileMenu = !mobileMenu"
          ></v-app-bar-nav-icon>
        </div>
      </v-row>
      </v-container>
   
    </v-app-bar>

    <v-navigation-drawer v-model="mobileMenu" absolute right temporary>
      <v-row class="ma-0 borderBottom-1 border-secondary">
        <v-col class="text-right py-2 px-3 line-height-0">
          <span
            @click.stop="mobileMenu = !mobileMenu"
            class="
              material-icons-outlined
              cursor-pointer
              secondaryLight--text
              xxlgFont
            "
          >
            close
          </span>
        </v-col>
      </v-row>
      <MenuBtnsComponent />
      <v-row class="ma-0 borderBottom-1 py-3 border-secondary borderTop-1">
        <v-col class="pa-0 menubtns">
          <v-btn
            text
            plain
            class="nonactivetextcolor"
            @click="RouteTo('/profile')"
          >
            <span class="material-icons mr-2 mdFont"> account_circle </span>
            Profile
          </v-btn>

          <v-btn text plain class="nonactivetextcolor" @click="LogoutUser">
            <span class="material-icons-outlined mr-2 mdFont"> logout </span
            >Logout
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="socialicons justify-center text-center">
          <img
            src="../assets/Facebook.svg"
            @click="OpenInNewTab('https://www.facebook.com/ShabbirStile/')"
          />
          <img
            src="../assets/Instagram.svg"
            @click="
              OpenInNewTab('https://www.instagram.com/stiletilesceramic/?hl=en')
            "
          />
          <img
            src="../assets/LinkedIn.svg"
            @click="OpenInNewTab('https://pk.linkedin.com/company/stile-pk')"
          />
          <img
            src="../assets/Youtube.svg"
            @click="
              OpenInNewTab(
                'https://www.youtube.com/channel/UCBUuTvCDautYnqYlhLYqZDA'
              )
            "
          />
        </v-col>
      </v-row>
    </v-navigation-drawer>
  </div>
</template>

<script>
import MenuBtnsComponent from "./MenuBtns.vue";
import helpermethods from "../mixins/helpermethods";

export default {
  mixins: [helpermethods],
  mounted() {},
  components: {
    MenuBtnsComponent,
  },
  created() {
    var userDetailStr = localStorage.getItem("UserDetail");
    if (userDetailStr != null) {
      var userDetail = JSON.parse(userDetailStr);
      this.ProfileImageUrl = userDetail.profileImageUrl;
      this.Name = userDetail.firstName;
    }
  },
  data() {
    return {
      mobileMenu: false,
      dialog: false,
      ProfileImageUrl: "",
      Name: "",
    };
  },
  name: "AppBarComponent",
  props: ["$route.path"],
  methods: {
    redirecttostile() {
      window.open("https://www.stile.com.pk/", "_blank").focus();
    },
    RouteTo(url) {
      this.$router.push(url);
      this.mobileMenu = !this.mobileMenu;
    },
    NavigateToProfileifMobile() {
      if (this.CheckIfMobile()) this.$router.push("/profile");
    },
    LogoutUser() {
      localStorage.removeItem("UserDetail");
      localStorage.removeItem("UserData");
      localStorage.removeItem("ViewRoles");
      this.$router.push("/");
    },
    OpenInNewTab(link) {
      window.open(link, "_blank");
    },
  },
};
</script>
