import createAuthRefreshInterceptor from 'axios-auth-refresh';
import axios from 'axios';

export default {
    data() {
    },
    methods: {
        GetRequestWithAuthorization(actionName, parameters) {
            let querystring = "";
            let i = 0;
            if (parameters != undefined && parameters.length > 0) {
                querystring += "?";

                parameters.forEach((element) => {
                    if (i != 0) querystring += "&";
                    querystring += element.Key + "=" + element.Value;
                    i++;
                });
            }

            const storedUser = localStorage.getItem("UserData");
            let AuthorizationToken = "";
            if (storedUser != undefined)
                AuthorizationToken = JSON.parse(storedUser).accessToken;

            let config = {
                headers: {
                    "Authorization": "bearer " + AuthorizationToken,
                },
            };

            var axiosInstance = axios.create({
                "Content-Type": "application/json", baseURL: process.env.VUE_APP_API_LINK,
                headers: {
                    "Content-type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
                }
            });

            axiosInstance.interceptors.response.use(function (response) {
                return response;
            }, async function (error) {
                const originalRequest = error.config;

                if (error == undefined || error.response == undefined || error.response.status != 401 || originalRequest._retry) {
                    return Promise.reject(error);
                }
                else {
                    try {
                        var userDetail = JSON.parse(localStorage.UserData);
                        originalRequest._retry = true;

                        var refreshReponse = await axiosInstance.post('oauth/RefreshToken', userDetail);
                        if (refreshReponse != undefined && refreshReponse.data != undefined && refreshReponse.data.status == true) {
                            localStorage.UserData = JSON.stringify(refreshReponse.data);
                            originalRequest.headers['Authorization'] = 'Bearer ' + refreshReponse.data.accessToken;
                            return await axios.request(originalRequest);
                        }
                        else {
                            localStorage.removeItem('UserData');
                            document.location.href = document.location.origin;
                        }
                    }
                    catch (ex) {

                    }
                }
            })



            return axiosInstance
                .get(process.env.VUE_APP_API_LINK + actionName + querystring, config).then(res => { return res; }).catch(ex => {
                    var exJson = ex.toJSON();
                    if (exJson.status == 401 && exJson.config._retry == true) {
                    }
                    else if (exJson.status == 401) {
                    }
                    else
                        Promise.reject(ex);
                });
        },






        PostRequestWithAuthorization(actionName, parameters, object) {

            let querystring = "";
            let i = 0;
            if (parameters != undefined && parameters.length > 0) {
                querystring += "?";

                parameters.forEach((element) => {
                    if (i != 0) querystring += "&";
                    querystring += element.Key + "=" + element.Value;
                    i++;
                });
            }

            const storedUser = localStorage.getItem("UserData");
            let AuthorizationToken = "";
            if (storedUser != undefined)
                AuthorizationToken = JSON.parse(storedUser).accessToken;

            let config = {
                headers: {
                    "Authorization": "bearer " + AuthorizationToken,
                },
            };

            var axiosInstance = axios.create({
                "Content-Type": "application/json", baseURL: process.env.VUE_APP_API_LINK,
                headers: {
                    "Content-type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
                }
            });

            axiosInstance.interceptors.response.use(function (response) {
                return response;
            }, async function (error) {
                const originalRequest = error.config;

                if (error == undefined || error.response == undefined || error.response.status != 401 || originalRequest._retry) {
                    return Promise.reject(error);
                }
                else {
                    try {
                        var userDetail = JSON.parse(localStorage.UserData);
                        originalRequest._retry = true;

                        var refreshReponse = await axiosInstance.post('oauth/RefreshToken', userDetail);
                        if (refreshReponse != undefined && refreshReponse.data != undefined && refreshReponse.data.status == true) {
                            localStorage.UserData = JSON.stringify(refreshReponse.data);
                            originalRequest.headers['Authorization'] = 'Bearer ' + refreshReponse.data.accessToken;
                            return await axios.request(originalRequest);
                        }
                        else {
                            localStorage.removeItem('UserData');
                            document.location.href = document.location.origin;
                        }
                    }
                    catch (ex) {

                    }
                }
            })

            return axiosInstance
                .post(process.env.VUE_APP_API_LINK + actionName + querystring, object, config).then(res => {
                    return res;
                }).catch(ex => {
                    var exJson = ex.toJSON();
                    if (exJson.status == 401 && exJson.config._retry == true) {
                    }
                    else if (exJson.status == 401) {
                    }
                    else
                        Promise.reject(ex);
                });
        },


        PostFormRequestWithAuthorization(actionName, parameters, object) {
            let querystring = "";
            let i = 0;
            if (parameters != undefined && parameters.length > 0) {
                querystring += "?";

                parameters.forEach((element) => {
                    if (i != 0) querystring += "&";
                    querystring += element.Key + "=" + element.Value;
                    i++;
                });
            }

            const storedUser = localStorage.getItem("UserData");
            let AuthorizationToken = "";
            if (storedUser != undefined)
                AuthorizationToken = JSON.parse(storedUser).accessToken;

            let config = {
                headers: {
                    "Authorization": "bearer " + AuthorizationToken,
                },
            };

            var axiosInstance = axios.create({
                "Content-Type": "multipart/form-data", baseURL: process.env.VUE_APP_API_LINK,
                headers: {
                    "Content-type": "multipart/form-data",
                    "Access-Control-Allow-Origin": "*",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
                }
            });
            var self = this;
            axiosInstance.interceptors.response.use(function (response) {
                return response;
            }, async function (error) {
                const originalRequest = error.config;

                if (error == undefined || error.response == undefined || error.response.status != 401 || originalRequest._retry) {
                    return Promise.reject(error);
                }
                else {
                    try {
                        var userDetail = JSON.parse(localStorage.UserData);
                        originalRequest._retry = true;
                        var axiosRetryInstance = axios.create({
                            "Content-Type": "application/json", baseURL: process.env.VUE_APP_API_LINK,
                            headers: {
                                "Content-type": "application/json",
                                "Access-Control-Allow-Origin": "*",
                                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
                            }
                        });

                        var refreshReponse = await axiosRetryInstance.post('oauth/RefreshToken', userDetail);
                        if (refreshReponse != undefined && refreshReponse.data != undefined && refreshReponse.data.status == true) {
                            localStorage.UserData = JSON.stringify(refreshReponse.data);
                            originalRequest.headers['Authorization'] = 'Bearer ' + refreshReponse.data.accessToken;
                            return await axios.request(originalRequest);
                        }
                        else {
                            localStorage.removeItem('UserData');
                            document.location.href = document.location.origin;
                        }
                    }
                    catch (ex) {

                    }
                }
            })
            return axiosInstance
                .post(process.env.VUE_APP_API_LINK + actionName + querystring, object, config).then(res => {
                    return res;
                }).catch(ex => {
                    var exJson = ex.toJSON();
                    if (exJson.status == 401 && exJson.config._retry == true) {
                    }
                    else if (exJson.status == 401) {
                    }
                    else
                        Promise.reject(ex);
                });
        },

    }
}
